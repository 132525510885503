import { useEffect } from "react";
import { Practice } from "@remhealth/apollo";
import { Classes, LazySelectInput, ListOption, useAbort } from "@remhealth/ui";
import { AccessToken, registryClients, resourceEquals } from "@remhealth/host";

export interface PracticeSelectProps {
  apolloUrl: string;
  token: AccessToken;
  selectedPractice: Practice | null;
  autoFocus?: boolean;
  fill?: boolean;
  filter?: (practice: Practice) => boolean;
  onSelect: (practice: Practice) => void;
}

export function PracticeSelect(props: PracticeSelectProps) {
  const { apolloUrl, autoFocus, fill, filter, token, selectedPractice, onSelect } = props;

  const abort = useAbort();

  useEffect(() => {
    loadPractices();
  }, []);

  return (
    <LazySelectInput<Practice>
      key={apolloUrl}
      autoFocus={autoFocus}
      clearable={false}
      fill={fill}
      itemsEqual={resourceEquals}
      itemsSource={loadPractices}
      optionRenderer={renderPractice}
      selectedItem={selectedPractice}
      onItemSelect={onSelect}
    />
  );

  async function loadPractices(): Promise<Practice[]> {
    const client = registryClients(apolloUrl, token);

    const feed = client.practices.feed({
      filters: [{
        features: { in: ["Bells"] },
        databaseExists: true,
      }],
      orderBy: {
        field: "Display",
        direction: "Ascending",
      },
    });

    const practices = await feed.all({ abort: abort.signal });
    return filter ? practices.filter(filter) : practices;
  }
}

function renderPractice(practice: Practice) {
  return (
    <ListOption info={practice.networkId} text={practice.display ?? "no name"}>
      {practice.display ?? (<span className={Classes.TEXT_MUTED}>(no name)</span>)}
    </ListOption>
  );
}
