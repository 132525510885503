import { type BlotConstructor, Registry as QuillRegistry, type RegistryDefinition, type ScrollBlot } from "parchment";
import Quill from "quill";
import type Delta from "quill-delta";

import { defaultBlots } from "~/blots/register";
import { defaultFormats } from "~/formats/register";

// Minimum registrations necessary for a functional Quill
const coreRegistrations = [
  // Core
  "block",
  "break",
  "container",
  "cursor",
  "inline",
  "scroll",
  "text",
];

// Default registrations
export const defaultRegistrations = [
  // Custom
  ...defaultBlots.map(blot => blot.blotName),

  // Core
  "indent",
  "header",
  "list",
  "list-container",
  "bold",
  "code",
  "italic",
  "link",
  "strike",
  "underline",

  // Custom
  ...defaultFormats,
];

const registryTypes = [
  "blots",
  "formats",
  "attributors/attribute",
  "attributors/class",
  "attributors/style",
];

export type MatcherSelector = string | Node["TEXT_NODE"] | Node["ELEMENT_NODE"];
export type Matcher = (node: Node, delta: Delta, scroll: ScrollBlot) => Delta;

export class Registry {
  private readonly _formats = new Set<string>();
  private readonly _modules: JSX.Element[] = [];
  private readonly _matchers: [selector: MatcherSelector, matcher: Matcher][] = [];

  constructor(formats: ReadonlyArray<string>) {
    this.register(...formats);
  }

  public get registered(): ReadonlySet<string> {
    return this._formats;
  }

  public get modules(): ReadonlyArray<JSX.Element> {
    return this._modules;
  }

  public get matchers(): ReadonlyArray<[selector: MatcherSelector, matcher: Matcher]> {
    return this._matchers;
  }

  public clone(): Registry {
    const clone = new Registry([...this._formats.values()]);
    clone._matchers.push(...this._matchers);
    return clone;
  }

  public addModule(module: JSX.Element): void {
    this._modules.push(module);
  }

  public getQuillRegistry(): QuillRegistry {
    return createRegistry(Array.from(this._formats));
  }

  public hasMatcher(selector: MatcherSelector, matcher: Matcher): boolean {
    return this._matchers.some(m => m[0] === selector && m[1] === matcher);
  }

  public addMatcher(selector: MatcherSelector, matcher: Matcher): void {
    if (this.hasMatcher(selector, matcher)) {
      return;
    }

    this._matchers.push([selector, matcher]);
  }

  public removeMatcher(selector: MatcherSelector, matcher: Matcher): void {
    const index = this._matchers.findIndex(m => m[0] === selector && m[1] === matcher);
    if (index === -1) {
      return;
    }

    this._matchers.splice(index, 1);
  }

  public isRegistered(format: string): boolean {
    return this._formats.has(format);
  }

  public register(...formats: string[]): void {
    for (const format of formats) {
      this._formats.add(format);
    }
  }

  public unregister(...formats: string[]): void {
    for (const format of formats) {
      this._formats.delete(format);
    }
  }
}

export const defaultRegistry = new Registry(defaultRegistrations);
export const plainTextRegistry = new Registry([]);

function createRegistry(imports: string[]): QuillRegistry {
  const registry = new QuillRegistry();

  for (const name of [...coreRegistrations, ...imports]) {
    for (const registryType of registryTypes) {
      const registryName = `${registryType}/${name}`;
      const definition = Quill.imports[registryName];
      if (definition && isParchmentDefinition(registryName, definition)) {
        registry.register(definition);
      }
    }
  }

  return registry;
}

function isParchmentDefinition(name: string, target: unknown): target is RegistryDefinition {
  if (name.startsWith("blots/")) {
    return (target as BlotConstructor).blotName !== "abstract";
  }

  return name.startsWith("formats/") || name.startsWith("attributors/");
}
