import styled from "styled-components";
import { Intent, intentColor } from "@remhealth/ui";

export const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .bp5-input-group {
    flex-grow: 1;
    margin-right: 8px;
  }

  .bp5-button {
    margin-left: 10px;
  }
`;

export interface PracticeRowProps {
  $syncIssue: Intent;
}

export const PracticeRow = styled.tr<PracticeRowProps>`
  td:first-child {
    border-left: 4px solid ${props => intentColor(props.$syncIssue)};
  }

  .bp5-icon,
  .bp5-tag {
    margin: 0 5px;
  }
`;
