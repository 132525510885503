import { ChangeEvent, useEffect, useState } from "react";
import { LocalDate, Practitioner, Reference, securityRoleIds } from "@remhealth/apollo";
import { Button, Classes, Dialog, FormGroup, HTMLTable, ProgressBar, useAbort } from "@remhealth/ui";
import { PractitionerSearchRecord as ExternalPractitioner } from "@remhealth/mastodon";
import { createReference, useApollo } from "@remhealth/host";
import { Text } from "~/text";
import { useEhr } from "~/services";
import { useErrorHandler } from "~/app";
import { createPractitionerFilters, useStore } from "../stores";
import { Csv } from "../utils";
import { Failures, ProgressContainer, ProgresssInfo, UsersTextArea } from "./importEhrUserDialog.styles";

export interface ImportSupervisorDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ImportFailure {
  user: string;
  supervisor: string;
  reason: string;
}

interface ImportRow {
  user: string;
  supervisor: string;
}

const placeholder = "John Smith, Mary Boss\nJoe Worker, Kevin Smith\n... and so on ...";

export const ImportSupervisorDialog = (props: ImportSupervisorDialogProps) => {
  const { isOpen, onClose } = props;

  const ehr = useEhr();
  const store = useStore();
  const apollo = useApollo();
  const abort = useAbort();
  const handleError = useErrorHandler();

  const [data, setData] = useState("");
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState<number>();
  const [failures, setFailures] = useState<ImportFailure[]>([]);

  useEffect(() => {
    if (isOpen) {
      abort.reset();

      setData("");
      setTotal(undefined);
      setCurrent(0);
      setFailures([]);
    }
  }, [isOpen]);

  const importing = total !== undefined;
  const doneImporting = current === total;
  const successfulCount = total !== undefined ? total - failures.length : 0;

  return (
    <Dialog
      autoFocus
      isOpen={isOpen}
      title="Bulk Import Users and Supervisors"
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        {importing
          ? (
            <>
              <ProgressContainer>
                <ProgressBar intent="success" stripes={!doneImporting} value={current / total} />
                <ProgresssInfo>
                  {doneImporting
                    ? <>Imported <strong>{successfulCount}</strong> user{successfulCount !== 1 ? "s" : ""}.</>
                    : <>Importing <strong>{current}</strong> of <strong>{total}</strong>...</>}
                </ProgresssInfo>
              </ProgressContainer>
              {failures && failures.length > 0 && (
                <>
                  <h4>{failures.length} import failure{failures.length !== 1 ? "s" : ""}</h4>
                  <Failures>
                    <HTMLTable compact stickyHeader>
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Supervisor</th>
                          <th>Failure reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {failures.map((failure, idx) => (
                          <tr key={idx}>
                            <td>{failure.user}</td>
                            <td>{failure.supervisor}</td>
                            <td>{failure.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Failures>
                </>
              )}
            </>
          )
          : (
            <>
              <FormGroup label="Enter names separated by line:" subLabel="One user and supervisor per line separated by a comma.">
                <UsersTextArea fill large placeholder={placeholder} onChange={handleTextAreaChange} />
              </FormGroup>
            </>
          )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {importing && current !== total && <Button intent="danger" label="Abort" onClick={handleClose} />}
          {importing && current === total && <Button minimal intent="primary" label="Close" onClick={handleClose} />}
          {!importing && <Button minimal intent="primary" label={Text.Cancel} onClick={handleClose} />}
          {!importing && <Button disabled={!data} intent="primary" label="Import" onClick={handleImport} />}
        </div>
      </div>
    </Dialog>
  );

  function handleClose() {
    abort.reset();
    onClose();
  }

  function handleTextAreaChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setData(event.target.value);
  }

  async function handleImport() {
    const signal = abort.signal;

    const parser = Csv<ImportRow>();

    try {
      const results = parser.cast(data);

      setCurrent(0);
      setTotal(results.length);

      for (const row of results) {
        if (signal.aborted) {
          break;
        }

        // User, Supervisor, StaffId, Email
        try {
          const [user, supervisor] = await Promise.all([
            findUser(row.user, signal),
            findUser(row.supervisor, signal),
          ]);

          if (user.length === 0 || supervisor.length === 0) {
            setFailures(f => [...f, {
              user: row.user,
              supervisor: row.supervisor,
              reason: user.length === 0 ? "User not found" : supervisor.length === 0 ? "Supervisor not found" : "Neither found.",
            }]);
          } else if (user.length !== 1 || supervisor.length !== 1) {
            setFailures(f => [...f, { user: row.user, supervisor: row.supervisor, reason: "Too many matches." }]);
          } else if (supervisor !== null) {
            await Promise.all([
              setUserAsSupervisor(supervisor[0]!),
              setSupervisorForUser(user[0]!, supervisor[0]!),
            ]);
          }

          setCurrent(c => c + 1);
        } catch (error) {
          handleError(error);
        }
      }
    } catch (error) {
      setFailures(f => [...f, { user: "", supervisor: "", reason: "Failed parsing CSV" }]);
      handleError(error);
    }
  }

  async function findUser(query: string, abort: AbortSignal) {
    const page = await apollo.practitioners.query({
      filters: createPractitionerFilters({ query }),
      abort,
    });
    if (page.results.length === 0) {
      const response = await ehr.searchPractitioners(query, { limit: 5 }, abort);
      if (response.results.length === 1) {
        const practitioner = await importEhrUser(response.results[0]);
        return practitioner ? [practitioner] : [];
      }
    }
    return page.results;
  }

  async function importEhrUser(item: ExternalPractitioner) {
    const result = await ehr.pull(item, abort.signal);

    if (result.reference) {
      const practitioner = result.reference as Reference<Practitioner>;
      return store.practitioners.expand(practitioner);
    }
    return undefined;
  }

  async function setUserAsSupervisor(user: Practitioner) {
    if (!user.profile.roles.some(r => r.id === securityRoleIds.Supervisor)) {
      user.profile.roles.push({ resourceType: "SecurityRole", id: securityRoleIds.Supervisor, display: "Supervisor" });
      await store.practitioners.upsertAsync(user);
    }
  }

  async function setSupervisorForUser(user: Practitioner, supervisor: Practitioner) {
    if (!user.supervisors.some(s => s.subject.id === supervisor.id)) {
      user.supervisors.push({
        subject: createReference(supervisor),
        role: "Clinical",
        period: {
          start: LocalDate.today(),
          end: undefined,
        },
      });

      await store.practitioners.upsertAsync(user);
    }
  }
};
