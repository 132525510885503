import { Form, FormContent, Yup } from "@remhealth/core";
import { Button, Classes, Dialog, FormGroup, InputGroup } from "@remhealth/ui";

import { useRegistry } from "~/contexts";
import { DialogBody } from "./common.styles";

interface DatabaseFormValues {
  serverAddress: string;
  name: string;
  user: string;
  password: string;
}

const schema = Yup.object<DatabaseFormValues>({
  serverAddress: Yup.string()
    .label("Server Address")
    .required(),

  name: Yup.string()
    .label("Database Name")
    .required(),

  user: Yup.string()
    .label("SQL User")
    .required(),

  password: Yup.string()
    .label("SQL Password")
    .required(),
});

const initialValues: DatabaseFormValues = {
  serverAddress: "",
  name: "",
  user: "",
  password: "",
};

export interface CreateDatabaseDialogProps {
  isOpen: boolean;
  onSave: (database: Database) => void;
  onClose: () => void;
}

export interface Database {
  id: string;
  display: string | undefined;
}

export const CreateDatabaseDialog = (props: CreateDatabaseDialogProps) => {
  const { isOpen, onSave, onClose } = props;

  const registry = useRegistry();

  return (
    <Dialog
      isOpen={isOpen}
      title="New Database"
      onClose={onClose}
    >
      <Form<DatabaseFormValues>
        validateOnChange
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(form: FormContent<DatabaseFormValues>) => {
          const { fields } = form;
          return (
            <>
              <DialogBody className={Classes.DIALOG_BODY}>
                <FormGroup field={fields.serverAddress} label="Server Address">
                  <InputGroup fill field={fields.serverAddress} />
                </FormGroup>
                <FormGroup field={fields.name} label="Database Name">
                  <InputGroup fill field={fields.name} />
                </FormGroup>
                <FormGroup field={fields.user} label="SQL Username">
                  <InputGroup fill field={fields.user} />
                </FormGroup>
                <FormGroup field={fields.password} label="SQL Password">
                  <InputGroup fill field={fields.password} />
                </FormGroup>
              </DialogBody>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    disabled={form.isSubmitting}
                    intent="primary"
                    label="Save"
                    onClick={() => form.submitForm()}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </Dialog>
  );

  async function handleSubmit(values: DatabaseFormValues): Promise<void> {
    const database = await registry.databaseConnections.create({
      serverAddress: values.serverAddress,
      name: values.name,
      credentials: {
        user: values.user,
        password: values.password,
      },
    });

    onClose();

    onSave({
      id: database.id,
      display: database.display,
    });
  }
};
