import { KeyboardEvent, useState } from "react";
import { Text } from "text";
import { Button, FormGroup, InputGroup, Tooltip, isEnterKeyPress } from "@remhealth/ui";
import { DragHandleVertical } from "@remhealth/icons";
import { RuleForm } from "../schema";
import { ExpandOnce, ExpansionsList } from "./acronymRulePanel.styles";

export interface AcronymRulePanelProps {
  form: RuleForm<"Acronym">;
}

export function AcronymRulePanel(props: AcronymRulePanelProps) {
  const { form } = props;

  const [newExpansion, setNewExpansion] = useState("");
  const [addSuggestionError, setAddSuggestionError] = useState("");

  return (
    <>
      <FormGroup field={form.acronym} helperText="Write an acronym in all caps" label="Acronym">
        <InputGroup field={form.acronym} maxLength={100} placeholder="Enter an acronym..." />
      </FormGroup>
      <FormGroup field={form.expansions} helperText="Suggested replacements in preferred order" label="Expansions to suggest">
        <Tooltip isOpen content={addSuggestionError} disabled={!addSuggestionError} intent="danger" openOnTargetFocus={false} placement="top-start">
          <InputGroup
            maxLength={200}
            placeholder="Enter expansion..."
            rightElement={<Button square disabled={!newExpansion.trim()} label="Add" onClick={handleAddExpansion} />}
            value={newExpansion}
            onBlur={handleExpansionBlur}
            onChange={setNewExpansion}
            onKeyDown={handleExpansionKeyDown}
          />
        </Tooltip>
        {form.expansions.value.length > 0 && (
          <ExpansionsList
            removable
            sortable={form.expansions.value.length > 1}
            tagProps={{ icon: form.expansions.value.length > 1 ? <DragHandleVertical /> : undefined }}
            values={form.expansions.value}
            onChange={handleExpansionsChange}
          />
        )}
      </FormGroup>
      <ExpandOnce field={form.expandOnce} label={Text.ExpandOnce} />
    </>
  );

  function handleExpansionsChange(expansions: string[]) {
    form.expansions.onChange(expansions);
    form.expansions.onTouched();
  }

  function handleExpansionKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    setAddSuggestionError("");

    if (isEnterKeyPress(event)) {
      handleAddExpansion();
    }
  }

  function handleExpansionBlur() {
    setAddSuggestionError("");
  }

  function handleAddExpansion() {
    const value = newExpansion.trim();

    // Avoid duplicates
    const alreadyExists = form.expansions.value.includes(value);

    if (alreadyExists) {
      setAddSuggestionError("This expansion is already present in the list.");
      return;
    }

    setNewExpansion("");
    setAddSuggestionError("");

    form.expansions.onChange([...form.expansions.value, value]);
    form.expansions.onTouched();
  }
}
