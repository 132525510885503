import styled from "styled-components";
import { Scrollbar } from "@remhealth/ui";

export const ScrollArea = styled(Scrollbar)`
  width: 400px;
  max-height: 400px;
`;

export const Content = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin: 0 0 10px 0;
  font-size: var(--font-size-large);
  color: ${props => props.theme.font.muted};

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

export const TagContainer = styled.div`
  .bp5-tag {
    justify-content: flex-start;
    height: 20px;
    margin-bottom: 5px;
  }
`;
