import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  height: 100%;
`;

export const ActionCell = styled.td`
  text-align: right;

  .bp5-button {
    margin-right: 10px;
  }
`;

export const Grid = styled.div`
  flex: auto;
  overflow: hidden;
  position: relative;

  table {
    margin-top: 25px;
  }
`;

export const BuildDisplayCell = styled.td`
  .display {
    font-size: var(--font-size-header4);
  }
  .build {
    color: var(--themecolor2);
    vertical-align: text-bottom;
  }
`;

export const ArtifactBar = styled.div`
  align-self: center;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const SourceBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ChannelNameCell = styled.th`
  width: 80px;
  text-align: center;
  padding-left: 12px !important;
  padding-right: 12px !important;

  && {
    position: relative;
    overflow: visible;
  }

  && + && {
    border-left: 1px solid var(--light-gray3);
  }

  .follow {
    position: absolute;
    left: calc(50% - 15px);
    top: -22px;
  }
`;

export const ChannelBuild = styled.td`
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;

  && + && {
    border-left: 1px solid var(--light-gray3);
  }
`;

export const CommitCell = styled.td`
  font-size: var(--font-size-small);

  .commit {
    display: inline-block;
    width: 50px;
    height: 24px;
    line-height: 24px;
  }
`;

export const StatusCell = styled.td`
  .bp5-icon {
    color: var(--gray2);
    margin-left: 5px;
  }
`;

export const Notes = styled.div`
  h5 {
    color: inherit;
    margin: 0 0 5px 0;
  }

  p + h5 {
    margin-top: 15px;
  }

  p {
    margin: 0;
    white-space: pre-wrap;
  }
`;
