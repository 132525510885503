import styled from "styled-components";
import { IconButton, MenuItem, type MenuItemProps } from "@remhealth/ui";
import { Link } from "@remhealth/icons";
import { CopyButton } from "./copyButton";

const ExtensionMenuItem = styled(MenuItem)`
  font-family: monospace;
  font-size: var(--font-size-small);

  .bp5-menu-item-label {
    margin-left: 20px;
  }
`;

export interface DataMenuItemProps extends Omit<MenuItemProps, "info" | "readonly" | "tagName"> {
  value?: string;
  url?: string;
}

export const DataMenuItem = (props: DataMenuItemProps) => {
  const { value, url, ...restProps } = props;

  return (
    <ExtensionMenuItem
      readonly
      info={(
        <>
          {value}{" "}
          {value && <CopyButton copyContent={value} />}
          {url && <IconButton aria-label="Open link" href={url} icon={<Link />} rel="noopener noreferrer" target="_blank" />}
        </>
      )}
      tagName="div"
      {...restProps}
    />
  );
};
