import { createContext, useContext } from "react";
import type Quill from "quill";
import type { QuillEditor } from "~/editor/quillEditor";

export interface QuillEditorContext {
  editor: QuillEditor;
  version: number;
}

export const QuillEditorContext = createContext<QuillEditorContext | null>(null);

/**
 * Returns a quill editor.
 */
export function useQuillEditor(): QuillEditor {
  const context = useContext(QuillEditorContext);

  if (!context) {
    throw new Error("The useQuillEditor hook must be used inside the <Compose> component's context.");
  }

  return context.editor;
}

/**
 * Returns a quill instance.
 */
export function useQuill(): Quill | null {
  const editor = useQuillEditor();

  if (!editor.mounted) {
    return null;
  }

  return editor.quill;
}
