import { Classes, Intent, TabId } from "@blueprintjs/core";
import classnames from "classnames";
import { intentClass, removeNonHtmlProps } from "../utils";
import { TabProps } from "./tab";
import { Icon } from "./icon";
import { Tag } from "./tag";

export interface TabTitleProps extends TabProps {
  /** Optional contents. */
  children?: React.ReactNode;

  /** ID of the parent `Tabs` to which this tab belongs. Used to generate ID for ARIA attributes. */
  parentId: TabId;

  /** Whether the tab is currently selected. */
  selected: boolean;

  intent?: Intent;

  /** Handler invoked when this tab is clicked. */
  onClick: (id: TabId, event: React.MouseEvent<HTMLElement>) => void;
}

export const TabTitle = (props: TabTitleProps) => {
  const {
    className,
    children,
    disabled,
    id,
    parentId,
    selected,
    icon,
    tagContent,
    tagProps,
    title,
    titleElement,
    intent = selected ? Intent.PRIMARY : Intent.NONE,
    onClick,
    ...htmlProps
  } = props;

  return (
    <div
      {...removeNonHtmlProps(htmlProps)}
      aria-controls={generateTabPanelId(parentId, id)}
      aria-disabled={disabled}
      aria-expanded={selected}
      aria-selected={selected}
      className={classnames(Classes.TAB, intentClass(intent), className)}
      data-tab-id={id}
      id={generateTabTitleId(parentId, id)}
      role="tab"
      tabIndex={disabled ? undefined : selected ? 0 : -1}
      onClick={disabled ? undefined : handleClick}
    >
      {icon != null && <Icon className={Classes.TAB_ICON} icon={icon} intent={intent} />}
      {titleElement ?? title}
      {children}
      {tagContent != null && (
        <Tag
          minimal
          intent={intent}
          {...tagProps}
          className={classnames(Classes.TAB_TAG, tagProps?.className)}
        >
          {tagContent}
        </Tag>
      )}
    </div>
  );

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    onClick(id, e);
  }
};

export function generateTabPanelId(parentId: TabId, tabId: TabId) {
  return `${parentId}.tabpanel[${tabId}]`;
}

export function generateTabTitleId(parentId: TabId, tabId: TabId) {
  return `${parentId}.tab[${tabId}]`;
}
