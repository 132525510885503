import styled from "styled-components";
import { Intent, intentColor } from "@remhealth/ui";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .bp5-form-group {
    min-width: 300px;
  }

  table {
    width: 100%;
    background-color: white;

    .caretCell {
      width: 15px;
      text-align: center;
      padding: 0 0 0 3px !important;
    }

    .iconCell {
      width: 20px;
      text-align: center;
      padding: 0 0 0 11px !important;
    }

    .nameCell {
      width: 200px;

      label {
        margin-left: 0;
      }
    }
  }

  > button {
    align-self: flex-start;
  }
`;

export const PopoverContent = styled.div`
  padding: 5px;
`;

export interface IntentCellProps {
  $intent: Intent;
}

export const IntentCell = styled.td<IntentCellProps>`
  color: ${props => props.$intent === Intent.NONE ? props.theme.font.muted : intentColor(props.$intent)};
`;

export interface CollapsibleRowProps {
  $isOpen: boolean;
}

export const CollapsibleRow = styled.tr<CollapsibleRowProps>`
  display: ${props => !props.$isOpen ? "none" : ""};
`;
