import { HealthcareService, ParameterPresence, PracticePreferences, Program } from "@remhealth/apollo";
import { ImportProps } from "./common";

export interface ImportPracticePreferencesResult {
  practicePreferences: PracticePreferences | null;
  programOverrides: Program[];
  overlapServices: HealthcareService[];
}

export async function importPracticePreferences(props: ImportProps): Promise<ImportPracticePreferencesResult> {
  const {
    targetPractice,
    preview,
    sourceClient,
    targetClient,
    referenceFactory,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemUpdate,
    onItemComplete,
  } = props;

  const practicePreferences = await importPracticePreferencesData();
  const programOverrides = await importProgramOverrides();
  const overlapServices = await importOverlapServices();

  return { practicePreferences, programOverrides, overlapServices };

  async function importPracticePreferencesData(): Promise<PracticePreferences | null> {
    const sourcePracticePreferences = (await sourceClient.practicePreferences.feed({
      filters: [],
    }).all({ abort })).find(f => f);

    if (!sourcePracticePreferences) {
      return null;
    }

    onUpdate(1);
    onItemCreate(sourcePracticePreferences.id, "Organization Preferences");

    const targetPracticePreferences = await targetClient.practicePreferences.feed({
      filters: [{ includeDeleted: true }],
    }).all({ abort });
    const practicePreferences = targetPracticePreferences.find(f => !f.meta?.isDeleted);

    if (targetPracticePreferences.some(p => p.id === sourcePracticePreferences.id)) {
      onItemSkip(sourcePracticePreferences.id, "IdExisted");
      return null;
    }

    const item = {
      ...sourcePracticePreferences,
      practice: targetPractice,
      emailTestingRecipients: [],
    };

    if (sourcePracticePreferences.noteCreationForCancelledAppointment) {
      const serviceReferenceMapper = await referenceFactory.createMapperByIdentifier("healthcareServices", [sourcePracticePreferences.noteCreationForCancelledAppointment.serviceType], abort);
      const noteDefinitionReferenceMapper = await referenceFactory.createMapperById("noteDefinitions", [sourcePracticePreferences.noteCreationForCancelledAppointment.definition], abort);
      const definition = noteDefinitionReferenceMapper.map(sourcePracticePreferences.noteCreationForCancelledAppointment.definition);
      const serviceType = serviceReferenceMapper.map(sourcePracticePreferences.noteCreationForCancelledAppointment.serviceType);
      if (!definition) {
        onItemError(sourcePracticePreferences.id, `Note type "${sourcePracticePreferences.noteCreationForCancelledAppointment.definition}" not found for cancelled appointment note creation`);
      }
      if (!serviceType) {
        onItemError(sourcePracticePreferences.id, `Service "${sourcePracticePreferences.noteCreationForCancelledAppointment.serviceType}" not found for cancelled appointment note creation`);
      }
      item.noteCreationForCancelledAppointment = definition && serviceType ? {
        definition,
        serviceType,
        category: sourcePracticePreferences.noteCreationForCancelledAppointment.category,
      } : undefined;
    }

    if (practicePreferences && !preview) {
      try {
        await targetClient.practicePreferences.deleteById(practicePreferences.id, { abort });
        onItemUpdate(sourcePracticePreferences.id, practicePreferences.id);
      } catch (error) {
        onItemError(sourcePracticePreferences.id, "Failed to delete existing item");
        // eslint-disable-next-line no-console
        console.error(error);
        return null;
      }
    }

    try {
      const result = !preview
        ? await targetClient.practicePreferences.update({ ...item, meta: undefined }, { abort })
        : item;

      onItemComplete(sourcePracticePreferences.id);
      return result;
    } catch (error) {
      onItemError(sourcePracticePreferences.id, "Failed to create", true);
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  async function importProgramOverrides(): Promise<Program[]> {
    const sourcePrograms = await sourceClient.programs.feed({
      filters: [{ sessionToSignIncludesWeekends: { presence: ParameterPresence.MustBePresent }, includeDeleted: true }],
    }).all({ abort });

    onUpdate(sourcePrograms.length);
    if (sourcePrograms.length === 0) {
      return [];
    }

    const programReferenceMapper = await referenceFactory.createMapperByIdentifier("programs", sourcePrograms, abort);

    const results: Program[] = [];
    for (const sourceProgram of sourcePrograms) {
      onItemCreate(sourceProgram.id, sourceProgram.name);

      const program = programReferenceMapper.map(sourceProgram);
      if (program) {
        if (program.noteComplianceOverrides) {
          onItemError(sourceProgram.id, "Program already has override settings", true);
        } else {
          try {
            if (!preview) {
              program.noteComplianceOverrides = sourceProgram.noteComplianceOverrides;
              results.push(await targetClient.programs.update(program, { abort }));
            } else {
              results.push(program);
            }
            onItemComplete(sourceProgram.id);
          } catch (error) {
            onItemError(sourceProgram.id, "Failed to update for program overrides", true);
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      } else {
        onItemError(sourceProgram.id, `Program "${sourceProgram.name}" not found for program overrides`, true);
      }
    }

    return results;
  }

  async function importOverlapServices(): Promise<HealthcareService[]> {
    const sourceServices = await sourceClient.healthcareServices.feed({
      filters: [{ overlappingServiceExceptions: { presence: ParameterPresence.MustBePresent }, includeDeleted: true }],
    }).all({ abort });

    onUpdate(sourceServices.length);
    if (sourceServices.length === 0) {
      return [];
    }

    const allSourceServices = [...sourceServices, ...sourceServices.flatMap(s => s.overlappingServiceExceptions)];

    const serviceReferenceMapper = await referenceFactory.createMapperByIdentifier("healthcareServices", allSourceServices, abort);

    const results: HealthcareService[] = [];
    for (const sourceService of sourceServices) {
      onItemCreate(sourceService.id, sourceService.name);

      const service = serviceReferenceMapper.map(sourceService);
      if (service) {
        if (service.overlappingServiceExceptions.length) {
          onItemError(sourceService.id, "Service already has overlap setting", true);
        } else {
          const overlapServices = serviceReferenceMapper.map(sourceService.overlappingServiceExceptions, (unmatched) => {
            onItemError(sourceService.id, `Service "${unmatched.display}" not found for overlap setting`);
          });

          if (overlapServices.length === 0) {
            onItemError(sourceService.id, "No valid overlap service", true);
            continue;
          }

          try {
            if (!preview) {
              service.overlappingServiceExceptions = overlapServices;
              results.push(await targetClient.healthcareServices.update(service, { abort }));
            } else {
              results.push(service);
            }
            onItemComplete(sourceService.id);
          } catch (error) {
            onItemError(sourceService.id, "Failed to update for service overlap", true);
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      } else {
        onItemError(sourceService.id, `Service "${sourceService.name}" not found for service overlap`, true);
      }
    }

    return results;
  }
}
