import styled from "styled-components";
import { Dialog, MultistepDialog } from "@remhealth/ui";

export const SizedDialog = styled(Dialog)`
  width: 300px;

  .bp5-dialog-body {
    text-align: center;
  }

  h4 {
    text-align: center;
  }

  .bp5-progress-bar {
    margin: 20px 0;
  }
`;

export const SizedMultistepDialog = styled(MultistepDialog)`
  .bp5-dialog-body {
    height: 400px;
  }
`;
