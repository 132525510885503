import { useEffect, useState } from "react";
import { AcronymRule, LiteralRule, LonelyNounRule, NounRule, SpeechRuleCategory, SpeechRuleTemplate, StaffVerbObjectRule, StaffVerbPatientRule, StaffVerbRepeatedRule, StaffVerbRule } from "@remhealth/apollo";
import { type DiscriminatedType, useAbort } from "@remhealth/ui";
import { useRegistry } from "~/contexts";
import { RuleList, SpeechRuleTemplateForm } from "../speechRules";
import { EditRuleDialog } from "../speechRules/editRuleDialog";
import { Container, Description, PageTitle } from "./recommendations.styles";

export const Recommendations = () => {
  const registry = useRegistry();
  const abort = useAbort();

  const [rules, setRules] = useState<SpeechRuleTemplate[]>([]);
  const [rule, setRule] = useState<SpeechRuleTemplateForm>();

  useEffect(() => {
    loadRules();
  }, []);

  const discouragedVerbRules = getRules(rules, "StaffVerb", "StaffVerbPatient", "StaffVerbObject").sort(sortBy(r => r.rule.verb));
  const repeatedVerbRules = getRules(rules, "StaffVerbRepeated").sort(sortBy(r => r.rule.verb));
  const nounRules = getRules(rules, "Noun").sort(sortBy(r => r.rule.noun));
  const lonelyNounRules = getRules(rules, "LonelyNoun").sort(sortBy(r => r.rule.noun));
  const acronymRules = getRules(rules, "Acronym").sort(sortBy(r => r.rule.acronym));
  const literalRules = getRules(rules, "Literal").sort(sortBy(r => r.rule.text));

  return (
    <Container>
      <PageTitle>Clinical Recommendations</PageTitle>
      <RuleList<StaffVerbRule | StaffVerbPatientRule | StaffVerbObjectRule>
        noResults={<>You are not discouraging any verbs yet.</>}
        ruleRenderer={rule => rule.verb}
        rules={discouragedVerbRules}
        rulesEqual={(a, b) => a.verb === b.verb}
        onAdd={handleAddVerbRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Discouraged Verbs</h3>
          <p>Discourage your staff from describing themselves as the actor of some <strong>verbs</strong>.</p>
        </Description>
      </RuleList>
      <RuleList<NounRule>
        noResults={<>You are not restricting any nouns yet.</>}
        ruleRenderer={rule => rule.noun}
        rules={nounRules}
        rulesEqual={(a, b) => a.noun === b.noun}
        onAdd={handleAddNounRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Restricted Nouns</h3>
          <p>Prevent your staff from using specific <strong>nouns</strong> in their documentation.</p>
        </Description>
      </RuleList>
      <RuleList<LonelyNounRule>
        noResults={<>You don&apos;t have any ambiguous nouns set up yet.</>}
        ruleRenderer={rule => rule.noun}
        rules={lonelyNounRules}
        rulesEqual={(a, b) => a.noun === b.noun}
        onAdd={handleAddLonelyNounRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Ambiguous Nouns</h3>
          <p>Reduce ambiguity by requiring adjectival or adverbial modifiers on these <strong>nouns</strong>.</p>
        </Description>
      </RuleList>
      <RuleList<LiteralRule>
        noResults={<>You don&apos;t have any literal rules set up yet.</>}
        ruleRenderer={rule => rule.text}
        rules={literalRules}
        rulesEqual={(a, b) => a.text === b.text}
        onAdd={handleAddLiteralRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Forbidden Text</h3>
          <p>Noun? Verb? Phrase? Who cares! These should simply never be written in notes.  Can be anything!</p>
        </Description>
      </RuleList>
      <RuleList<AcronymRule>
        noResults={<>You don&apos;t have any acronym expansions set up yet.</>}
        ruleRenderer={rule => rule.acronym.toUpperCase()}
        rules={acronymRules}
        rulesEqual={(a, b) => a.acronym === b.acronym}
        onAdd={handleAddAcronymRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Expand Acronyms</h3>
          <p>Some <strong>acronyms</strong> do more harm than good.  Help encourage your staff to write out the full form of an acronym.</p>
        </Description>
      </RuleList>
      <RuleList<StaffVerbRepeatedRule>
        noResults={<>You don&apos;t have any redundant verbs set up yet.</>}
        ruleRenderer={rule => rule.verb}
        rules={repeatedVerbRules}
        rulesEqual={(a, b) => a.verb === b.verb}
        onAdd={handleAddRepeatedVerbRule}
        onEdit={handleEditRule}
      >
        <Description>
          <h3>Redundant Much?</h3>
          <p>Are your staff using the same <strong>verb</strong> over and over in their notes? Help them with some alternative language!</p>
        </Description>
      </RuleList>
      <EditRuleDialog rule={rule} onClose={handleEditDialogClose} onCopy={handleCopy} />
    </Container>
  );

  async function loadRules() {
    const feed = registry.speechRuleTemplates.feed({});
    setRules(await feed.all({ abort: abort.signal }));
  }

  function handleCopy(rule: SpeechRuleTemplateForm) {
    setRule({
      rule: rule.rule,
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleEditDialogClose() {
    setRule(undefined);
    loadRules();
  }

  function sortBy<TRule>(expression: (rule: Rule<TRule>) => string) {
    return (left: Rule<TRule>, right: Rule<TRule>) => {
      const compare = expression(left).localeCompare(expression(right));
      if (compare === 0) {
        return left.id.localeCompare(right.id);
      }
      return compare;
    };
  }

  function handleEditRule(rule: SpeechRuleTemplate) {
    setRule({
      ...rule,
      category: rule.category,
      // Ensures all properties are defined, or else formik will not validate properly
      rule: rule.rule.type === "StaffVerb"
        || rule.rule.type === "StaffVerbPatient"
        || rule.rule.type === "StaffVerbObject"
        || rule.rule.type === "StaffVerbRepeated"
        || rule.rule.type === "LonelyNoun"
        || rule.rule.type === "Noun"
        || rule.rule.type === "Literal"
        ? { instruction: undefined, ...rule.rule }
        : rule.rule,
    });
  }

  function handleAddVerbRule() {
    setRule({
      rule: { type: "StaffVerb", verb: "", tenses: [], alternatives: [], instruction: undefined },
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleAddRepeatedVerbRule() {
    setRule({
      rule: { type: "StaffVerbRepeated", verb: "", tenses: [], alternatives: [], instruction: undefined },
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleAddNounRule() {
    setRule({
      rule: { type: "Noun", noun: "", alternatives: [], instruction: undefined },
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleAddLonelyNounRule() {
    setRule({
      rule: { type: "LonelyNoun", noun: "", adjectives: [], instruction: undefined },
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleAddLiteralRule() {
    setRule({
      rule: { type: "Literal", text: "", caseSensitive: false, alternatives: [], instruction: undefined },
      category: SpeechRuleCategory.AnyService,
    });
  }

  function handleAddAcronymRule() {
    setRule({
      rule: { type: "Acronym", acronym: "", expansions: [], expandOnce: false },
      category: SpeechRuleCategory.AnyService,
    });
  }
};

interface Rule<TRule> extends Omit<SpeechRuleTemplate, "rule"> {
  rule: TRule;
}

type SpeechRuleType = SpeechRuleTemplate["rule"]["type"];
type DiscriminatedSpeechRule<TRuleType extends SpeechRuleType> = DiscriminatedType<SpeechRuleTemplate["rule"], "type", TRuleType>;

function isRuleKind<TRuleType extends SpeechRuleType>(type: TRuleType): ((rule: SpeechRuleTemplate) => rule is Rule<DiscriminatedSpeechRule<TRuleType>>) {
  return (rule: SpeechRuleTemplate): rule is Rule<DiscriminatedSpeechRule<TRuleType>> => rule.rule.type === type;
}

function getRules<TRuleType extends SpeechRuleType>(rules: SpeechRuleTemplate[], ...types: TRuleType[]): Rule<DiscriminatedSpeechRule<TRuleType>>[] {
  return types.flatMap(type => rules.filter(isRuleKind(type)));
}
