import styled, { css } from "styled-components";
import { TabId } from "@blueprintjs/core";
import { hiddenscroll } from "../modernizr";

export interface ContainerProps {
  $id: TabId;
  $tabIds: TabId[];
}

export const Container = styled.div<ContainerProps>`
  &:not(.bp5-vertical) {
    .bp5-tab-list {
      overflow-x: ${hiddenscroll ? "auto" : "hidden"};
      overflow-y: hidden;
    }
  }

  ${props => props.$tabIds.map(tabId => css`
    .bp5-tab-list:has(~ .bp5-tab-panel[id="${props.$id}.tabpanel[${tabId}]"] *[aria-invalid="true"]) {
      .bp5-tab[id="${props.$id}.tab[${tabId}]"]:not([aria-selected="true"]) {
        color: ${props => props.theme.font.invalid};
      }
    }
  `)};
`;
