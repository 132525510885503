import { useState } from "react";
import { Database as DatabaseIcon } from "@remhealth/icons";
import { DatabaseConnection } from "@remhealth/apollo";
import { Button, FormField, PagingResult } from "@remhealth/ui";
import { resourceEquals } from "@remhealth/host";
import { CopyButton } from "@remhealth/core";
import { useRegistry } from "~/contexts";
import { CreateDatabaseDialog, Database } from "./createDatabaseDialog";
import { DatabaseAsyncSuggest } from "./databaseSuggest.styles";

export interface DatabaseSuggestProps {
  field: FormField<Database | undefined>;
}

export type { Database };

export const DatabaseSuggest = (props: DatabaseSuggestProps) => {
  const { field } = props;

  const registry = useRegistry();

  const [showDbDialog, setShowDbDialog] = useState(false);

  return (
    <>
      <DatabaseAsyncSuggest
        clearable
        fetchOnBlankQuery
        fill
        field={field}
        itemsEqual={resourceEquals}
        leftIcon={<DatabaseIcon />}
        optionRenderer={d => d.display ?? ""}
        queryable={databaseConnectionSource}
        rightElement={!field.value
          ? <Button square label="Add New Database" onClick={openDbDialog} />
          : undefined}
      />
      {field.value && <CopyButton copyContent={field.value.display ?? ""} /> }
      <CreateDatabaseDialog
        isOpen={showDbDialog}
        onClose={closeDbDialog}
        onSave={handleCreate}
      />
    </>
  );

  async function databaseConnectionSource(query: string, abort: AbortSignal): Promise<PagingResult<Database>> {
    const data = await registry.databaseConnections.query({
      filters: [
        {
          name: { startsWithAllWords: query },
        },
        {
          serverAddress: { startsWithAllWords: query },
        },
      ],
      feedOptions: {
        maxItemCount: 10,
      },
      abort,
    });
    return {
      items: data.results.map(d => ({
        id: d.id,
        display: databaseTextRenderer(d),
      })),
      hasMore: !!data.continuationToken,
    };
  }

  function handleCreate(database: Database): void {
    field.onChange(database);
  }

  function openDbDialog() {
    setShowDbDialog(true);
  }

  function closeDbDialog() {
    setShowDbDialog(false);
  }
};

export function databaseTextRenderer(database: DatabaseConnection) {
  return `${database.serverAddress}\\${database.name}`;
}
