import { useMemo, useState } from "react";
import { useUpdateEffect } from "@remhealth/ui";

export interface ComposeControlables {
  initialValue?: string;
  readonly?: boolean;
  disabled?: boolean;
}

export interface ComposeController {
  readonly initialValue: string;
  readonly readonly: boolean;
  readonly disabled: boolean;
  readonly version: number;

  setInitialValue(initialValue: string): void;
  makeEnabled(): void;
  makeDisabled(): void;
  makeReadonly(): void;
  reset(): void;
}

export function useComposeController(options?: ComposeControlables): ComposeController {
  const {
    initialValue: controlledInitialValue,
    readonly: controlledReadonly,
    disabled: controlledDisabled,
  } = options ?? {};

  const [initialValue, setInitialValue] = useState(controlledInitialValue ?? "");
  const [readonly, setReadonly] = useState(controlledReadonly ?? false);
  const [disabled, setDisabled] = useState(controlledDisabled ?? false);
  const [version, setVersion] = useState(0);

  useUpdateEffect(() => {
    if (controlledInitialValue !== undefined) {
      setInitialValue(controlledInitialValue);
    }
    if (controlledReadonly !== undefined) {
      setReadonly(controlledReadonly);
    }
    if (controlledDisabled !== undefined) {
      setDisabled(controlledDisabled);
    }
  }, [controlledInitialValue, controlledReadonly, controlledDisabled]);

  return useMemo(() => ({
    initialValue,
    readonly,
    disabled,
    version,
    setInitialValue: updateInitialValue,
    makeEnabled,
    makeDisabled,
    makeReadonly,
    reset,
  }), [version, initialValue, readonly, disabled]);

  function updateInitialValue(initialValue: string) {
    setInitialValue(initialValue);
    setVersion(v => v + 1);
  }

  function makeEnabled() {
    setReadonly(false);
    setDisabled(false);
  }

  function makeDisabled() {
    setReadonly(false);
    setDisabled(true);
  }

  function makeReadonly() {
    setReadonly(true);
    setDisabled(false);
  }

  function reset() {
    setVersion(v => v + 1);
  }
}
