import styled from "styled-components";
import { Button, InputGroup } from "@remhealth/ui";
import { Compose } from "@remhealth/compose";

export const FeaturesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h5 {
    margin-top: 0;
  }
`;

export const NameInput = styled(InputGroup)`
  width: 300px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 15px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const Maintainence = styled(Container)`
  gap: 5px;

  h5 {
    margin-bottom: 0;
  }
`;

export const ConnectionDetails = styled(Button)`
  margin-top: 20px;
`;

export const NotesSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 5px;

  > button {
    align-self: flex-start;
  }
`;

export const NotesCompose = styled(Compose)`
  width: 100%;
  min-height: 200px;
`;

export const NotesSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;

  h5 {
    margin-bottom: 0;
  }
`;
