import { values } from "lodash-es";
import { Text } from "text";
import {
  AcronymRule,
  LiteralRule,
  LonelyNounRule,
  MissingActorRule,
  NonThirdPersonRule,
  NounAlternative,
  NounRule,
  PatientAssessmentRule,
  PatientNounRule,
  SpeechRule,
  StaffVerbObjectRule,
  StaffVerbPatientRule,
  StaffVerbRepeatedRule,
  StaffVerbRule,
  Tense,
  VerbAlternative,
  VerbRule
} from "@remhealth/apollo";
import { type DiscriminatedType, Form } from "@remhealth/ui";
import { type Complete, Yup } from "~/validation";

export type SpeechRuleRuleForm = Complete<SpeechRule["rule"]>;

const singleWordRegex = /^\s*\S*\s*$/g; // Can have trailing spaces or be blank, but not two words
export const lemmaRegex = () => /^\s*\w+\s*$/g; // Must be a single word, can have trailing spaces or be blank

export function speechRuleSchema(strict: boolean) {
  const AcronymRuleSchema = Yup.object<Complete<AcronymRule>>({
    type: Yup.mixed<"Acronym">().required().oneOf(["Acronym"]),
    acronym: Yup.string().label("Acronym").required().max(100).trim().uppercase().matches(/^\w+$/g, "Must be alphanumeric without spaces."),
    expansions: Yup.array(
      Yup.string().required().max(200).trim()
    ).label("Suggested expansions").min(1, "You must enter at least one expansion to suggest").unique(),
    expandOnce: Yup.boolean().required().label(Text.ExpandOnce),
  });

  const TenseSchema = Yup.mixed<Tense>().required().oneOf(values(Tense));
  const VerbList = Yup.array(
    Yup.string()
      .label("Suggestion")
      .required()
      .max(100)
      .trim()
      .test("upper-or-lowercase", "Verb must be all lowercase or all uppercase.", isStringUpperOrLowercase)
  ).unique();

  const VerbRuleSchema = Yup.object<Complete<VerbRule>>({
    verb: Yup.string().label("Verb").required().trim().max(100).lowercase().trim()
      .matches(singleWordRegex, "Do not include more than one word.  Prepositions and particles (with, in on, about, etc) will be detected automatically.")
      .matches(lemmaRegex(), "Must be alphanumeric without spaces."),
    tenses: Yup.array(TenseSchema).unique(),
    instruction: Yup.string().label("Instruction").max(300).trim()
      .when("alternatives", {
        is: values => strict && (!values || values.length === 0),
        then: Yup.string().required("Instructions must be given when no suggestions are listed"),
      }),
    alternatives: Yup.array(
      Yup.object<VerbAlternative>({
        vb: VerbList,
        vbd: VerbList,
        vbg: VerbList,
        vbn: VerbList,
        vbp: VerbList,
        vbz: VerbList,
      })
    ).label("Suggestions"),
  });

  function createVerbRuleSchema<T extends VerbRule>(overrides: Yup.ObjectSchema<Omit<T, keyof VerbRule>>): Yup.ObjectSchema<T> {
    return overrides.concat(VerbRuleSchema) as Yup.ObjectSchema<T>;
  }

  const StaffVerbRuleSchema = createVerbRuleSchema<Complete<StaffVerbRule>>(Yup.object<Pick<StaffVerbRule, "type">>({
    type: Yup.mixed<"StaffVerb">().required().oneOf(["StaffVerb"]),
  }));

  const StaffVerbPatientRuleSchema = createVerbRuleSchema<Complete<StaffVerbPatientRule>>(Yup.object<Pick<StaffVerbPatientRule, "type">>({
    type: Yup.mixed<"StaffVerbPatient">().required().oneOf(["StaffVerbPatient"]),
  }));

  const StaffVerbObjectRuleSchema = createVerbRuleSchema<Complete<StaffVerbObjectRule>>(Yup.object<Pick<StaffVerbObjectRule, "type" | "objects">>({
    type: Yup.mixed<"StaffVerbObject">().required().oneOf(["StaffVerbObject"]),
    objects: Yup.array(
      Yup.string().required().max(100).trim()
    ).label("Nouns").min(1, "You must enter at least one noun").unique(),
  }));

  const StaffVerbRepeatedRuleSchema = createVerbRuleSchema<Complete<StaffVerbRepeatedRule>>(Yup.object<Pick<StaffVerbRepeatedRule, "type">>({
    type: Yup.mixed<"StaffVerbRepeated">().required().oneOf(["StaffVerbRepeated"]),
  }));

  const LiteralRuleSchema = Yup.object<Complete<LiteralRule>>({
    type: Yup.mixed<"Literal">().required().oneOf(["Literal"]),
    text: Yup.string().label("Word or phrase").required("Word or phrase is required").max(100).trim(),
    instruction: Yup.string().label("Instruction").max(300).trim()
      .when("alternatives", {
        is: values => strict && (!values || values.length === 0),
        then: Yup.string().required("Instructions must be given when no suggestions are listed"),
      }),
    caseSensitive: Yup.boolean(),
    alternatives: Yup.array(
      Yup.string().required().trim()
    ).label("Suggestions").unique(),
  });

  const nounRegex = /^[\s\w]+$/g;

  const LonelyNounRuleSchema = Yup.object<Complete<LonelyNounRule>>({
    type: Yup.mixed<"LonelyNoun">().required().oneOf(["LonelyNoun"]),
    noun: Yup.string().label("Noun").required().max(100).trim().matches(nounRegex, "Must be alphanumeric."),
    instruction: Yup.string().label("Instruction").max(300).trim(),
    adjectives: Yup.array(
      Yup.string().required().max(100).trim()
    ).label("Adjectives").min(1, "Must list at least one adjective to suggest").unique(),
  });

  const NounWordSchema = Yup.string()
    .label("Suggestion")
    .required()
    .max(100)
    .trim()
    .test("upper-or-lowercase", "Suggestion must be all lowercase or all uppercase.", isStringUpperOrLowercase);

  const NounRuleSchema = Yup.object<Complete<NounRule>>({
    type: Yup.mixed<"Noun">().required().oneOf(["Noun"]),
    noun: Yup.string().label("Noun").required().max(100).trim().lowercase().matches(nounRegex, "Must be alphanumeric."),
    instruction: Yup.string().label("Instruction").max(300).trim()
      .when("alternatives", {
        is: values => strict && (!values || values.length === 0),
        then: Yup.string().required("Instructions must be given when no suggestions are listed"),
      }),
    alternatives: Yup.array<NounAlternative>(
      Yup.object<NounAlternative>({
        singular: NounWordSchema,
        plural: Yup.array(NounWordSchema).min(1).unique(),
      })
    ).label("Suggestions").unique(undefined, (a, b) => a.singular === b.singular),
  });

  const MissingActorRuleSchema = Yup.object<Complete<MissingActorRule>>({
    type: Yup.mixed<"MissingActor">().required().oneOf(["MissingActor"]),
    alternatives: Yup.array<string>().label("Suggestions").unique(),
  });

  const NonThirdPersonRuleSchema = Yup.object<Complete<NonThirdPersonRule>>({
    type: Yup.mixed<"NonThirdPerson">().required().oneOf(["NonThirdPerson"]),
  });

  const PatientAssessmentRuleSchema = Yup.object<Complete<PatientAssessmentRule>>({
    type: Yup.mixed<"PatientAssessment">().required().oneOf(["PatientAssessment"]),
  });

  const PatientNounRuleSchema = Yup.object<Complete<PatientNounRule>>({
    type: Yup.mixed<"PatientNoun">().required().oneOf(["PatientNoun"]),
    allowPreferredLabel: Yup.boolean().required(),
    allowName: Yup.boolean().required(),
    allowInitials: Yup.boolean().required(),
    requireConsistency: Yup.boolean().required(),
    nouns: Yup.array(
      Yup.string().required().max(50).trim().lowercase()
    ).label("Patient nouns").min(1).unique(),
  });

  function inferRuleSchema(rule: SpeechRuleRuleForm): Yup.ObjectSchema<SpeechRuleRuleForm> {
    switch (rule.type) {
      case "Acronym": return AcronymRuleSchema;
      case "StaffVerb": return StaffVerbRuleSchema;
      case "StaffVerbPatient": return StaffVerbPatientRuleSchema;
      case "StaffVerbObject": return StaffVerbObjectRuleSchema;
      case "StaffVerbRepeated": return StaffVerbRepeatedRuleSchema;
      case "Literal": return LiteralRuleSchema;
      case "LonelyNoun": return LonelyNounRuleSchema;
      case "Noun": return NounRuleSchema;
      case "MissingActor": return MissingActorRuleSchema;
      case "NonThirdPerson": return NonThirdPersonRuleSchema;
      case "PatientAssessment": return PatientAssessmentRuleSchema;
      case "PatientNoun": return PatientNounRuleSchema;
    }
  }

  return Yup.lazy<SpeechRuleRuleForm>(inferRuleSchema) as Yup.ObjectSchema<SpeechRuleRuleForm>;
}

function isStringUpperOrLowercase(value: string | undefined): boolean {
  return !value || value === value.toLowerCase() || value === value.toUpperCase();
}

type SpeechRuleType = SpeechRuleRuleForm["type"];
type DiscriminatedSpeechRule<TRuleType extends SpeechRuleType> = Complete<DiscriminatedType<SpeechRuleRuleForm, "type", TRuleType>>;

export type RuleForm<TRuleType extends SpeechRuleType> = Form<DiscriminatedSpeechRule<TRuleType>>;
