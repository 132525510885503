import styled from "styled-components";

export const AddContainer = styled.div`
  position: absolute;
  left: -210px;
  top: 25px;
  width: 210px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px 20px 0;
`;

export const TagList = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 20px 0 220px;
  min-height: 30px;
  line-height: 30px;

  > p:last-child {
    margin-bottom: 0;
  }

  &:before {
    width: 100%;
    line-height: 15px;
    display: block;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    color: var(--gray3);
    content: "Current Rules";
  }
`;

export const Overview = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  user-select: none;
  border-radius: var(--callout-border-radius);
`;

export const RuleKindContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 20px 30px;
  background: ${props => props.theme.background.card};
  box-shadow: ${props => props.theme.elevations.wide};
  border-radius: var(--callout-border-radius);
`;
