export const routes = {
  root: "/",
  operations: "/operations",
  builds: "/builds",
  recommendations: "/recommendations",
  authRedirect: "/signin-oidc",
  authSilentRenew: "/silent-renew",
  logout: "/logout",
  dropIns: "/drop-ins",
  expansions: "/expansions",
} as const;
