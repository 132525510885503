export type CsvField = string;
export type CsvRow = CsvField[];

export interface Configuration {
  fieldSeparator?: string;
  lineSeparator?: string;
  quote?: string;
}

const DefaultConfiguration = {
  fieldSeparator: ",",
  lineSeparator: "\n",
  quote: '"',
};

interface State {
  appendCell: boolean;
  appendField: boolean;
  appendRow: boolean;
  field: number;
  fieldOffset: number;
  line: number;
  lineOffset: number;
  quoted: boolean;
}

const CSVINITALSTATE: State = {
  field: 0,
  fieldOffset: 0,
  line: 0,
  lineOffset: 0,
  quoted: false,
  appendCell: false,
  appendField: false,
  appendRow: false,
};

interface ParseError extends Error {
  line: number;
  column: number;
}

export function Csv<T = Record<string, string>>(configuration?: Configuration) {
  let rows: CsvRow[] = [];
  let row: CsvField[] = [];
  let cell = "";
  const options = Object.assign({}, DefaultConfiguration, configuration);

  let index = 0;
  let current = "";
  let previous = "";

  let state = { ...CSVINITALSTATE };
  let quoteState = { ...CSVINITALSTATE };

  return {
    get rows() {
      return rows;
    },
    parse,
    cast,
  };

  function parse(text: string): ReadonlyArray<CsvRow> {
    reset();

    for (index = 0; index < text.length; index++) {
      // Skip spaces right after comma
      if (state.appendCell === false && current === "," && text[index] === " ") {
        continue;
      }

      state.appendCell = true;
      previous = current;
      current = text[index];
      handleNext();
    }

    if (row.length > 0) {
      addField(fieldValue(cell), row, state);
      addRow(row, rows, state);
    }

    if (state.quoted) {
      throw {
        line: quoteState.line,
        column: quoteState.lineOffset,
      } as ParseError;
    }

    makeImmutable();

    return rows;
  }

  /**
   * Returns rows as JSON using the first row as property name provider
   */
  function cast(text: string): T[] {
    if (rows.length === 0) {
      parse(text);
    }
    if (rows.length > 0) {
      const keys = rows[0].filter(
        (field) => typeof field === "string"
      ) as string[];

      return rows
        .filter((row, index) => row && index > 0)
        .map((row) => {
          const object = {} as any;
          keys.forEach((key, keyIndex) => {
            object[key] = row[keyIndex];
          });
          return Object.freeze(object);
        });
    }

    return [];
  }

  function handleNext() {
    if (!handleQuote()) {
      if (!handleFieldSeparator()) {
        handleLineSeparator();
      }
    }
    processState();
  }

  function handleQuote() {
    if (current === options.quote) {
      quoteState = { ...state };
      if (previous === "\\") {
        handleQuoteEscaped();
      } else {
        handleQuoteNotEscaped();
      }
      return true;
    }
    return false;
  }

  function handleQuoteEscaped() {
    cell = cell.slice(0, Math.max(0, cell.length - 1));
  }

  function handleQuoteNotEscaped() {
    if (cell.length === 0 || state.quoted) {
      state.quoted = !state.quoted;
    } else {
      throw { line: state.line, column: state.lineOffset } as ParseError;
    }
    state.appendCell = false;
  }

  function handleFieldSeparator() {
    if (current === options.fieldSeparator) {
      if (!state.quoted) {
        state.appendCell = false;
        state.appendField = true;
      }
      return true;
    }
    return false;
  }

  function handleLineSeparator() {
    if (current === options.lineSeparator) {
      if (!state.quoted) {
        state.appendCell = false;
        state.appendField = true;
        state.appendRow = true;
      }
      return true;
    }
    return false;
  }

  function processState() {
    if (state.appendCell) {
      cell += current;
    }

    if (state.appendField) {
      addField(fieldValue(cell), row, state);
      cell = "";
    }

    if (state.appendRow) {
      addRow(row, rows, state);
      row = [] as CsvField[];
    }

    state.lineOffset++;
    state.fieldOffset++;
  }

  function fieldValue(cell: string): CsvField {
    return cell;
  }

  function addField<F extends CsvField, T extends CsvRow>(
    field: F,
    row: T,
    state: State
  ) {
    row.push(field);
    state.field++;
    state.fieldOffset = -1;
    state.appendField = false;
  }

  function addRow<T extends CsvRow>(row: T, rows: T[], state: State) {
    rows.push(row);
    state.field = 0;
    state.line++;
    state.lineOffset = -1;
    state.appendRow = false;
  }

  function makeImmutable() {
    rows.forEach((row) => {
      row.forEach((value) => Object.freeze(value));
      Object.freeze(row);
    });
    Object.freeze(rows);
  }

  function reset() {
    rows = [];
    row = [];
    cell = "";
    state = { ...CSVINITALSTATE };
    index = 0;
    current = "";
    previous = "";
    quoteState = { ...CSVINITALSTATE };
  }
}

export default Csv;
