export const Constants = {
  selectSearchResultSize: 20,
  rankingChoiceLimit: 10, // 10 Choices
  storageKeys: {
    selectedProfile: "selectedProfile_v3",
  },

  exportFileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  exportFileExtension: ".xlsx",
};
