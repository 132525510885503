import { Fragment, useContext, useEffect, useMemo, useRef } from "react";
import Quill from "quill";
import { lazy, useAutomation, useUpdateEffect } from "@remhealth/ui";
import { useQuillEditor } from "~/hooks/useQuillEditor";
import { useImperativeComposeHandle } from "~/hooks/useImperativeComposeHandle";
import type { CommandsHandle, TriggeredCommand } from "~/hooks/useCommand";
import { AutoModulesContext } from "~/modules/autoModules";
import { Signal } from "~/modules/signal";
import matchers from "~/delta/matchers";
import { enableShadowDomSupport } from "./shadowDom";
import Container from "./editableContent.styles";

const Undo = lazy(() => import("~/modules/undo"));
const Inserts = lazy(() => import("~/modules/inserts"));
const Link = lazy(() => import("~/modules/link"));
const Choice = lazy(() => import("~/modules/choice/choice"));
const MaxLength = lazy(() => import("~/modules/maxLength"));

import "./quill.scss";

export interface EditableContentProps {
  /** @default false */
  autoFocus?: boolean;
  /** @default true */
  autoModules?: boolean;
  maxLength?: number;
  maxLines?: number;
  /** @default undefined */
  placeholder?: string;
  /** @default false */
  spellCheck?: boolean;
  /** @default 0 */
  tabIndex?: number;
  id?: string;
}

export const EditableContent = (props: EditableContentProps) => {
  const {
    autoFocus = false,
    autoModules: includeAutoModules = true,
    maxLength,
    maxLines,
    placeholder,
    spellCheck = false,
    tabIndex = 0,
    ...divProps
  } = props;

  const editor = useQuillEditor();
  const { label, id, errorId } = useAutomation({
    ...props,
    id: props.id ?? editor.id,
    field: editor.field,
  });

  const autoModules = useContext(AutoModulesContext);

  const editarea = useRef<HTMLDivElement>(null);

  const lastKnownValue = useRef<string>("");

  const initialValue = useMemo(() => {
    lastKnownValue.current = editor.initialValue;
    return editor.initialValue;
  }, [editor.initialValueVersion]);

  // First rendered html, which never changes
  const skeletonValue = useMemo(() => initialValue, []);

  const quill = useRef<Quill>();
  const ContentPortal = editor.portalTargets.content;

  // Pause announcement of changes while rendering
  editor.pauseEvents();

  useEffect(() => {
    // Allow change announcements after rendering completes
    editor.unpauseEvents();
  });

  useUpdateEffect(() => {
    if (quill.current) {
      setEditorAttributes(quill.current);
    }
  }, [editor.field]);

  useUpdateEffect(() => {
    if (quill.current) {
      const range = quill.current?.selection.lastRange;
      quill.current.setContents(quill.current.clipboard.convert({ html: lastKnownValue.current }));
      quill.current.setSelection(range, "silent");
    }
  }, [editor.initialValueVersion]);

  useEffect(() => {
    if (editarea.current) {
      // Handle race condition where this editor version was already registered
      // in a previous effect
      const reinitializing = editor.hookVersion !== null;
      if (editor.mountVersion !== editor.hookVersion) {
        const range = quill.current?.selection.lastRange;

        quill.current = new Quill(editarea.current, {
          registry: editor.registry.getQuillRegistry(),
          placeholder,
          modules: {
            clipboard: { matchers },
          },
        });

        for (const [selector, matcher] of editor.registry.matchers) {
          quill.current.clipboard.addMatcher(selector, matcher);
        }

        // Enable shadow DOM support if necessary
        if (quill.current.container.getRootNode() instanceof ShadowRoot) {
          enableShadowDomSupport(quill.current);
        }

        if (editor.readonly || editor.disabled) {
          quill.current.enable(false);
        }

        if (range) {
          quill.current.setSelection(range, "silent");
        }

        // Use clipboard to ensure we normalize the expected results to remove
        // any unwanted formatting
        if (reinitializing) {
          quill.current.setContents(quill.current.clipboard.convert({ html: lastKnownValue.current }));
        }

        quill.current.root.style.whiteSpace = "pre-wrap"; // Prevents quill's matchText from collapsing multiple spaces

        editor.hook(quill.current, editor.mountVersion);

        if (autoFocus) {
          editor.focus();
        }
      }

      if (quill.current) {
        quill.current.on("editor-change", handleEditorChange);
        setEditorAttributes(quill.current);
      }
    }

    return () => {
      if (quill.current) {
        quill.current.off("editor-change", handleEditorChange);
      }
    };
  }, [editor, editor.mountVersion, tabIndex, spellCheck, placeholder]);

  // Host CommandsHandle shareable by all commands
  const triggered = useMemo(() => new Signal<TriggeredCommand>(), []);
  useImperativeComposeHandle<CommandsHandle>("Commands", () => ({
    triggered,
  }), [triggered]);

  return (
    <>
      <Container className="quill">
        <div dangerouslySetInnerHTML={{ __html: skeletonValue }} ref={editarea} className="ql-container" />
        {quill.current && <ContentPortal />}
      </Container>
      <Undo />
      <Inserts />
      <Choice />
      <Link />
      {editor.registry.modules.map((module, index) => <Fragment key={index}>{module}</Fragment>)}
      {includeAutoModules && autoModules.map((module, index) => <Fragment key={index}>{module}</Fragment>)}
      {(maxLength || maxLines) && <MaxLength length={maxLength} lines={maxLines} />}
    </>
  );

  function handleEditorChange(event: "text-change" | "selection-change") {
    if (event === "text-change") {
      handleTextChange();
    } else {
      handleSelectionChange();
    }
  }

  function handleTextChange() {
    const currentQuill = quill.current;
    if (currentQuill) {
      lastKnownValue.current = currentQuill.root.innerHTML;
      editor.dispatchChange("text");
    }
  }

  function handleSelectionChange() {
    const currentQuill = quill.current;
    if (currentQuill) {
      editor.dispatchChange("selection");
    }
  }

  function setEditorAttributes(quill: Quill) {
    quill.root.tabIndex = tabIndex;
    quill.root.spellcheck = spellCheck;
    quill.root.setAttribute("spellcheck", spellCheck ? "true" : "false");
    setDataSet("placeholder", placeholder);
    setDataSet("errormessage", editor.field.error ? editor.field.errorText : undefined);
    setDataSet("name", editor.field.name);

    if (id && quill.root.id !== id) {
      quill.root.id = id;
    }

    quill.root.ariaLabel = label ?? null;
    quill.root.ariaInvalid = editor.field.error ? "true" : null;

    if (errorId) {
      quill.root.setAttribute("aria-errormessage", errorId);
    } else {
      quill.root.removeAttribute("aria-errormessage");
    }

    const attrs: Record<string, any> = divProps;
    for (const attr in attrs) {
      quill.root.setAttribute(attr, String(attrs[attr]));
    }

    function setDataSet(name: string, value: any) {
      if (value === undefined) {
        delete quill.root.dataset[name];
      } else {
        quill.root.dataset[name] = value;
      }
    }
  }
};
