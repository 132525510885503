import { DependencyList, type RefAttributes, cloneElement, useMemo } from "react";
import * as Core from "@blueprintjs/select";
import { highlightText, startsWithAllWords } from "../utils";
import { ListOption, ListOptionProps } from "./listOption";

export interface ItemRendererProps {
  query: string;
  active: boolean;
}

export type ItemTextRenderer<T> = (item: T) => string;
export type ItemInfoRenderer<T> = (item: T) => React.ReactNode;
export type ListOptionRenderer<T> = (item: T, itemProps: ItemRendererProps) => string | React.ReactElement<ListOptionProps>;

type PropOmissions =
  | "items"
  | "activeItem"
  | "itemPredicate"
  | "itemRenderer"
  | "itemListRenderer"
  | "onItemSelect"
  | "onActiveItemChange";

export type SelectPopoverProps = Core.SelectPopoverProps["popoverProps"] & {
  /**
   * Sets the max width of the popover. Setting to `null` will mean no maximum.
   * Ignored if `width` is set.
   * @default 400
   */
  maxWidth?: number | null;

  /**
   * Sets the max height of the popover. Setting to `null` will mean no maximum.
   * @default 350
   */
  maxHeight?: number | null;

  /**
   * Sets an exact width of the popover.
   * @default undefined
   */
  width?: number;
};

export interface ListItemsProps<T> extends Omit<Core.ListItemsProps<T>, PropOmissions> {
  /**
   * Renders an item as text or a ListOption.
   */
  optionRenderer: ListOptionRenderer<T>;

  /**
   * Right-aligned content info shown in options list.  This is ignored if `optionRenderer` returns a ListOption.
   */
  infoRenderer?: ItemInfoRenderer<T>;

  /**
   * Customize querying of individual items.  Defaults to using the option text and info using "starts with" logic.
   */
  itemPredicate?: Core.ItemPredicate<T>;
}

export interface ListItemsHookOptions<T> extends ListItemsProps<T> {
  listOptionPropFactory?: (item: T) => Partial<ListOptionProps>;
}

export function useListItems<T>(props: ListItemsHookOptions<T>, deps: DependencyList | undefined) {
  const {
    itemPredicate = defaultItemPredicate,
    infoRenderer,
    optionRenderer,
    listOptionPropFactory,
  } = props;

  return useMemo(() => ({
    itemPredicate,
    itemRenderer,
    textRenderer,
  }), [optionRenderer, infoRenderer, listOptionPropFactory, ...deps ?? []]);

  function defaultItemPredicate(query: string, item: T, _index?: number, exactMatch?: boolean): boolean {
    const option = optionRenderer(item, { query, active: false });
    const text = typeof option === "string" ? option : option.props.text;
    const info = (typeof option !== "string" ? option.props.info : undefined) ?? infoRenderer?.(item);
    const infoText = typeof info === "string" || typeof info === "number" ? String(info) : "";

    if (exactMatch) {
      return text.toLowerCase() === query.toLowerCase() || (!!infoText && infoText.toLowerCase() === query.toLowerCase());
    }

    return startsWithAllWords(`${text} ${infoText}`, query);
  }

  function itemRenderer(item: T, itemProps: Core.ItemRendererProps<HTMLLIElement>) {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }

    let option = optionRenderer(item, {
      query: itemProps.query,
      active: itemProps.modifiers.active,
    });

    if (typeof option === "string") {
      option = <ListOption text={option} />;
    }

    return extendListOption(item, option, infoRenderer, itemProps);
  }

  function textRenderer(item: T): string {
    const option = optionRenderer(item, { query: "", active: false });
    return typeof option === "string" ? option : option.props.text;
  }

  function extendListOption(
    item: T,
    option: React.ReactElement<ListOptionProps & RefAttributes<HTMLLIElement>>,
    infoRenderer: ItemInfoRenderer<T> | undefined,
    itemProps: Core.ItemRendererProps<HTMLLIElement>
  ) {
    let children = option.props.children;
    let info = option.props.info ?? (infoRenderer ? infoRenderer(item) : undefined);

    if (typeof info === "number") {
      info = String(info);
    }

    if (itemProps.query && option.props.text) {
      children = highlightText(option.props.text, itemProps.query);
    }

    if (itemProps.query && typeof info === "string") {
      info = highlightText(info, itemProps.query);
    }

    const listOptionProps: Partial<ListOptionProps & RefAttributes<HTMLLIElement>> = {
      ref: itemProps.ref,
      key: itemProps.index,
      active: option.props.active ?? itemProps.modifiers.active,
      disabled: option.props.disabled ?? itemProps.modifiers.disabled,
      ...listOptionPropFactory?.(item),
      info,
      onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
        itemProps.handleClick(event);
        option.props.onClick?.(event);
      },
      onFocus: (event: React.FocusEvent<HTMLAnchorElement>) => {
        itemProps.handleFocus?.();
        option.props.onFocus?.(event);
      },
    };

    return cloneElement(option, listOptionProps, children);
  }
}
