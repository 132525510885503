export interface SignalController<T> {
  signal(value: T): void;
  clear(): void;
}

export class Signal<T> {
  private readonly signaled = new Map<number, T>();

  public get signals(): T[] {
    return [...this.signaled.values()];
  }

  public getController(): SignalController<T> {
    const key = Math.random();
    return {
      signal: (value: T): void => {
        this.signaled.set(key, value);
      },
      clear: (): void => {
        this.signaled.delete(key);
      },
    };
  }
}
