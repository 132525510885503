import {
  type CheckResult,
  type Checkable,
  type InflectionsResponse,
  type LanguageCheckOptions,
  type LanguageChecker,
  type LanguageCheckerFactoryOptions,
  MetisClient,
  type MetisClientConfig,
  type PredictResponse,
  type ProfileWord,
  type RequestHandler,
  type TranslateLanguage,
  type Zone
} from "@remhealth/metis";
import { sanitizeBaseUrl } from "@remhealth/ui";
import { AccessToken } from "@remhealth/host";

export interface GlobalLanguageServiceConfig extends MetisClientConfig {
  application: string;
  zone: Zone;
  accessToken: Readonly<AccessToken>;
  headers?: Record<string, string>;
}

export class GlobalLanguageService {
  protected readonly client: MetisClient;
  protected readonly config: GlobalLanguageServiceConfig;

  constructor(config: GlobalLanguageServiceConfig) {
    config.baseURL = sanitizeBaseUrl(config.baseURL);
    this.client = new MetisClient(config);
    this.config = config;
  }

  public createChecker(options: LanguageCheckerFactoryOptions): LanguageChecker {
    return this.client.createChecker({ ...this.config, ...options });
  }

  public async check(text: string, options: Checkable[], abort: AbortSignal): Promise<CheckResult> {
    const context: LanguageCheckOptions = {
      application: this.config.application,
      zone: this.config.zone,
      check: options,
    };

    return this.client.check(text, context, abort);
  }

  public async inflections(word: string, partOfSpeech: "NOUN" | "VERB" | "ADJ" | "ADV", abort: AbortSignal): Promise<InflectionsResponse> {
    return this.client.inflections(word, partOfSpeech, abort);
  }

  public async predict(text: string, maxGeneratedWords: number, abort?: AbortSignal): Promise<PredictResponse> {
    return this.client.predict(text, maxGeneratedWords, abort);
  }

  public async translateTextToEnglish(text: string, abort?: AbortSignal): Promise<string> {
    return this.client.translateTextToEnglish(text, abort);
  }

  public async translateHtmlToEnglish(html: string, abort?: AbortSignal): Promise<string> {
    return this.client.translateHtmlToEnglish(html, abort);
  }

  public async translateText(text: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string> {
    return this.client.translateText(text, sourceLanguage, targetLanguage, abort);
  }

  public async translateHtml(html: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string> {
    return this.client.translateHtml(html, sourceLanguage, targetLanguage, abort);
  }

  public async translateToEnglish(content: string, contentType: string, abort?: AbortSignal): Promise<string>;
  public async translateToEnglish(content: ArrayBuffer, contentType: string, abort?: AbortSignal): Promise<ArrayBuffer>;
  public async translateToEnglish(content: string | ArrayBuffer, contentType: string, abort?: AbortSignal): Promise<string | ArrayBuffer> {
    return this.client.translateToEnglish(content, contentType, abort);
  }

  public async translate(content: string, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string>;
  public async translate(content: ArrayBuffer, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<ArrayBuffer>;
  public async translate(content: string | ArrayBuffer, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string | ArrayBuffer> {
    return this.client.translate(content, contentType, sourceLanguage, targetLanguage, abort);
  }
}

export interface LanguageServiceConfig extends GlobalLanguageServiceConfig {
  practice: string;
  profile: string;
}

export class LanguageService extends GlobalLanguageService {
  protected readonly config: LanguageServiceConfig;

  constructor(config: LanguageServiceConfig) {
    config.baseURL = sanitizeBaseUrl(config.baseURL);

    super(config);

    this.config = config;
  }

  public async fetchProfileWords(start = 0, limit = 100, abort: AbortSignal): Promise<string[]> {
    return this.client.fetchProfileWords({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
    }, start, limit, abort);
  }

  public async addProfileWord(word: string, abort: AbortSignal): Promise<ProfileWord> {
    return this.client.addProfileWord({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
      word,
    }, abort);
  }

  public async removeProfileWord(word: string, abort: AbortSignal): Promise<void> {
    return this.client.removeProfileWord({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
      word,
    }, abort);
  }
}

export function globalLanguageService(config: GlobalLanguageServiceConfig): GlobalLanguageService {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;
      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);
      return { headers, ...rest };
    },
  };

  return new GlobalLanguageService({
    ...config,
    headers: config.headers,
    requestHandler,
  });
}

export function languageService(config: LanguageServiceConfig): LanguageService {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;
      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);
      return { headers, ...rest };
    },
  };

  return new LanguageService({
    ...config,
    headers: config.headers,
    requestHandler,
  });
}
