import styled from "styled-components";
import { Switch, TagList } from "@remhealth/ui";

export const ExpansionsList = styled(TagList)`
  max-height: 150px;
` as typeof TagList;

export const ExpandOnce = styled(Switch)`
  margin-top: 20px;
`;
