import React, { type PropsWithChildren, type Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { type FieldValidation, useCallbackRef, useDebouncer, useStateRef, useUpdateEffect } from "@remhealth/ui";
import type { Range } from "~/types";
import { QuillEditorContext } from "~/hooks/useQuillEditor";
import { ComposeContext, FocusedContext } from "~/hooks/useCompose";
import { type ComposeController, useComposeController } from "~/hooks/useComposeController";
import { useComposeRegistration } from "~/hooks/useComposeRegistry";
import { type ComposeField, useComposeField } from "~/hooks/useComposeField";
import { type ComposeEditor } from "./composeEditor";
import { type QuillChangeType, QuillEditor } from "./quillEditor";
import { Editor, type EditorProps } from "./editor";
import { type Registry, defaultRegistry } from "./registry";

export interface ComposeProps extends Omit<EditorProps, "defaultValue" | "onChange"> {
  controller?: ComposeController;
  initialValue?: string;
  field?: ComposeField;
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  registry?: Registry;
  editorRef?: Ref<HTMLDivElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (editor: ComposeEditor) => void;
  onUnbufferedChange?: (editor: ComposeEditor) => void;
  onSelectionChange?: (range: Range | null) => void;
}

export interface ComposeSelectionChangeEventDetails {
  editor: ComposeEditor;
  range: Range | null;
}

export interface ComposeTextChangeEventDetails {
  editor: ComposeEditor;
}

export const Compose = forwardRef((props: PropsWithChildren<ComposeProps>, ref: Ref<ComposeEditor>) => {
  const {
    field,
    children,
    initialValue: controlledInitialValue,
    name = field?.name,
    disabled = field?.disabled ?? false,
    readonly = field?.readOnly ?? false,
    intent = field?.error ? "danger" : undefined,
    registry = defaultRegistry,
    editorRef,
    onFocus: onFocusCallback,
    onBlur: onBlurCallback,
    controller: controlledController,
    onChange: onChangeCallback,
    onSelectionChange: onSelectionChangeCallback,
    onUnbufferedChange: onUnbufferedChangeCallback,
    ...editorProps
  } = props;

  const initialValue = useRef<string>(controlledInitialValue ?? field?.value?.value ?? "");

  const uncontrolledController = useComposeController({ initialValue: initialValue.current, readonly, disabled });
  const controller = controlledController ?? uncontrolledController;

  const { onChange: onFieldChange } = useComposeField(field, controller);

  const changeDebouncer = useDebouncer(250);

  const onFocus = useCallbackRef(onFocusCallback);
  const onBlur = useCallbackRef(onBlurCallback);
  const onChange = useCallbackRef(onChangeCallback);
  const onUnbufferedChange = useCallbackRef(onUnbufferedChangeCallback);
  const onSelectionChange = useCallbackRef(onSelectionChangeCallback);
  const onTextChange = useCallbackRef(handleTextChange);

  const editor = useMemo(createQuillEditor, [registry]);
  const [version, setVersion] = useState(0);
  const context = useMemo<QuillEditorContext>(() => ({ editor, version }), [editor, version]);
  const isFocused = useStateRef(false);

  useImperativeHandle(ref, () => editor, [editor]);

  useComposeRegistration(editor);

  useEffect(() => {
    if (controller.disabled || uncontrolledController.disabled) {
      editor.makeDisabled();
    } else if (controller.readonly || uncontrolledController.readonly) {
      editor.makeReadonly();
    } else {
      editor.makeEnabled();
    }
  }, [editor, controller.readonly, controller.disabled, uncontrolledController.readonly, uncontrolledController.disabled]);

  useUpdateEffect(() => {
    editor.field = field ?? { name, error: false, valid: true, errorText: undefined };
  }, [field?.name, field?.error, field?.errorText, field?.valid, name]);

  useUpdateEffect(() => {
    editor.reset(controller.initialValue);
  }, [controller.version]);

  return (
    <ComposeContext.Provider value={context}>
      <QuillEditorContext.Provider value={context}>
        <FocusedContext.Provider value={isFocused.current}>
          <Editor ref={editorRef} {...editorProps} intent={intent}>
            <React.Suspense fallback={null}>
              {children}
            </React.Suspense>
          </Editor>
        </FocusedContext.Provider>
      </QuillEditorContext.Provider>
    </ComposeContext.Provider>
  );

  function createQuillEditor(): QuillEditor {
    const fieldValidation: FieldValidation = field ?? { name, error: false, valid: true, errorText: undefined };
    return new QuillEditor(controller.initialValue, fieldValidation, registry.clone(), handleQuillEditorChange);
  }

  function handleQuillEditorChange(type: QuillChangeType, editor: QuillEditor) {
    if (isFocused.current && !editor.focused) {
      onBlur();
    } else if (!isFocused.current && editor.focused) {
      onFocus();
    }

    isFocused.set(editor.focused);

    setVersion(prev => prev + 1);

    switch (type) {
      case "selection": {
        dispatchEvent<ComposeSelectionChangeEventDetails>("compose-selection-changed", { editor, range: editor.range });
        onSelectionChange?.(editor.range);
        break;
      }

      case "text": {
        dispatchEvent<ComposeTextChangeEventDetails>("compose-text-changed", { editor });
        onUnbufferedChange?.(editor);
        changeDebouncer.delay(onTextChange);
        break;
      }
    }
  }

  function handleTextChange() {
    onFieldChange(editor);
    onChange?.(editor);
  }

  function dispatchEvent<T>(event: string, detail: T): void {
    editor.quill.root.dispatchEvent(new CustomEvent<T>(event, {
      detail,
      bubbles: true,
      cancelable: false,
    }));
  }
});
