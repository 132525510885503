import styled from "styled-components";

export const Container = styled.main`
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > .bp5-callout {
    flex: 0 0 auto;
    margin-bottom: 15px;
  }
`;

export const PageTitle = styled.h3`
  margin-top: 0;
`;

export const Description = styled.div`
  flex: 1;

  h3 {
    margin: 12px 0;
    font-weight: 400;
  }

  p {
    color: ${props => props.theme.font.muted};
    font-style: italic;

    > strong,
    > b {
      color: ${props => props.theme.font.default};
    }
  }
`;
