import { Placeholders, type PlaceholdersProps } from "@remhealth/compose";
import { placeholders } from "@remhealth/core";

export type BellsPlaceholdersProps = Omit<PlaceholdersProps, "placeholders" | "onSelect">;

const allowedPlaceholders = placeholders.filter(p => p.name !== "singleChoice" && p.name !== "multiChoice");

const BellsPlaceholders = (props: BellsPlaceholdersProps) => {
  return (
    <Placeholders {...props} placeholders={allowedPlaceholders} />
  );
};

export default BellsPlaceholders;
