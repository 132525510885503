import React, { useContext } from "react";
import { AthenaQueryClient } from "@remhealth/athena-query";

export interface AnalyticsContext {
  readonly analytics: AthenaQueryClient;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  get analytics(): AthenaQueryClient {
    throw new Error("AnalyticsContext is not initialized");
  },
});

export const useAnalytics = () => useContext(AnalyticsContext).analytics;
