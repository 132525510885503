import styled from "styled-components";
import { FormGroup } from "@remhealth/ui";

export const Grid = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  @media (max-width: 1000px) {
    .collapsable {
      display: none;
    }
  }
`;

export const SearchInputFormGroup = styled(FormGroup)`
  margin: 0 0 15px 0;
`;
