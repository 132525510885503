import styled, { css } from "styled-components";
import { Classes } from "@remhealth/ui";
import { TabbedDialog } from "@remhealth/core";

export interface DialogWindowProps {
  $small?: boolean;
}

export const DialogWindow = styled(TabbedDialog)<DialogWindowProps>`
  width: 550px;
  min-height: ${props => props.$small ? "250px" : "600px"};
  max-height: calc(98vh / var(--zoom));

  .bp5-dialog-body {
    ${props => props.$small && css`
      padding-left: 30px;
      padding-right: 30px;
      overflow-y: auto;
    `};

    > .bp5-tabs > .bp5-tab-panel {
      overflow-y: auto;
      padding-left: 0;
      padding-right: 0;

      > * {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    .bp5-form-group + .bp5-form-group {
      margin-top: 15px;
    }

    .bp5-form-content > .bp5-tag-input + .bp5-input-group,
    .bp5-form-content > .bp5-input-group + .bp5-tag-input,
    .bp5-form-content > .tooltip + .bp5-tag-input {
      margin-top: 3px;
    }

    .bp5-multi-select {
      max-height: 180px;
    }
  }

  .${Classes.DIALOG_FOOTER_ACTIONS} > .left {
    margin-right: auto;
  }
`;
