import { useState } from "react";
import { SnippetSortField, SnippetTemplate, SortDirection, SortField } from "@remhealth/apollo";
import {
  Alert,
  GridFeed,
  IconButton,
  InputGroup,
  NonIdealState,
  SortButton,
  useDebouncedState,
  useUpdateEffect
} from "@remhealth/ui";
import { ShowMoreText, useFeed } from "@remhealth/core";
import { useRegistry } from "~/contexts";
import { Text } from "~/text";
import { SnippetTemplateDialog } from "./snippetTemplateDialog";
import {
  ActionCell,
  ActionHeader,
  AddSnippet,
  Container,
  Grid,
  Header,
  NameHeader,
  PageTitle,
  RowItem,
  SearchInputFormGroup,
  SnippetAction,
  SnippetTextCell
} from "./manageSnippetTemplates.styles";

export const ManageSnippetTemplates = () => {
  const registry = useRegistry();

  const [searchText, setSearchText] = useDebouncedState<string>("", 300);
  const [selectedTemplate, setSelectedTemplate] = useState<SnippetTemplate>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [sortPredicate, setSortPredicate] = useState<SortField<SnippetSortField>>({
    field: SnippetSortField.Name,
    direction: SortDirection.Ascending,
  });

  const feed = useFeed(registry.snippetTemplates, {
    filters: [
      { name: { startsWithAllWords: searchText } },
      { text: { startsWithAllWords: searchText } },
    ],
    orderBy: sortPredicate,
  });

  // Reset if feed query changes
  useUpdateEffect(() => {
    feed.reset();
  }, [searchText, sortPredicate]);

  return (
    <Container>
      <PageTitle>{Text.Snippets}</PageTitle>
      <Header>
        <SearchInputFormGroup>
          <InputGroup
            clearable
            fill
            large
            soft
            defaultValue={searchText}
            placeholder={Text.SearchDropIns}
            type="search"
            onChange={setSearchText}
          />
        </SearchInputFormGroup>
        <AddSnippet large icon="plus" id="add-snippet" intent="primary" label={Text.AddNew} onClick={handleAddNew} />
      </Header>
      <Grid>
        <GridFeed<SnippetTemplate>
          allowWrap
          compact
          interactive
          stickyHeader
          feed={feed}
          headerRenderer={headerRenderer}
          noResults={renderNoRecords}
          pageKey="snippetTemplate"
          rowRenderer={rowRenderer}
        />
      </Grid>
      <SnippetTemplateDialog
        isOpen={showDialog}
        snippetTemplate={selectedTemplate}
        onClose={handleEditClose}
        onSave={handleEditSave}
      />
      <Alert
        canEscapeKeyCancel
        cancelButtonText={Text.No}
        confirmButtonText={Text.ConfirmDelete}
        intent="primary"
        isOpen={showDeleteConfirm}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <p>{Text.DeleteConfirmation(Text.Snippet.toLowerCase())}</p>
      </Alert>
    </Container>
  );

  function renderNoRecords() {
    return (
      <tr>
        <td colSpan={3}>
          <NonIdealState
            description={Text.NoResults(Text.Snippets)}
            title={Text.NoResultTitle(Text.Snippets)}
          />
        </td>
      </tr>
    );
  }

  function headerRenderer() {
    const { direction, field } = sortPredicate;
    return (
      <tr>
        <NameHeader>
          <SortButton
            label={Text.Snippet}
            sort={field === SnippetSortField.Name ? direction : "Unspecified"}
            onClick={handleSortByName}
          />
        </NameHeader>
        <th>{Text.FullText}</th>
        <ActionHeader />
      </tr>
    );
  }

  function rowRenderer(snippetTemplate: SnippetTemplate) {
    const fullText = snippetTemplate.text.plainText?.replace(/^((\d+\.\s)|(\*\s))/gm, "");
    return (
      <RowItem key={snippetTemplate.id} onClick={() => handleEdit(snippetTemplate)}>
        <td>{snippetTemplate.name}</td>
        <td>
          <SnippetTextCell>
            <ShowMoreText>
              {fullText}
            </ShowMoreText>
          </SnippetTextCell>
        </td>
        <ActionCell>
          <SnippetAction className="snippet-action">
            <IconButton
              elevated
              tooltip
              aria-label={Text.Delete}
              icon="trash"
              onClick={e => handleDelete(e, snippetTemplate)}
            />
          </SnippetAction>
        </ActionCell>
      </RowItem>
    );
  }

  function handleAddNew() {
    setShowDialog(true);
    setSelectedTemplate(undefined);
  }

  function handleEditClose() {
    setSelectedTemplate(undefined);
    setShowDialog(false);
  }

  function handleEdit(snippetTemplate: SnippetTemplate) {
    setSelectedTemplate(snippetTemplate);
    setShowDialog(true);
  }

  function handleDelete(e: React.MouseEvent, snippetTemplate: SnippetTemplate) {
    e.stopPropagation();
    setSelectedTemplate(snippetTemplate);
    setShowDeleteConfirm(true);
  }

  function handleDeleteCancel() {
    setSelectedTemplate(undefined);
    setShowDeleteConfirm(false);
  }

  async function handleDeleteConfirm() {
    if (selectedTemplate) {
      await registry.snippetTemplates.deleteById(selectedTemplate.id);
      feed.reset();
    }
    setShowDeleteConfirm(false);
    setSelectedTemplate(undefined);
  }

  function handleSortByName() {
    handleSortBy(SnippetSortField.Name);
  }

  function handleSortBy(field: SnippetSortField) {
    let direction: SortDirection = SortDirection.Ascending;
    if (sortPredicate.field === field) {
      direction = getReverseSortDirection(sortPredicate.direction);
    }
    setSortPredicate({ field, direction });
  }

  function getReverseSortDirection(sortDirection?: SortDirection) {
    return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
  }

  function handleEditSave() {
    feed.reset();
    setSelectedTemplate(undefined);
    setShowDialog(false);
  }
};
