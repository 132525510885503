import { KeyboardEvent, useState } from "react";
import { isEqual, uniq } from "lodash-es";
import { Button, FieldValidation, FormGroup, InputGroup, Tooltip, isEnterKeyPress, useAbort } from "@remhealth/ui";
import { NounAlternative } from "@remhealth/apollo";
import { DragHandleVertical } from "@remhealth/icons";
import { useGlobalLanguageService } from "~/services";
import { useErrorHandler } from "~/app";
import { RuleForm } from "../schema";
import { LemmaInput } from "./lemmaInput";
import { SuggestionList } from "./nounRulePanel.styles";

export interface NounRulePanelProps {
  form: RuleForm<"Noun">;
}

export function NounRulePanel(props: NounRulePanelProps) {
  const { form } = props;

  const lang = useGlobalLanguageService();
  const abort = useAbort();
  const handleError = useErrorHandler();
  const [addingSuggestion, setAddingSuggestion] = useState(false);
  const [addSuggestionError, setAddSuggestionError] = useState("");
  const [newSuggestion, setNewSuggestion] = useState("");

  const addSuggestion = (
    <Tooltip isOpen content={addSuggestionError} disabled={!addSuggestionError} intent="danger" openOnTargetFocus={false} placement="top-start">
      <InputGroup
        maxLength={100}
        placeholder="Enter new replacement..."
        readOnly={addingSuggestion}
        rightElement={<Button square disabled={!newSuggestion.trim()} label="Add" loading={addingSuggestion} onClick={handleAddSuggestion} />}
        value={newSuggestion}
        onBlur={handleSuggestionBlur}
        onChange={setNewSuggestion}
        onKeyDown={handleSuggestionKeyDown}
      />
    </Tooltip>
  );

  const alternatives = form.alternatives.value.map(alt => {
    const nouns = [alt.singular, ...alt.plural];
    return uniq(nouns).sort().join(", ");
  });

  const alternativeFields: FieldValidation[] = [
    form.alternatives,
    ...form.alternatives.fields.map(f => f.fields.singular),
    ...form.alternatives.fields.flatMap(f => f.fields.plural.fields),
  ];

  return (
    <>
      <LemmaInput field={form.noun} helperText="Write a noun to restrict" label="Noun" maxLength={100} placeholder="Enter a noun..." pos="NOUN" />
      <FormGroup field={form.instruction} helperText="Give some rationale or advice to help explain the recommendation" label="Instructions">
        <InputGroup field={form.instruction} maxLength={300} placeholder="Write instruction..." />
      </FormGroup>
      <FormGroup field={alternativeFields} helperText="Suggested replacements in preferred order" label="Alternatives to suggest">
        {addSuggestion}
        {alternatives.length > 0 && (
          <SuggestionList
            removable
            sortable={alternatives.length > 1}
            tagProps={{ icon: alternatives.length > 1 ? <DragHandleVertical /> : undefined }}
            values={alternatives}
            onRemove={handleAlternativeRemove}
            onSort={handleAlternativeSort}
          />
        )}
      </FormGroup>
    </>
  );

  function handleAlternativeSort(source: number, destination: number) {
    const alternatives = [...form.alternatives.value];
    const item = alternatives[source];
    alternatives.splice(source, 1);
    alternatives.splice(destination, 0, item);
    form.alternatives.onChange(alternatives);
    form.alternatives.onTouched();
  }

  function handleAlternativeRemove(_display: string, index: number) {
    const alternatives = [...form.alternatives.value];
    alternatives.splice(index, 1);
    form.alternatives.onChange(alternatives);
    form.alternatives.onTouched();
  }

  function handleSuggestionKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    setAddSuggestionError("");

    if (isEnterKeyPress(event)) {
      handleAddSuggestion();
    }
  }

  function handleSuggestionBlur() {
    setAddSuggestionError("");
  }

  async function handleAddSuggestion() {
    const noun = newSuggestion.trim();

    if (!noun) {
      setNewSuggestion("");
      return;
    }

    const alternative: NounAlternative = {
      singular: noun,
      plural: [],
    };

    // Only use inflection service if not an acronym and is a single word
    if (!noun.includes(" ") && noun !== noun.toUpperCase()) {
      setAddingSuggestion(true);

      try {
        const results = await lang.inflections(noun, "NOUN", abort.signal);

        alternative.singular = results.inflections.NNP?.[0] ?? results.inflections.NN?.[0] ?? alternative.singular;
        alternative.plural = [...results.inflections.NNS ?? [], ...results.inflections.NNPS ?? []];
      } catch (error) {
        handleError(error);
      } finally {
        setAddingSuggestion(false);
      }
    }

    if (alternative.plural.length === 0) {
      alternative.plural.push(alternative.singular);
    }

    // Avoid duplicates
    const alreadyExists = form.alternatives.value.some(alt => isEqual(alt, alternative));

    if (alreadyExists) {
      setAddSuggestionError("This alternative is already present in the list.");
      return;
    }

    setNewSuggestion("");
    setAddSuggestionError("");

    form.alternatives.onChange([...form.alternatives.value, alternative]);
    form.alternatives.onTouched();
  }
}
