import { type Ref, forwardRef, useImperativeHandle, useLayoutEffect, useRef } from "react";
import { type AutomatableProps, useAutomation, useCallbackRef, useDebouncer } from "~/hooks";
import type { FieldValidation } from "~/utils";

export interface AutomationInputHandle {
  setValue(value: string): void;
}

export interface AutomationInputProps extends AutomatableProps {
  field?: FieldValidation;
  name?: string;
  defaultValue: string;
  onChange: (value: string) => void;
}

// https://github.com/cypress-io/cypress/issues/647#issuecomment-335829482
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")!.set!;

/**
 * Hidden input used to allow a custom component to integrate with automation drivers.
 */
export const AutomationInput = forwardRef((props: AutomationInputProps, ref: Ref<AutomationInputHandle>) => {
  const {
    field,
    name = field?.name,
    defaultValue,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const value = useRef(defaultValue);

  const fireChangeDebouncer = useDebouncer(50);
  const dispatchChangeDebouncer = useDebouncer(0);

  const { id, label, errorId } = useAutomation(props);

  const onChange = useCallbackRef(props.onChange);
  const onNativeChange = useCallbackRef(handleNativeChange);
  const onNativeInput = useCallbackRef(handleNativeInput);

  useImperativeHandle(ref, () => ({
    setValue: handleSetValue,
  }), []);

  useLayoutEffect(() => {
    const input = inputRef.current;

    if (input) {
      // Initialize value natively
      input.value = defaultValue;
      nativeInputValueSetter.call(inputRef.current, defaultValue);

      // Listen to native event, because React misses changes made through automation events
      input.addEventListener("change", onNativeChange);
      input.addEventListener("input", onNativeInput);
    }

    return () => {
      if (input) {
        input.removeEventListener("change", onNativeChange);
        input.removeEventListener("input", onNativeInput);
      }
    };
  }, []);

  if (!id) {
    return <></>;
  }

  return (
    <input
      ref={inputRef}
      aria-hidden
      aria-errormessage={errorId}
      aria-invalid={field?.error ? true : undefined}
      aria-label={label}
      className="automation-only"
      data-errormessage={field?.error ? field.errorText : undefined}
      id={id}
      name={name}
      tabIndex={-1}
      type="text"
    />
  );

  function handleSetValue(value: string) {
    dispatchChangeDebouncer.delay(() => fireSetValue(value));
  }

  function fireSetValue(newValue: string) {
    const input = inputRef.current;
    if (input) {
      input.value = newValue;
      value.current = newValue;

      nativeInputValueSetter.call(input, newValue);

      input.dispatchEvent(new Event("change", { bubbles: true }));
      input.dispatchEvent(new InputEvent("input", { bubbles: true, inputType: "deleteContentBackward" }));
      input.dispatchEvent(new InputEvent("input", { bubbles: true, inputType: "deleteContentForward" }));

      input.dispatchEvent(new InputEvent("input", {
        bubbles: true,
        inputType: "insertText",
        data: newValue,
      }));
    }
  }

  function handleNativeChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      fireChange(event.target.value);
    }
  }

  function handleNativeInput(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      fireChange(event.target.value);
    }
  }

  function fireChange(newValue: string) {
    if (newValue !== value.current) {
      value.current = newValue;
      fireChangeDebouncer.delay(() => onChange(newValue));
    }
  }
});
