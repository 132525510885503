import { useState } from "react";
import { ulid } from "ulid";
import { SpeechRuleCategory, TextExpansionFilterSet, TextExpansionTemplate, TextExpansionTemplateFilterSet } from "@remhealth/apollo";
import { Alert, Button, Classes, InputGroup, SelectInput, useAbort } from "@remhealth/ui";
import { BeforeLeavePrompt, Form, FormActions, FormContent, Yup, speechRuleCategoryText } from "@remhealth/core";
import { Text } from "~/text";
import { useRegistry } from "~/contexts";
import { CategoryGroup, Expansion, ExpansionGroup, FieldGroup, Modal } from "./textExpansionTemplateDialog.styles";

const nameMaxLength = 10;

interface TextExanpsionTemplateDialogFields {
  name: string;
  expansion: string;
  category: SpeechRuleCategory | undefined;
}

const TextExpansionTemplateDialogSchema = Yup.object<TextExanpsionTemplateDialogFields>({
  name: Yup.string().trim().label(Text.Name).max(nameMaxLength).required().matches(/^([^\s#:@~]+)$/, '"@", "~", ":", "#" and whitespaces are not allowed.'),
  expansion: Yup.string().trim().label(Text.TextExpansion).max(300).required(),
  category: Yup.mixed<SpeechRuleCategory>().label(Text.ApplicableCategory),
});

export interface TextExpansionTemplateDialogProps {
  isOpen: boolean;
  textExpansionTemplate?: TextExpansionTemplate;
  onClose?: () => void;
  onSave?: (textExpansionTemplate: TextExpansionTemplate) => void;
}

export const TextExpansionTemplateDialog = (props: TextExpansionTemplateDialogProps) => {
  const { isOpen, textExpansionTemplate, onClose, onSave } = props;

  const registry = useRegistry();
  const abort = useAbort();

  const [confirmLeave, setConfirmLeave] = useState(false);
  const [conflictedCount, setConflictedCount] = useState(0);

  return (
    <Form<TextExanpsionTemplateDialogFields>
      enableReinitialize
      validateOnChange
      initialValues={{
        name: textExpansionTemplate?.name.trim() ?? "",
        expansion: textExpansionTemplate?.expansion.trim() ?? "",
        category:  textExpansionTemplate?.category,
      }}
      validationSchema={TextExpansionTemplateDialogSchema}
      onSubmit={handleFormSubmit}
    >
      {form => {
        return (
          <>
            <Modal isOpen={isOpen} title={Text.TextExpansion} onClose={() => handleClose(form, form.dirty)}>
              <div className={Classes.DIALOG_BODY}>
                <FieldGroup field={form.fields.name} label={Text.Name}>
                  <InputGroup autoFocus large autoComplete="off" field={form.fields.name} maxLength={nameMaxLength} />
                </FieldGroup>
                <ExpansionGroup field={form.fields.expansion} label={Text.TextExpansionExpansion}>
                  <Expansion fill large disabled={form.isSubmitting} field={form.fields.expansion} />
                </ExpansionGroup>
                <CategoryGroup label={Text.ApplicableCategory}>
                  <SelectInput<SpeechRuleCategory>
                    large
                    field={form.fields.category}
                    items={Object.values(SpeechRuleCategory)}
                    optionRenderer={item => speechRuleCategoryText[item]}
                  />
                </CategoryGroup>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    large
                    minimal
                    disabled={form.isSubmitting}
                    intent="primary"
                    label={Text.Cancel}
                    onClick={() => handleClose(form, form.dirty)}
                  />
                  <Button
                    large
                    disabled={form.isSubmitting}
                    intent="primary"
                    label={Text.Save(Text.TextExpansion)}
                    onClick={() => form.submitForm()}
                  />
                </div>
              </div>
            </Modal>
            <BeforeLeavePrompt
              isOpen={isOpen && confirmLeave}
              when={form.dirty && !form.isSubmitting}
              onCancelLeave={handleCancelLeave}
              onConfirmLeave={() => handleConfirmLeave(form)}
            />
            <Alert
              cancelButtonText="No, let me update the name"
              confirmButtonText="Yes, override"
              header={<>{Text.TextExpansion} Name Override</>}
              intent="warning"
              isOpen={conflictedCount > 0}
              onClose={() => handleConfirmWarningDialogClose()}
              onConfirm={() => handleConfirmWarningDialogConfirm(form)}
            >
              <p>Saving this will conflict with {conflictedCount} user{conflictedCount > 1 ? "s" : ""}</p>
              <p>Are you sure you want to continue?</p>
            </Alert>
          </>
        );
      }}
    </Form>
  );

  function handleClose(form: FormActions<TextExanpsionTemplateDialogFields>, dirty?: boolean) {
    if (dirty) {
      setConfirmLeave(true);
    } else {
      form.resetForm();
      onClose?.();
    }
  }

  function handleConfirmWarningDialogClose() {
    setConflictedCount(0);
  }

  async function handleConfirmWarningDialogConfirm(form: FormContent<TextExanpsionTemplateDialogFields>) {
    setConflictedCount(0);
    triggerSave(form.values);
    handleClose(form);
  }

  async function handleFormSubmit(values: TextExanpsionTemplateDialogFields, formActions: FormActions<TextExanpsionTemplateDialogFields>) {
    const name = values.name.trim();

    const nameFilter: TextExpansionTemplateFilterSet = {
      id: textExpansionTemplate?.id ? {
        notIn: [textExpansionTemplate.id],
      } : undefined,
      name: {
        equalTo: name,
      },
    };

    const duplicateCount = await fetchConflictedTextExpansionTemplateCount([{
      ...nameFilter,
    }]);

    if (duplicateCount > 0) {
      formActions.setFieldError("name", Text.DuplicateNameError(Text.TextExpansion));
      return;
    }
    await triggerSave(values);

    handleClose(formActions);
  }

  async function triggerSave(values: TextExanpsionTemplateDialogFields) {
    const name = values.name.trim();
    const expansion = values.expansion.trim();

    const data: TextExpansionTemplate = {
      id: textExpansionTemplate?.id ?? ulid(),
      resourceType: "TextExpansionTemplate",
      display: name,
      name,
      expansion,
      category: values.category ?? SpeechRuleCategory.AnyService,
    };

    await registry.textExpansionTemplates.update(data);
    onSave?.(data);
  }

  async function fetchConflictedTextExpansionTemplateCount(filters: TextExpansionFilterSet[]) {
    return await registry.textExpansionTemplates.queryCount({
      filters,
      abort: abort.signal,
    });
  }

  function handleConfirmLeave(form: FormActions<TextExanpsionTemplateDialogFields>) {
    setConfirmLeave(false);
    form.resetForm();
    onClose?.();
  }

  function handleCancelLeave() {
    setConfirmLeave(false);
  }
};
