import { useEffect, useState } from "react";
import { Error } from "@remhealth/icons";
import { Practitioner, securityRoleIds } from "@remhealth/apollo";
import { Button, Callout, Classes, Ellipsize, Icon, useAbort } from "@remhealth/ui";
import { useApollo } from "@remhealth/host";
import { useErrorHandler } from "~/app";
import { Avatar } from "~/avatars/avatar";
import { createPractitionerFilters, useStore } from "~/stores";
import { forEachAsync } from "../utils";
import { Header, Info, StyledCard, StyledDialog, SuperviseesContainer } from "./removeSupervisorRole.styles";

export interface RemoveSupervisorRoleProps {
  isOpen: boolean;
  user: Practitioner;
  onClose: () => void;
}

export function RemoveSupervisorRole(props: RemoveSupervisorRoleProps) {
  const { isOpen, onClose, user } = props;
  const apollo = useApollo();
  const abort = useAbort();
  const store = useStore();
  const handleError = useErrorHandler();
  const [supervisees, setSupervisees] = useState<Practitioner[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAwaitingRequest, setIsAwaitingRequest] = useState(false);

  useEffect(() => {
    loadSupervisees(user);
  }, [user.id]);

  const infoText = supervisees.length === 1
    ? `The following user is associated with ${user.name.display}. Do you want this user to be unassigned from the supervisor?`
    : `The following ${supervisees?.length} users are associated with ${user.name.display}. Do you want these users to be unassigned from the supervisor?`;

  const confirmText = supervisees.length === 1
    ? "Yes, unassign this user"
    : "Yes, unassign these users";

  const cancelText = supervisees.length === 1
    ? "Nevermind, I will reassign the user"
    : "Nevermind, I will reassign them";

  return (
    <StyledDialog isOpen={isOpen} title="Remove Supervisor privilege" onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <Header><Icon icon={<Error />} intent="warning" size={40} /></Header>
        <Info>{infoText}</Info>
        {renderSupervisees()}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button minimal disabled={isLoading} label={cancelText} loading={isAwaitingRequest} onClick={handleClose} />
          <Button disabled={isLoading} intent="warning" label={confirmText} loading={isAwaitingRequest} onClick={handleConfirm} />
        </div>
      </div>
    </StyledDialog>
  );

  async function loadSupervisees(user: Practitioner) {
    setIsLoading(true);
    try {
      const feed = apollo.practitioners.feed({
        filters: createPractitionerFilters({ activeTenure: true }).map(filter => ({
          ...filter,
          supervisor: { in: [user.id] },
        })),
      });
      setSupervisees(await feed.all({ abort: abort.signal }));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose() {
    onClose();
  }

  async function handleConfirm() {
    setIsAwaitingRequest(true);
    try {
      // Remove supervisor role
      user.profile.roles = user.profile.roles.filter(r => r.id !== securityRoleIds.Supervisor);
      await store.practitioners.upsertAsync(user);
      // Remove user's supervisor role for supervisees
      await forEachAsync(10, supervisees, async s => {
        s.supervisors = s.supervisors.filter(s => s.subject.id !== user.id && s.role === "Clinical");
        await store.practitioners.upsertAsync(s);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsAwaitingRequest(false);
      onClose();
    }
  }

  function renderSupervisees() {
    if (supervisees.length) {
      return (
        <SuperviseesContainer>
          {supervisees.map(s => <PractitionerTag key={s.id} user={s} />)}
        </SuperviseesContainer>
      );
    }
    return <Callout intent="warning">No supervisees.</Callout>;
  }
}

interface PractitionerTagProps {
  user: Practitioner;
}

function PractitionerTag(props: PractitionerTagProps) {
  const { user } = props;
  return (
    <StyledCard>
      <Avatar className="avatar" person={user} size={28} />
      <Ellipsize className="name">{user.name.display}</Ellipsize>
    </StyledCard>
  );
}
