import { Tab, Tabs } from "@remhealth/ui";
import { AcronymRulePanel } from "@remhealth/core";
import { RuleForm } from "../schema";
import { SpeechRuleCategoryInput } from "./speechRuleCategoryInput";

export interface AcronymRuleFormProps {
  form: RuleForm<"Acronym">;
}

export function AcronymRuleForm(props: AcronymRuleFormProps) {
  const { form } = props;

  const panel = (
    <>
      <AcronymRulePanel form={form.rule.fields} />
      <SpeechRuleCategoryInput field={form.category} />
    </>
  );

  return (
    <Tabs renderActiveTabPanelOnly id="rule" selectedTabId="details">
      <Tab id="details" panel={panel} title="Configuration" />
    </Tabs>
  );
}
