import { MastodonClient, RequestHandler } from "@remhealth/mastodon";
import { sanitizeBaseUrl } from "@remhealth/ui";
import { AccessToken } from "@remhealth/host";

export interface MastodonConfig {
  accessToken: Readonly<AccessToken>;
  networkId: string;
  headers?: Record<string, string>;
}

export function mastodonClient(baseUrl: string, config: MastodonConfig): MastodonClient {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;

      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);

      return {
        headers,
        ...rest,
      };
    },
  };

  return new MastodonClient({
    baseURL: sanitizeBaseUrl(baseUrl),
    networkId: config.networkId,
    headers: config.headers,
    requestHandler,
  });
}
