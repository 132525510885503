import { FocusEvent, useState } from "react";
import styled from "styled-components";
import {
  FormGroup,
  Icon,
  InputGroup,
  InputGroupProps,
  Spinner,
  useAbort
} from "@remhealth/ui";
import { useGlobalLanguageService } from "~/services";
import { lemmaRegex } from "../schema";

const LemmaText = styled.span`
  margin-left: 4px;
`;

export interface LemmaInputProps extends InputGroupProps {
  label: string;
  helperText?: string | JSX.Element;
  pos: "NOUN" | "VERB" | "ADJ" | "ADV";
}

export function LemmaInput(props: LemmaInputProps) {
  const { field, helperText, label, pos, onBlur, onChange, ...inputProps } = props;

  const lang = useGlobalLanguageService();
  const abort = useAbort();
  const [lemmation, setLemmation] = useState<"none" | "pending" | "running" | "done">("none");

  const lemmaRunning = lemmation === "running" ? <Spinner intent="primary" size={20} /> : undefined;
  const lemmaStatus = lemmation === "done"
    ? (
      <>
        <Icon icon="tick" intent="success" size={14} />
        <LemmaText>{label} has been updated to its <strong>basic form</strong>.</LemmaText>
      </>
    ) : undefined;

  return (
    <FormGroup field={field} helperText={lemmaStatus ?? helperText} label={label}>
      <InputGroup
        field={field}
        rightElement={lemmaRunning}
        onBlur={handleBlur}
        onChange={handleChange}
        {...inputProps}
      />
    </FormGroup>
  );

  function handleChange(value: string) {
    setLemmation("pending");
    onChange?.(value);
  }

  async function handleBlur(event: FocusEvent<HTMLInputElement>) {
    const word = event.currentTarget.value.trim();

    if (!word || !lemmaRegex().test(word)) {
      setLemmation("none");
      return;
    }

    if (lemmation === "pending") {
      setLemmation("running");

      try {
        const results = await lang.inflections(word, pos, abort.signal);
        field?.onChange(results.lemma);
        setLemmation(results.lemma === word ? "none" : "done");
      } catch (error) {
        setLemmation("none");
        throw error;
      }
    }

    onBlur?.(event);
  }
}
