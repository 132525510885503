import styled from "styled-components";
import { Popover } from "./popover";
import { Menu } from "./menu";

export const Container = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const Content = styled(Menu)`
  && {
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
  }
`;

export const FilterPopover = styled(Popover)`
  margin: -7px 0 -7px 5px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  border-top: 1px solid var(--light-gray1);

  .filter {
    margin-left: auto;
  }
`;
