import { type SetStateAction, useMemo } from "react";
import { useStateRef } from "./useStateRef";

export interface StateMap<K, T> {
  readonly current: Map<K, T>;
  readonly size: number;
  get(key: K): T | undefined;
  set(key: K, value: T): void;
  has(key: K): boolean;
  delete(key: K): void;
  clear(): void;
}

/**
 * Convenience useState made specifically for Map.
 */
export function useStateMap<K, T>(initialState: Map<K, T> | (() => Map<K, T>)): StateMap<K, T> {
  const state = useStateRef(() => isStateCallback(initialState) ? new Map<K, T>(initialState()) : new Map<K, T>(initialState));

  return useMemo(() => ({
    get current() {
      return state.current;
    },
    get size() {
      return state.current.size;
    },
    get,
    set,
    has,
    delete: deleteItem,
    clear,
  }), []);

  function set(key: K, value: T) {
    state.set(map => {
      map.set(key, value);
      return new Map<K, T>(map);
    });
  }

  function deleteItem(key: K) {
    state.set(map => {
      if (map.delete(key)) {
        return new Map<K, T>(map);
      }
      return map;
    });
  }

  function has(key: K) {
    return state.current.has(key);
  }

  function get(key: K) {
    return state.current.get(key);
  }

  function clear() {
    state.set(map => {
      if (map.size === 0) {
        return map;
      }
      return new Map<K, T>();
    });
  }
}

function isStateCallback<S>(a: SetStateAction<S>): a is ((prevState: S) => S) {
  return typeof a === "function";
}
