import { type SetStateAction, useMemo } from "react";
import { useStateRef } from "./useStateRef";

export interface StateSet<T> {
  readonly current: Set<T>;
  readonly size: number;
  add(item: T): void;
  has(item: T): boolean;
  delete(item: T): void;
  clear(): void;
}

/**
 * Convenience useState made specifically for Set.
 */
export function useStateSet<T>(initialState: T[] | (() => T[])): StateSet<T> {
  const state = useStateRef(() => isStateCallback(initialState) ? new Set<T>(initialState()) : new Set<T>(initialState));

  return useMemo(() => ({
    get current() {
      return state.current;
    },
    get size() {
      return state.current.size;
    },
    add,
    has,
    delete: deleteItem,
    clear,
  }), []);

  function add(item: T) {
    state.set(items => {
      if (items.has(item)) {
        return items;
      }
      items.add(item);
      return new Set<T>(items);
    });
  }

  function deleteItem(item: T) {
    state.set(items => {
      if (items.delete(item)) {
        return new Set<T>(items);
      }
      return items;
    });
  }

  function has(item: T) {
    return state.current.has(item);
  }

  function clear() {
    state.set(items => {
      if (items.size === 0) {
        return items;
      }
      return new Set<T>();
    });
  }
}

function isStateCallback<S>(a: SetStateAction<S>): a is ((prevState: S) => S) {
  return typeof a === "function";
}
