import styled from "styled-components";
import { Dialog, FormGroup, InputGroup } from "@remhealth/ui";

export const Modal = styled(Dialog)`
  width: 70% !important;
  min-height: 400px;
  user-select: auto;
`;

export const FieldGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;

export const ExpansionGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;

export const Expansion = styled(InputGroup)`
  resize: none;
  text-align: justify;
`;

export const CategoryGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;
