import type Quill from "quill";
import type QuillSelection from "quill/core/selection";

declare global {
  interface ShadowRoot {
    getSelection?: (() => Selection | null);
  }

  interface Selection {
    getComposedRanges?: ((rootNode: ShadowRoot) => StaticRange[]);
  }
}

const hasShadowRootSelection = !!document.createElement("div").attachShadow({ mode: "open" }).getSelection;

// Each browser engine has a different implementation for retrieving the Range
const getNativeRange = (rootNode: ShadowRoot) => {
  try {
    if (hasShadowRootSelection) {
      // In Chromium, the shadow root has a getSelection function which returns the range
      return rootNode.getSelection!()?.getRangeAt(0) ?? null;
    }

    const selection = window.getSelection();
    if (selection?.getComposedRanges) {
      // Webkit range retrieval is done with getComposedRanges (see: https://bugs.webkit.org/show_bug.cgi?id=163921)
      return selection.getComposedRanges(rootNode)[0] ?? null;
    }
    // Gecko implements the range API properly in Native Shadow: https://developer.mozilla.org/en-US/docs/Web/API/Selection/getRangeAt
    return selection?.getRangeAt(0) ?? null;
  } catch {
    return null;
  }
};

export function enableShadowDomSupport(quill: Quill) {
  /**
   * Original implementation uses document.active element which does not work in Native Shadow.
   * Replace document.activeElement with shadowRoot.activeElement
   **/
  quill.selection.hasFocus = function() {
    const rootNode = quill.root.getRootNode() as ShadowRoot;
    return rootNode.activeElement === quill.root;
  };

  /**
   * Original implementation uses document.getSelection which does not work in Native Shadow.
   * Replace document.getSelection with shadow dom equivalent (different for each browser)
   **/
  quill.selection.getNativeRange = function() {
    const rootNode = quill.root.getRootNode() as ShadowRoot;
    const nativeRange = getNativeRange(rootNode);
    return nativeRange ? quill.selection.normalizeNative(nativeRange) : null;
  };

  /**
   * Original implementation relies on Selection.addRange to programatically set the range, which does not work
   * in Webkit with Native Shadow. Selection.addRange works fine in Chromium and Gecko.
   **/
  quill.selection.setNativeRange = function(this: QuillSelection, startNode, startOffset, endNode = startNode, endOffset = startOffset, force = false) {
    if (startNode != null && (this.root.parentNode == null || startNode.parentNode == null || endNode?.parentNode == null)) {
      return;
    }
    const selection = document.getSelection();
    if (selection == null) {
      return;
    }
    if (startNode != null) {
      if (!this.hasFocus()) {
        this.root.focus({ preventScroll: true });
      }
      const { native } = this.getNativeRange() || {};
      if (native == null || force || startNode !== native.startContainer || startOffset !== native.startOffset || endNode !== native.endContainer || endOffset !== native.endOffset) {
        if (startNode instanceof Element && startNode.tagName === "BR") {
          startOffset = Array.from(startNode.parentNode?.childNodes ?? []).indexOf(startNode);
          startNode = startNode.parentNode;
        }
        if (endNode instanceof Element && endNode.tagName === "BR") {
          endOffset = Array.from(endNode.parentNode?.childNodes ?? []).indexOf(endNode);
          endNode = endNode.parentNode;
        }
        selection.setBaseAndExtent(startNode!, startOffset!, endNode!, endOffset!);
      }
    } else {
      selection.removeAllRanges();
      quill.selection.root.blur();
    }
  };

  const weakRef = new WeakRef(quill);

  document.addEventListener("selectionchange", handleSelectionChange);

  /**
   * Subscribe to selection change separately, because emitter in Quill doesn't catch this event in Shadow DOM
   **/
  function handleSelectionChange() {
    const quill = weakRef.deref();

    // If this Quill instance gets garbage collected, clean up this handler
    if (!quill) {
      document.removeEventListener("selectionchange", handleSelectionChange);
    } else {
      quill.selection.update();
    }
  }
}
