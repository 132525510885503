import { useEffect, useState } from "react";
import { sortBy, uniqBy } from "lodash-es";
import { Button, Classes, InputGroup, ListOption, Menu, SortButton, Switch, Tooltip, startsWithAllWords, useAbort, useDebouncedState, useUpdateEffect } from "@remhealth/ui";
import { Practice, SortField } from "@remhealth/apollo";
import { useRegistry } from "~/contexts";
import { GridContainer, Header, SwitchWrapper } from "./practicePanel.styles";

export interface PracticesPanelProps {
  singleSelect?: boolean;
  selectedPractices: Practice[];
  onSelectionChanged: (selectedPractices: Practice[]) => void;
  practiceRenderer?: (practice: Practice) => JSX.Element | string;
  disabledReason?: (practice: Practice) => JSX.Element | string | null;
}

export function PracticesPanel(props: PracticesPanelProps) {
  const {
    singleSelect = false,
    practiceRenderer = renderPractice,
    disabledReason,
    selectedPractices,
    onSelectionChanged,
  } = props;

  const abort = useAbort();
  const registry = useRegistry();
  const [bellsOnly, setBellsOnly] = useState(true);
  const [practices, setPractices] = useState<Practice[]>([]);
  const [searchText, setSearchText] = useDebouncedState("", 50);
  const [sort, setSort] = useState<SortField<"name" | "networkId">>({ field: "networkId", direction: "Ascending" });

  const visiblePractices = filterPractices(practices, searchText);
  const selectablePractices = visiblePractices.filter(p => !disabledReason?.(p));
  const visibleIds = new Set<string>(selectedPractices.map(p => p.id));
  const isAllSelected = selectablePractices.map(p => p.id).every(id => visibleIds.has(id));

  useEffect(() => {
    abort.reset();
    loadPractices();
  }, [bellsOnly]);

  useUpdateEffect(() => {
    setPractices(practices => sortPractices(practices));
  }, [sort]);

  return (
    <div className={Classes.DIALOG_BODY}>
      <SwitchWrapper>
        <Switch checked={bellsOnly} label="Bells Only" onChange={handleBellsOnlyChange} />
        {selectablePractices.length > 0 && !singleSelect && (
          isAllSelected
            ? <Button icon="cross" label="Unselect All" onClick={handleUnselectAll} />
            : <Button icon="tick" label="Select All" onClick={handleSelectAll} />
        )}
      </SwitchWrapper>
      <InputGroup fill soft placeholder="Search practices..." type="search" onChange={setSearchText} />
      <GridContainer>
        <Header $singleSelect={singleSelect}>
          <SortButton
            label="Name"
            sort={sort.field === "name" ? sort.direction : "Unspecified"}
            onClick={handleSortByName}
          />
          <SortButton
            label="Network ID"
            sort={sort.field === "networkId" ? sort.direction : "Unspecified"}
            onClick={handleSortByNetworkId}
          />
        </Header>
        <Menu>
          {visiblePractices.map(practice => {
            const content = practiceRenderer(practice);
            const disabledMsg = disabledReason?.(practice);
            return (
              <ListOption
                key={practice.id}
                disabled={!!disabledMsg}
                info={practice.networkId}
                selected={singleSelect ? undefined : selectedPractices.some(p => p.id === practice.id)}
                text={practice.name ?? practice.networkId}
                onClick={() => togglePractice(practice)}
              >
                <Tooltip content={disabledMsg ?? ""} disabled={!disabledMsg}>{content}</Tooltip>
              </ListOption>
            );
          })}
        </Menu>
      </GridContainer>
    </div>
  );

  function renderPractice(practice: Practice) {
    return practice.name ?? practice.networkId;
  }

  async function loadPractices() {
    const feed = registry.practices.feed({
      filters: [{
        features: !bellsOnly ? undefined : {
          matches: "Bells",
          presence: "MustBePresent",
        },
      }],
    });

    const practices = await feed.all({ abort: abort.signal });
    setPractices(sortPractices(practices));
  }

  function handleSelectAll() {
    onSelectionChanged(uniqBy([...selectedPractices, ...selectablePractices], p => p.id));
  }

  function handleUnselectAll() {
    onSelectionChanged([]);
  }

  function sortPractices(practices: Practice[]) {
    practices = sortBy(practices, p => sort.field === "name" ? p.name : p.networkId);

    if (sort.direction === "Descending") {
      practices.reverse();
    }

    return practices;
  }

  function filterPractices(practices: Practice[], query: string) {
    return practices.filter(p => startsWithAllWords(p.name, query) || p.networkId.toLowerCase().includes(query.toLowerCase()));
  }

  function handleSortByName() {
    handleSortBy("name");
  }

  function handleSortByNetworkId() {
    handleSortBy("networkId");
  }

  function handleSortBy(field: "name" | "networkId") {
    setSort(prev => ({
      field,
      direction: prev.field === field && prev.direction === "Ascending" ? "Descending" : "Ascending",
    }));
  }

  function handleBellsOnlyChange(event: React.FormEvent<HTMLInputElement>) {
    setBellsOnly(event.currentTarget.checked);
  }

  function togglePractice(practice: Practice) {
    if (selectedPractices.some(p => p.id === practice.id)) {
      onSelectionChanged(selectedPractices.filter(p => p.id !== practice.id));
    } else {
      onSelectionChanged([...selectedPractices, practice]);
    }
  }
}
