import { DateTime, Duration, Interval } from "luxon";
import { Approximate, ApproximateRange, DateRange, Instant, InstantRange, LocalDate } from "@remhealth/apollo";

// Returns a date rounded to the nearest interval of your choosing
export function getRoundedDate(date: Date): Date;
export function getRoundedDate(date: Date, value: number, unit: "minutes" | "seconds"): Date;
export function getRoundedDate(date: Date, value = 5, unit: "minutes" | "seconds" = "minutes"): Date {
  return getRoundedDateTime(DateTime.fromJSDate(date), value, unit).toJSDate();
}

export function getRoundedDateTime(date: DateTime): DateTime;
export function getRoundedDateTime(date: DateTime, value: number, unit: "minutes" | "seconds"): DateTime;
export function getRoundedDateTime(date: DateTime, value = 5, unit: "minutes" | "seconds" = "minutes"): DateTime {
  const duration = Duration.fromObject({ [unit]: value }).toMillis();
  const dateMillis = date.toMillis();
  const roundedMillis = Math.round(dateMillis / duration) * duration;
  return DateTime.fromMillis(roundedMillis);
}

export function getRoundedInstant(instant: Instant): Instant;
export function getRoundedInstant(instant: Instant, value: number, unit: "minutes" | "seconds"): Instant;
export function getRoundedInstant(instant: Instant, value = 5, unit: "minutes" | "seconds" = "minutes"): Instant {
  return Instant.fromDateTime(getRoundedDateTime(Instant.toDateTime(instant), value, unit));
}

export function getRoundedInstantNow(): Instant;
export function getRoundedInstantNow(value: number, unit: "minutes" | "seconds"): Instant;
export function getRoundedInstantNow(value = 5, unit: "minutes" | "seconds" = "minutes"): Instant {
  return getRoundedInstant(Instant.now(), value, unit);
}
/**
 * Returns true if the given instant occurred today.
 */
export function isInstantToday(instant: Instant): boolean {
  const today = DateTime.now().startOf("day").until(DateTime.now().endOf("day"));
  return today.contains(Instant.toDateTime(instant));
}

export function isDateRangeEffective(effective: DateRange): boolean;
export function isDateRangeEffective(effective: DateRange | null | undefined): boolean;
export function isDateRangeEffective(effective: DateRange, relativeTo: Date): boolean;
export function isDateRangeEffective(effective: DateRange | null | undefined, relativeTo: Date): boolean;
export function isDateRangeEffective(effective: DateRange | null | undefined, relativeTo?: Date): boolean;
export function isDateRangeEffective(effective: DateRange | null | undefined, relativeTo?: Date): boolean {
  if (!effective) {
    return true;
  }

  const start = effective.start;
  const end = effective.end;

  if (!start && !end) {
    return true;
  }

  const relativeDateTime = relativeTo ? DateTime.fromJSDate(relativeTo) : DateTime.now();

  if (start && end) {
    const range = Interval.fromDateTimes(LocalDate.toDateTime(start).startOf("day"), LocalDate.toDateTime(end).endOf("day"));
    return range.contains(relativeDateTime);
  } else if (start) {
    return relativeDateTime >= LocalDate.toDateTime(start).startOf("day");
  } else if (end) {
    return relativeDateTime <= LocalDate.toDateTime(end).endOf("day");
  }

  return true;
}

export function isInstantRangeEffective(effective: InstantRange): boolean;
export function isInstantRangeEffective(effective: InstantRange | null | undefined): boolean;
export function isInstantRangeEffective(effective: InstantRange, relativeTo: Date): boolean;
export function isInstantRangeEffective(effective: InstantRange | null | undefined, relativeTo: Date): boolean;
export function isInstantRangeEffective(effective: InstantRange | null | undefined, relativeTo?: Date): boolean;
export function isInstantRangeEffective(effective: InstantRange | null | undefined, relativeTo?: Date): boolean {
  if (!effective) {
    return true;
  }

  const start = effective.start;
  const end = effective.end;

  if (!start && !end) {
    return true;
  }

  const relativeDateTime = relativeTo ? DateTime.fromJSDate(relativeTo) : DateTime.now();

  if (start && end) {
    const range = Interval.fromDateTimes(Instant.toDateTime(start).startOf("day"), Instant.toDateTime(end).endOf("day"));
    return range.contains(relativeDateTime);
  } else if (start) {
    return relativeDateTime >= Instant.toDateTime(start).startOf("day");
  } else if (end) {
    return relativeDateTime <= Instant.toDateTime(end).endOf("day");
  }

  return true;
}

export function isApproximateRangeEffective(effective: ApproximateRange): boolean;
export function isApproximateRangeEffective(effective: ApproximateRange | null | undefined): boolean;
export function isApproximateRangeEffective(effective: ApproximateRange, relativeTo: Date): boolean;
export function isApproximateRangeEffective(effective: ApproximateRange | null | undefined, relativeTo: Date): boolean;
export function isApproximateRangeEffective(effective: ApproximateRange | null | undefined, relativeTo?: Date): boolean;
export function isApproximateRangeEffective(effective: ApproximateRange | null | undefined, relativeTo?: Date): boolean {
  if (!effective) {
    return true;
  }

  const start = effective.start;
  const end = effective.end;

  if (!start && !end) {
    return true;
  }

  const relativeDateTime = relativeTo ? DateTime.fromJSDate(relativeTo) : DateTime.now();

  if (start && end) {
    const range = Interval.fromDateTimes(Approximate.toDateTime(start).startOf("day"), Approximate.toDateTime(end).endOf("day"));
    return range.contains(relativeDateTime);
  } else if (start) {
    return relativeDateTime >= Approximate.toDateTime(start).startOf("day");
  } else if (end) {
    return relativeDateTime <= Approximate.toDateTime(end).endOf("day");
  }

  return true;
}

export function instantRangeOverlaps(left: InstantRange, right: InstantRange, exclusive = false): boolean {
  if (!left.start || !left.end || !right.start || !right.end) {
    return false;
  }

  const leftStart = Instant.toDateTime(left.start).startOf("second");
  const leftEnd = Instant.toDateTime(left.end).startOf("second");
  const rightStart = Instant.toDateTime(right.start).startOf("second");
  const rightEnd = Instant.toDateTime(right.end).startOf("second");

  if (exclusive) {
    return DateTime.max(leftStart, rightStart) < DateTime.min(leftEnd, rightEnd);
  }

  return DateTime.max(leftStart, rightStart) <= DateTime.min(leftEnd, rightEnd);
}

export function approximateRangeOverlaps(left: ApproximateRange, right: ApproximateRange, exclusive = false): boolean {
  if (!left.start || !left.end || !right.start || !right.end) {
    return false;
  }

  const leftStart = Approximate.toDateTime(left.start).startOf("second");
  const leftEnd = Approximate.toDateTime(left.end).startOf("second");
  const rightStart = Approximate.toDateTime(right.start).startOf("second");
  const rightEnd = Approximate.toDateTime(right.end).startOf("second");

  if (exclusive) {
    return DateTime.max(leftStart, rightStart) < DateTime.min(leftEnd, rightEnd);
  }

  return DateTime.max(leftStart, rightStart) <= DateTime.min(leftEnd, rightEnd);
}
