import Delta from "quill-delta";
import { ReadonlyTableBlot } from "~/blots/readonlyTableBlot";

function matchTable(node: HTMLTableRowElement, delta: Delta) {
  if (node.tagName === "TABLE") {
    return delta.reduce((newDelta, op) => {
      if (!op.insert) {
        return newDelta;
      }
      if (op.attributes && op.attributes["readonly-table"]) {
        return newDelta.push(op);
      }
      return newDelta.insert({ "readonly-table": ReadonlyTableBlot.value(node), ...op.attributes });
    }, new Delta());
  }
  return delta;
}

export default [
  ["table", matchTable],
];
