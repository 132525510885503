import { useContext, useEffect, useRef, useState } from "react";
import { NavigateOptions, To, UNSAFE_NavigationContext } from "react-router-dom";
import { Alert, AlertProps, useUpdateEffect } from "@remhealth/ui";
import { Text } from "~/text";

export interface BeforeLeavePromptProps extends Omit<AlertProps, "isOpen" | "onConfirm" | "onCancel"> {
  /**
   * If true, the prompt will watch for changes to the URL and display the prompt if it attempts to change.
   */
  when: boolean;

  /**
   * In controlled mode, this will force the prompt opened or closed.
   */
  isOpen?: boolean;

  /**
   * If true, the history will use replace instead of push for the new location once the user confirms.
   */
  replace?: boolean;

  /**
   * If true, the navigation will be block within Bells, default is true.
   */
  blockNavigation?: boolean;

  /**
   * Callback when user presses Confirm.  Can return false to cancel redirect.
   */
  onConfirmLeave?: () => undefined | boolean | void | Promise<undefined | boolean | void>;

  /**
   * Callback when user presses Cancel.
   */
  onCancelLeave?: () => void;
}

export const BeforeLeavePrompt = (props: BeforeLeavePromptProps) => {
  const { blockNavigation = true } = props;
  const [disabled, setDisabled] = useState(false);

  const [blockedPush, setBlockedPush] = useState<BlockedNavigate>();

  const navigate = useNavigateBlocker(props.when && blockNavigation && !disabled, handleBlock);

  const {
    when,
    isOpen = blockedPush !== undefined && !disabled,
    intent = "danger",
    confirmButtonText = Text.ConfirmDiscard,
    cancelButtonText = Text.No,
    canEscapeKeyCancel = false,
    canOutsideClickCancel = false,
    children,
    onConfirmLeave,
    onCancelLeave,
    ...alertProps
  } = props;

  useEffect(() => {
    if (when && !disabled) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [when, disabled]);

  useUpdateEffect(() => {
    setDisabled(false);
  }, [when]);

  return (
    <Alert
      {...alertProps}
      canEscapeKeyCancel={canEscapeKeyCancel}
      canOutsideClickCancel={canOutsideClickCancel}
      cancelButtonText={cancelButtonText}
      className="before-leave-prompt"
      confirmButtonText={confirmButtonText}
      header={Text.UnsavedChanges}
      intent={intent}
      isOpen={isOpen && !disabled}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      {children ?? <p>{Text.UnsavedChangesWarning}</p>}
    </Alert>
  );

  function handleBlock(blocked: BlockedNavigate) {
    setBlockedPush(blocked);
    return false;
  }

  async function handleConfirm() {
    if (!isOpen) {
      return;
    }

    const allow = await onConfirmLeave?.();

    if (allow === false) {
      return;
    }

    setDisabled(true);

    if (blockedPush) {
      navigate(...blockedPush);
    }
  }

  function handleCancel() {
    if (!isOpen) {
      return;
    }

    setBlockedPush(undefined);
    onCancelLeave?.();
  }

  function onBeforeUnload(event: BeforeUnloadEvent) {
    // Prompts the user before closing the page, see:
    // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
    event.preventDefault();
    event.returnValue = Text.UnsavedChangesWarning;
    return Text.UnsavedChangesWarning;
  }
};

type BlockedNavigate = Parameters<(to: To, options?: NavigateOptions) => void>;
function useNavigateBlocker(block: boolean, onBlock: (blockedPush: BlockedNavigate) => boolean) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  const originalPush = useRef(navigator.push);
  const originalReplace = useRef(navigator.replace);

  useEffect(() => {
    if (!block) {
      return;
    }

    originalPush.current = navigator.push;
    originalReplace.current = navigator.replace;

    navigator.push = (...args: Parameters<typeof navigator.push>) => {
      const to: To = args[0];
      const opts: NavigateOptions | undefined = args[2];

      const result = onBlock([to, opts]);
      if (result) {
        navigator.push(...args);
      }
    };

    navigator.replace = (...args: Parameters<typeof navigator.replace>) => {
      const to: To = args[0];
      const opts: NavigateOptions | undefined = args[2];

      const result = onBlock([to, opts]);
      if (result) {
        navigator.replace(...args);
      }
    };

    return () => {
      navigator.push = originalPush.current;
      navigator.replace = originalReplace.current;
    };
  }, [navigator, block]);

  return originalPush.current;
}
