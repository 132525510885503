import { useState } from "react";
import { Practitioner } from "@remhealth/apollo";
import { Alert, useAbort } from "@remhealth/ui";
import { usePreferences } from "@remhealth/host";
import { Text } from "~/text";

export interface ClearPinProps {
  user: Practitioner;
  isOpen: boolean;
  onClose: () => void;
}

export function ClearPin(props: ClearPinProps) {
  const { user, isOpen, onClose } = props;
  const abort = useAbort();
  const preferences = usePreferences();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Alert
      canEscapeKeyCancel
      cancelButtonText={Text.No}
      confirmButtonText={Text.ConfirmClearPin}
      intent="primary"
      isOpen={isOpen}
      loading={isLoading}
      onCancel={onClose}
      onConfirm={handleClearPin}
    >
      <p>{Text.ClearPinMessage(user.name.display)}</p>
    </Alert>
  );

  async function handleClearPin() {
    setIsLoading(true);
    await preferences.updatePersonal({ securityPin: undefined }, abort.signal);
    setIsLoading(false);
    onClose();
  }
}
