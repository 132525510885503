import { CareFabricConnection } from "@remhealth/apollo";
import { Form, FormContent, Yup } from "@remhealth/core";
import { Button, Classes, Dialog, FormGroup, InputGroup, SelectInput } from "@remhealth/ui";

import { useRegistry } from "~/contexts";
import { DialogBody } from "./common.styles";

interface CareFabricFormValues {
  sdkUrl: string;
  clientId: string;
  clientSecret: string;
}

const schema = Yup.object<CareFabricFormValues>({
  sdkUrl: Yup.string()
    .label("SDK Url")
    .required(),

  clientId: Yup.string()
    .label("Client ID")
    .required(),

  clientSecret: Yup.string()
    .label("Client Secret")
    .required(),
});

const sdkUrls = [
  "https://eventsdev.netsmartcloud.com/FabricSDK",
  "https://eventsqa.ntstplatform.com/FabricSDK",
  "https://events.ntstplatform.com/FabricSDK",
];

const initialValues: CareFabricFormValues = {
  sdkUrl: sdkUrls[2],
  clientId: "",
  clientSecret: "",
};

export interface CreateCareFabricConnectionDialogProps {
  isOpen: boolean;
  onSave: (connection: CareFabricConnection) => void;
  onClose: () => void;
}

export const CreateCareFabricConnectionDialog = (props: CreateCareFabricConnectionDialogProps) => {
  const { isOpen, onSave, onClose } = props;

  const registry = useRegistry();

  return (
    <Dialog
      isOpen={isOpen}
      title="New CareFabric Connection"
      onClose={onClose}
    >
      <Form<CareFabricFormValues>
        validateOnChange
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(form: FormContent<CareFabricFormValues>) => {
          const { fields } = form;
          return (
            <>
              <DialogBody className={Classes.DIALOG_BODY}>
                <FormGroup field={fields.sdkUrl} label="SDK Url">
                  <SelectInput
                    items={sdkUrls}
                    optionRenderer={i => i}
                    selectedItem={fields.sdkUrl.value}
                    onItemSelect={value => handleSdkUrlChange(form, value)}
                  />
                </FormGroup>
                <FormGroup field={fields.clientId} label="Client ID">
                  <InputGroup fill field={fields.clientId} />
                </FormGroup>
                <FormGroup field={fields.clientSecret} label="Client Secret">
                  <InputGroup fill field={fields.clientSecret} />
                </FormGroup>
              </DialogBody>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    disabled={form.isSubmitting}
                    intent="primary"
                    label="Save"
                    onClick={() => form.submitForm()}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </Dialog>
  );

  function handleSdkUrlChange(form: FormContent<CareFabricFormValues>, sdkUrl: string) {
    form.fields.sdkUrl.onChange(sdkUrl);
    form.fields.sdkUrl.onTouched();
  }

  async function handleSubmit(values: CareFabricFormValues): Promise<void> {
    const careFabric = await registry.careFabricConnections.create(values);

    onClose();

    onSave(careFabric);
  }
};
