import { type PropsWithChildren, type Ref, forwardRef } from "react";
import classNames from "classnames";
import type { Intent } from "@remhealth/ui";
import { useIsFocused } from "~/hooks/useCompose";
import { useQuillEditor } from "~/hooks/useQuillEditor";
import { Editor as Container } from "./editor.styles";

type HtmlProps =
  | "className"
  | "style"
  | "onMouseOver"
  | "onMouseOut"
  | "onMouseEnter"
  | "onMouseLeave"
  | "onMouseDown"
  | "onMouseUp";

export interface EditorProps extends Pick<React.HTMLAttributes<HTMLDivElement>, HtmlProps>, React.AriaAttributes {
  /** @default false */
  minimal?: boolean;

  /** @default false */
  soft?: boolean;

  /** @default false */
  resizable?: boolean | "none" | "vertical" | "horizontal" | "both";

  intent?: Intent;
}

export const Editor = forwardRef((props: PropsWithChildren<EditorProps>, ref: Ref<HTMLDivElement>) => {
  const {
    className,
    resizable = false,
    minimal = false,
    soft = false,
    intent = "none",
    children,
    ...divProps
  } = props;

  const editor = useQuillEditor();
  const focused = useIsFocused();
  const EditorPortal = editor.portalTargets.editor;

  const skin = soft ? "soft" : minimal ? "minimal" : "input";
  const toolbar = editor.flags.has("toolbar");
  const resize = resizable === true || resizable === "vertical"
    ? "vertical"
    : resizable === false || resizable === "none" ? "none" : resizable;

  return (
    <Container
      {...divProps}
      ref={ref}
      $intent={intent}
      $skin={skin}
      className={classNames("compose", `compose-skin-${skin}`, `resize-${resize}`, {
        disabled: editor.disabled,
        readonly: editor.readonly,
        focused,
        [`compose-skin-${skin}-toolbar`]: toolbar,
      }, className)}
    >
      {children}
      <EditorPortal />
    </Container>
  );
});
