import { useState } from "react";
import { Navigate, Route, Routes, generatePath, useMatch, useNavigate } from "react-router-dom";
import { Build } from "@remhealth/icons";
import { Button, Tab, Tabs, TabsExpander } from "@remhealth/ui";
import type { Zone } from "@remhealth/host";
import { MesaConfig } from "~/config";
import { PracticeArea } from "./practice";
import { PracticeGrid } from "./practiceGrid";
import { ReleaseDialog } from "./releaseDialog";
import { operationRoutes } from "./routes";
import { Main } from "./operations.styles";

export interface OperationsProps {
  config: MesaConfig;
  zone: Zone;
}

export const Operations = (props: OperationsProps) => {
  const tabMatch = useMatch(operationRoutes.tab);
  const navigate = useNavigate();
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);

  const defaultTabPath = generatePath(operationRoutes.tab, { tab: "practices" });

  return (
    <Routes>
      <Route element={<PracticeArea {...props} />} path=":tab/:networkId/:practiceTab/*" />
      <Route
        element={(
          <Main>
            <Tabs large renderActiveTabPanelOnly id="operations" selectedTabId={tabMatch?.params.tab} onChange={handleTabChange}>
              <Tab id="practices" panel={<PracticeGrid />} title="Practices" />
              <TabsExpander />
              <Button icon={<Build />} label="Release" onClick={toggleReleaseDialog} />
              <ReleaseDialog isOpen={releaseDialogOpen} onClose={handleReleaseDialogClose} />
            </Tabs>
          </Main>
        )}
        path=":tab/*"
      />
      <Route index element={<Navigate replace to={defaultTabPath} />} />
    </Routes>
  );

  function toggleReleaseDialog() {
    setReleaseDialogOpen(!releaseDialogOpen);
  }

  function handleReleaseDialogClose() {
    setReleaseDialogOpen(false);
  }

  function handleTabChange(tab: "practices" | "sync") {
    const path = generatePath(operationRoutes.tab, { tab });
    navigate(path);
  }
};
