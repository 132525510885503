import { useState } from "react";
import { Database } from "@remhealth/icons";
import { CareFabricConnection } from "@remhealth/apollo";
import { Button, FormField, PagingResult } from "@remhealth/ui";
import { resourceEquals } from "@remhealth/host";
import { useRegistry } from "~/contexts";
import { CreateCareFabricConnectionDialog } from "./createCareFabricConnection";
import { CareFabricItem, CareFabricItemSuggest } from "./careFabricSuggest.styles";

export type { CareFabricItem };

export interface CareFabricSuggestProps {
  field: FormField<CareFabricItem | undefined>;
}

export const CareFabricSuggest = (props: CareFabricSuggestProps) => {
  const { field } = props;

  const registry = useRegistry();
  const [showCfDialog, setShowCfDialog] = useState(false);

  return (
    <>
      <CareFabricItemSuggest
        clearable
        fetchOnBlankQuery
        fill
        field={field}
        itemsEqual={resourceEquals}
        leftIcon={<Database />}
        optionRenderer={d => d.display}
        queryable={careFabricConnectionSource}
        rightElement={!field.value
          ? <Button square label="Add New Connection" onClick={openCfDialog} />
          : undefined}
      />
      <CreateCareFabricConnectionDialog
        isOpen={showCfDialog}
        onClose={closeCfDialog}
        onSave={handleCreate}
      />
    </>
  );

  function handleCreate(connection: CareFabricConnection): void {
    field.onChange(connection);
  }

  async function careFabricConnectionSource(query: string, abort: AbortSignal): Promise<PagingResult<CareFabricConnection>> {
    const data = await registry.careFabricConnections.query({
      filters: [
        {
          clientId: { startsWithAllWords: query },
        },
      ],
      feedOptions: {
        maxItemCount: 10,
      },
      abort,
    });
    return {
      items: data.results,
      hasMore: !!data.continuationToken,
    };
  }

  function openCfDialog() {
    setShowCfDialog(true);
  }

  function closeCfDialog() {
    setShowCfDialog(false);
  }
};
