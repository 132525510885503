import styled from "styled-components";

export const SwitchWrapper = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }

  .bp5-control {
    margin: 0;
  }
`;

export interface HeaderProps {
  $singleSelect: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 8px 4px ${props => props.$singleSelect ? 12 : 35}px;
  background: ${props => props.theme.table.header};
`;

export const GridContainer = styled.div`
  margin: 8px 0;
  border: 1px solid ${props => props.theme.border.muted};
  border-radius: var(--pt-border-radius);
`;
