import { HealthcareService, Location, NoteSectionForm, Organization, Program, QuestionnaireAnswerOption, QuestionnaireElement, Reference, removeOriginal } from "@remhealth/apollo";
import { createVersionedReference } from "@remhealth/host";
import { ImportProps } from "./common";

export async function importNoteSectionForms(props: ImportProps, noteSectionFormReferences?: Reference<NoteSectionForm>[]): Promise<NoteSectionForm[]> {
  const {
    preview,
    sourceClient,
    targetClient,
    referenceFactory,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let sourceNoteSectionForms = noteSectionFormReferences
    ? await sourceClient.noteSectionForms.expand(noteSectionFormReferences, { abort })
    : await sourceClient.noteSectionForms.feed({ filters: [{ usage: { matches: "NoteDefinition" } }] }).all({ abort });

  if (sourceNoteSectionForms.length === 0) {
    return [];
  }

  let subformIds = getUnresolvedSubforms(sourceNoteSectionForms);
  while (subformIds.size > 0) {
    const subforms = await sourceClient.noteSectionForms.feed({
      filters: [{ ids: [...subformIds.values()], includeDeleted: true }],
    }).all({ abort });

    sourceNoteSectionForms.push(...subforms);

    subformIds = getUnresolvedSubforms(subforms);
  }

  onUpdate(sourceNoteSectionForms.length);

  const targetNoteSectionForms = await targetClient.noteSectionForms.feed({
    filters: [{ includeDeleted: true }],
  }).all({ abort });

  const noteSectionForms = targetNoteSectionForms.filter(f => !f.meta?.isDeleted);

  const sourceServices: Reference<HealthcareService>[] = [];
  const sourcePrograms: Reference<Program>[] = [];
  const sourceOrganizations: Reference<Organization>[] = [];
  const sourceLocations: Reference<Location>[] = [];

  const resolvedForms = new Map<string, Reference<NoteSectionForm>>();
  const failedFormIds = new Set<string>();

  targetNoteSectionForms.forEach(form => {
    resolvedForms.set(form.id, createVersionedReference(form));
  });

  sourceNoteSectionForms = sourceNoteSectionForms.filter(item => {
    onItemCreate(item.id, item.name);

    if (resolvedForms.has(item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    if (!item.meta?.isDeleted) {
      const name = item.name.trim().toLowerCase();
      if (noteSectionForms.some(d => d.usage === item.usage && d.name.trim().toLowerCase() === name)) {
        failedFormIds.add(item.id);
        onItemError(item.id, "Duplicate name", true);
        return false;
      }
    }

    if (checkCircular(new Set<string>(), item)) {
      onItemError(item.id, "Circular dependency form", true);
      return false;
    }

    item.elements.forEach(el => {
      el.answerOptions.forEach(ao => {
        sourceServices.push(...ao.serviceTypeFilters);
        sourcePrograms.push(...ao.programFilters);
        sourceOrganizations.push(...ao.insuranceFilters);
        sourceLocations.push(...ao.locationFilters);
        sourceLocations.push(...ao.serviceLocationFilters);
      });

      sourceServices.push(...el.serviceTypeFilters);
      sourcePrograms.push(...el.programFilters);
      sourceOrganizations.push(...el.insuranceFilters);
      sourceLocations.push(...el.locationFilters);
      sourceLocations.push(...el.serviceLocationFilters);
    });

    return true;
  });

  const serviceReferenceMapper = await referenceFactory.createMapperByIdentifier("healthcareServices", sourceServices, abort);
  const programReferenceMapper = await referenceFactory.createMapperByIdentifier("programs", sourcePrograms, abort);
  const organizationReferenceMapper = await referenceFactory.createMapperByIdentifier("organizations", sourceOrganizations, abort);
  const locationReferenceMapper = await referenceFactory.createMapperByIdentifier("locations", sourceLocations, abort);

  let page = sourceNoteSectionForms.filter(f => canBeProcessed(f));

  const results: NoteSectionForm[] = [];

  while (page.length > 0) {
    for (const form of page) {
      let item = copyNoteSectionForm(form);
      if (item) {
        try {
          if (!preview) {
            item = removeOriginal(item);
            const meta = { isDeleted: item.meta?.isDeleted };
            const result = await targetClient.noteSectionForms.update({ ...item, meta }, { abort });
            results.push(result);
            resolvedForms.set(form.id, createVersionedReference(result));
          } else {
            results.push(item);
            resolvedForms.set(form.id, createVersionedReference(item));
          }

          onItemComplete(form.id);
        } catch (error) {
          onItemError(form.id, "Failed to create", true);
          failedFormIds.add(form.id);
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } else {
        failedFormIds.add(form.id);
      }
    }

    page = sourceNoteSectionForms.filter(f => canBeProcessed(f));
  }

  return results;

  function copyNoteSectionForm(noteSectionForm: NoteSectionForm): NoteSectionForm | null {
    const elements: QuestionnaireElement[] = [];
    for (const el of noteSectionForm.elements) {
      const element: QuestionnaireElement = {
        ...el,
        form: undefined,
        answerOptions: [],
        serviceTypeFilters: [],
        programFilters: [],
        insuranceFilters: [],
        locationFilters: [],
        serviceLocationFilters: [],
      };

      const questionErrorText = `question "${el.text?.plainText}"`;
      if (el.form) {
        element.form = resolvedForms.get(el.form.id);
        if (!element.form) {
          onItemError(noteSectionForm.id, `Form "${el.form.display}" not found for ${questionErrorText}`);
          continue;
        }
      }

      for (const ao of el.answerOptions) {
        const answerOption: QuestionnaireAnswerOption = {
          ...ao,
          serviceTypeFilters: [],
          programFilters: [],
          insuranceFilters: [],
          locationFilters: [],
          serviceLocationFilters: [],
        };

        const answerErrorText = `answer "${ao.output}" in of ${questionErrorText}`;
        answerOption.serviceTypeFilters = serviceReferenceMapper.map(ao.serviceTypeFilters, (unmatched) => {
          onItemError(noteSectionForm.id, `Service "${unmatched.display}" not found for ${answerErrorText}`);
        });
        answerOption.programFilters = programReferenceMapper.map(ao.programFilters, (unmatched) => {
          onItemError(noteSectionForm.id, `Program "${unmatched.display}" not found for ${answerErrorText}`);
        });
        answerOption.insuranceFilters = organizationReferenceMapper.map(ao.insuranceFilters, (unmatched) => {
          onItemError(noteSectionForm.id, `Insurance "${unmatched.display}" not found for ${answerErrorText}`);
        });
        answerOption.locationFilters = locationReferenceMapper.map(ao.locationFilters, (unmatched) => {
          onItemError(noteSectionForm.id, `Location "${unmatched.display}" not found for ${answerErrorText}`);
        });
        answerOption.serviceLocationFilters = locationReferenceMapper.map(ao.serviceLocationFilters, (unmatched) => {
          onItemError(noteSectionForm.id, `Service Location "${unmatched.display}" not found for ${answerErrorText}`);
        });

        element.answerOptions.push(answerOption);
      }

      element.serviceTypeFilters = serviceReferenceMapper.map(el.serviceTypeFilters, (unmatched) => {
        onItemError(noteSectionForm.id, `Service "${unmatched.display}" not found for ${questionErrorText}`);
      });
      element.programFilters = programReferenceMapper.map(el.programFilters, (unmatched) => {
        onItemError(noteSectionForm.id, `Program "${unmatched.display}" not found for ${questionErrorText}`);
      });
      element.insuranceFilters = organizationReferenceMapper.map(el.insuranceFilters, (unmatched) => {
        onItemError(noteSectionForm.id, `Insurance "${unmatched.display}" not found for ${questionErrorText}`);
      });
      element.locationFilters = locationReferenceMapper.map(el.locationFilters, (unmatched) => {
        onItemError(noteSectionForm.id, `Location "${unmatched.display}" not found for ${questionErrorText}`);
      });
      element.serviceLocationFilters = locationReferenceMapper.map(el.serviceLocationFilters, (unmatched) => {
        onItemError(noteSectionForm.id, `Service Location "${unmatched.display}" not found for ${questionErrorText}`);
      });

      elements.push(element);
    }

    if (elements.length === 0) {
      onItemError(noteSectionForm.id, "No valid element", true);
      return null;
    }

    return {
      ...noteSectionForm,
      elements,
    };
  }

  function getUnresolvedSubforms(forms: NoteSectionForm[]): Set<string> {
    const ids = new Set<string>();
    for (const form of forms) {
      for (const element of form.elements) {
        const form = element.form;
        if (form && !sourceNoteSectionForms.some(f => f.id === form.id)) {
          ids.add(form.id);
        }
      }
    }
    return ids;
  }

  function canBeProcessed(form: NoteSectionForm) {
    if (resolvedForms.has(form.id) || failedFormIds.has(form.id)) {
      return false;
    }

    return form.elements.every(e => e.form ? resolvedForms.has(e.form.id) : true);
  }

  function checkCircular(prev: Set<string>, form: NoteSectionForm) : boolean {
    const path = new Set<string>([...prev.values(), form.id]);
    const ids = form.elements.filter(f => f.form).map(f => f.form!.id);

    for (const id of ids) {
      if (path.has(id)) {
        return true;
      }

      const subform = targetNoteSectionForms.find(f => f.id === id)
        || sourceNoteSectionForms.find(f => f.id === id);
      if (subform && checkCircular(path, subform)) {
        return true;
      }
    }

    return false;
  }
}
