import { useMemo } from "react";
import { startsWithAllWords } from "@remhealth/ui";
import {
  EnumFilter,
  IStore,
  LocalDate,
  PatientPopulationLevel,
  Practitioner,
  PractitionerFilterSet,
  PractitionerSortField,
  PractitionersClient,
  SortField,
  securityRoleIds
} from "@remhealth/apollo";
import { UserAccessStatus, getAccessStatus } from "@remhealth/host";
import { getAccessStatusFilters } from "../components";
import { getUserEmail } from "../users";
import { useStore } from "./useStore";
import { lastModifiedAscending } from "./filters";

export type BellsRoleFilter = "enabled"
  | "bells-disabled"
  | "admins"
  | "non-admins"
  | "supervisors"
  | "non-supervisors"
  | "careteam-enabled"
  | "careteam-disabled"
  | "ambient-listening-enabled"
  | "ambient-listening-disabled";

export interface PractitionerFilterOptions {
  ids?: string[];
  statuses?: UserAccessStatus[];
  suspended?: boolean;
  includeSupport?: boolean;
  roles?: BellsRoleFilter[];
  query?: string;
  notIds?: string[];
  notExcludedPatients?: string[];
  activeTenure?: boolean;
}

export function usePractitionersView(sort: SortField<PractitionerSortField>, options: PractitionerFilterOptions) {
  const store = useStore();
  return usePractitionersViewWithStore(store.practitioners, sort, options);
}

export function usePractitionersViewWithStore(store: IStore<PractitionersClient>, sort: SortField<PractitionerSortField>, options: PractitionerFilterOptions) {
  const { field, direction } = sort;

  return useMemo(() => store.view({
    filters: {
      online: createPractitionerFilters(options),
      offline: s => offlineFilter(s, options),
    },
    orderBy: {
      online: sort,
      offline: sortOffline(sort),
    },
  }), [JSON.stringify(options), field, direction]);
}

export function createPractitionerFilters(options: PractitionerFilterOptions): PractitionerFilterSet[] {
  const includesRoles: string[] = [];
  const excludesRoles: string[] = [];

  let patientPopulationOverride: EnumFilter<PatientPopulationLevel> | undefined;
  if (options.roles && options.roles.length > 0) {
    if (options.roles.includes("admins") && !options.roles.includes("non-admins")) {
      includesRoles.push(securityRoleIds.Administrative);
    }

    if (options.roles.includes("non-admins") && !options.roles.includes("admins")) {
      excludesRoles.push(securityRoleIds.Administrative);
    }

    if (options.roles.includes("enabled") && !options.roles.includes("bells-disabled")) {
      includesRoles.push(securityRoleIds.Bells);
    }

    if (options.roles.includes("supervisors") && !options.roles.includes("non-supervisors")) {
      includesRoles.push(securityRoleIds.Supervisor);
    }

    if (options.roles.includes("ambient-listening-enabled") && !options.roles.includes("ambient-listening-disabled")) {
      includesRoles.push(securityRoleIds.AmbientListening);
    }

    if (options.roles.includes("ambient-listening-disabled") && !options.roles.includes("ambient-listening-enabled")) {
      excludesRoles.push(securityRoleIds.AmbientListening);
    }

    if (options.roles.includes("non-supervisors") && !options.roles.includes("supervisors")) {
      excludesRoles.push(securityRoleIds.Supervisor);
    }

    if (options.roles.includes("bells-disabled") && !options.roles.includes("enabled")) {
      excludesRoles.push(securityRoleIds.Bells);
    }

    if (options.roles.includes("careteam-enabled") && !options.roles.includes("careteam-disabled")) {
      patientPopulationOverride = {
        matches: "CareTeam",
        presence: "MustBePresent",
      };
    }

    if (options.roles.includes("careteam-disabled") && !options.roles.includes("careteam-enabled")) {
      patientPopulationOverride = {
        matches: "All",
        presence: "MustBePresent",
      };
    }
  }

  if (options.includeSupport !== true) {
    excludesRoles.push(securityRoleIds.Support);
  }

  const idFilters: PractitionerFilterSet[] = !options.ids && !options.notIds
    ? []
    : [{
      id: {
        in: options.ids,
        notIn: options.notIds,
      },
    }];

  const patientExclusionFilter: string[] = options.notExcludedPatients && options.notExcludedPatients.length > 0 ? options.notExcludedPatients : [];

  const tentureFilter: PractitionerFilterSet = !options.activeTenure ? {} : {
    tenure: { wraps: LocalDate.today() },
  };

  const queryFilters: PractitionerFilterSet[] = !options.query ? [] : [
    {
      name: {
        display: { startsWithAllWords: options.query },
      },
    },
    {
      telecoms: {
        value: { startsWith: options.query },
      },
    },
    {
      extension: {
        name: "username",
        value: { startsWith: options.query },
      },
    },
  ];

  const statusFilters = options.statuses ? getAccessStatusFilters(options.statuses) : [];
  let filters: PractitionerFilterSet[] = [{
    ...patientExclusionFilter,
    ...tentureFilter,
    roles: {
      all: includesRoles,
    },
    referencesNotIn: [...excludesRoles, ...patientExclusionFilter],
    patientPopulationOverride: patientPopulationOverride ?? undefined,
  }];

  if (statusFilters.length > 0) {
    filters = statusFilters.flatMap(statusFilter => filters.length === 0 ? [statusFilter] : filters.map(filter => ({
      ...filter,
      ...statusFilter,
    })));
  }

  if (idFilters.length > 0) {
    filters = idFilters.flatMap(idFilter => filters.length === 0 ? [idFilter] : filters.map(filter => ({
      ...filter,
      ...idFilter,
    })));
  }

  if (queryFilters.length > 0) {
    filters = queryFilters.flatMap(queryFilter => filters.length === 0 ? [queryFilter] : filters.map(filter => ({
      ...filter,
      ...queryFilter,
    })));
  }

  return filters;
}

function offlineFilter(resource: Practitioner, options: PractitionerFilterOptions) {
  if (options.roles && options.roles.length > 0) {
    const adminRoleFilterResults: boolean[] = [];
    const bellsRoleFilterResults: boolean[] = [];
    const careTeamRoleFilterResults: boolean[] = [];

    if (options.roles.includes("admins")) {
      adminRoleFilterResults.push(resource.profile.roles.some(r => r.id === securityRoleIds.Administrative));
    }

    if (options.roles.includes("non-admins")) {
      adminRoleFilterResults.push(resource.profile.roles.every(r => r.id !== securityRoleIds.Administrative));
    }

    if (options.roles.includes("enabled")) {
      bellsRoleFilterResults.push(resource.profile.roles.some(r => r.id === securityRoleIds.Bells));
    }

    if (options.roles.includes("supervisors")) {
      bellsRoleFilterResults.push(resource.profile.roles.some(r => r.id === securityRoleIds.Supervisor));
    }

    if (options.roles.includes("bells-disabled")) {
      bellsRoleFilterResults.push(resource.profile.roles.every(r => r.id !== securityRoleIds.Bells));
    }

    if (options.roles.includes("careteam-enabled")) {
      careTeamRoleFilterResults.push(resource.patientPopulationOverride === "CareTeam");
    }

    if (options.roles.includes("careteam-disabled")) {
      careTeamRoleFilterResults.push(resource.patientPopulationOverride === "All");
    }

    return adminRoleFilterResults.reduce((acc, val) => acc || val, adminRoleFilterResults.length === 0)
      && bellsRoleFilterResults.reduce((acc, val) => acc || val, bellsRoleFilterResults.length === 0)
      && careTeamRoleFilterResults.reduce((acc, val) => acc || val, careTeamRoleFilterResults.length === 0);
  }

  if (options.includeSupport !== true && resource.profile.roles.some(r => r.id === securityRoleIds.Support)) {
    return false;
  }

  const ids = options.ids;
  if (ids && ids.length > 0 && !ids.includes(resource.id)) {
    return false;
  }

  if (options.notIds && options.notIds.length > 0 && options.notIds.includes(resource.id)) {
    return false;
  }

  if (options.statuses && options.statuses.length > 0) {
    const currentStatus = getAccessStatus(resource);

    if (!options.statuses.includes(currentStatus)) {
      return false;
    }
  }

  if (options.suspended !== undefined && resource.suspended !== options.suspended) {
    return false;
  }

  const query = options.query;
  if (query
    && !startsWithAllWords(resource.name.display, query)
    && !resource.telecoms.some(telecom => startsWithAllWords(telecom.value, query))
    && !resource.extensions?.some(ext => ext.name === "username" && startsWithAllWords(ext.value, query))) {
    return false;
  }

  return true;
}

function sortOffline(sort: SortField<PractitionerSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Practitioner, b: Practitioner) => {
    switch (sort.field) {
      case PractitionerSortField.Email: {
        const emailA = getUserEmail(a) ?? "";
        const emailB = getUserEmail(b) ?? "";
        return dir * emailA.localeCompare(emailB);
      }
      case PractitionerSortField.WorkEmail: {
        const emailA = a.telecoms.find(t => t.system === "Email" && t.use === "Work")?.value ?? "";
        const emailB = b.telecoms.find(t => t.system === "Email" && t.use === "Work")?.value ?? "";
        return dir * emailA.localeCompare(emailB);
      }
      case PractitionerSortField.Name:
        return dir * a.display.localeCompare(b.display);
      case PractitionerSortField.LastModified:
      default:
        return dir * lastModifiedAscending(a, b);
    }
  };
}
