import styled from "styled-components";
import { Dialog } from "@remhealth/ui";

export interface CareFabricItem {
  id: string;
  display: string;
}

export const ConnDialog = styled(Dialog)`
  width: 600px;
`;

export const ConnList = styled.dl`
  width: 300px;
`;

export const Heading = styled.dt`
  margin: 0;
  padding: 10px 0 3px;
  font-weight: bold;
`;

export const Data = styled.dd`
  margin: 0;
  padding: 0;
`;
