import styled from "styled-components";
import { Dialog } from "@remhealth/ui";

export const SizedDialog = styled(Dialog)`
  width: 500px;

  .bp5-dialog-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .bp5-form-group {
    margin-bottom: 15px;
  }

  .select-input {
    width: 150px;
  }

  textarea {
    width: 100%;
  }
`;
