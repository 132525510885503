import { Practice } from "@remhealth/apollo";

import { Button, NonIdealState, Spinner } from "@remhealth/ui";

import { Text } from "~/text";
import { getProductLabel } from "~/labeling";
import { DialogBody, Modal } from "./pullLoadingDialog.styles";

export interface PullLoadingDialogProps {
  practice: Practice;
  name?: string;
  onCancel: () => void;
}

export const PullLoadingDialog = (props: PullLoadingDialogProps) => {
  const { practice, name, onCancel } = props;
  const productLabel = getProductLabel(practice.product);

  return (
    <Modal autoFocus aria-label="Loading" isOpen={!!name}>
      <DialogBody>
        {name && (
          <NonIdealState
            action={<Button minimal intent="primary" label={Text.Cancel} onClick={onCancel} />}
            description={<>Gathering data for <strong>{name}</strong> from <strong>{productLabel}</strong></>}
            icon={<Spinner intent="primary" size={40} />}
          />
        )}
      </DialogBody>
    </Modal>
  );
};
