import { Tab, Tabs } from "@remhealth/ui";
import { StaffVerbRulePanel } from "@remhealth/core";
import { RuleForm } from "../schema";
import { SpeechRuleCategoryInput } from "./speechRuleCategoryInput";

export interface StaffVerbRuleFormProps {
  form: RuleForm<"StaffVerb" | "StaffVerbPatient" | "StaffVerbObject" | "StaffVerbRepeated">;
}

export function StaffVerbRuleForm(props: StaffVerbRuleFormProps) {
  const { form } = props;

  const panel = (
    <>
      <StaffVerbRulePanel form={form.rule.fields} />
      <SpeechRuleCategoryInput field={form.category} />
    </>
  );

  return (
    <Tabs renderActiveTabPanelOnly id="rule" selectedTabId="details">
      <Tab id="details" panel={panel} title="Configuration" />
    </Tabs>
  );
}
