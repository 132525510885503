import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { Button, ButtonProps } from "@remhealth/ui";

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export interface ButtonLinkProps extends ButtonProps {
  to: LinkProps["to"];
}

export const ButtonLink = (props: ButtonLinkProps) => {
  const { to, ...buttonProps } = props;
  return (
    <StyledLink tabIndex={-1} to={to}>
      <Button {...buttonProps} />
    </StyledLink>
  );
};
