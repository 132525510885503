import { AthenaQueryClient, RequestHandler, UserContext } from "@remhealth/athena-query";
import { sanitizeBaseUrl } from "@remhealth/ui";
import { AccessToken } from "@remhealth/host";

export interface AthenaQueryConfig extends UserContext {
  accessToken: Readonly<AccessToken>;
  headers?: Record<string, string>;
}

export function athenaQueryClient(baseUrl: string, config: AthenaQueryConfig): AthenaQueryClient {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;

      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);

      return {
        headers,
        ...rest,
      };
    },
  };

  return new AthenaQueryClient({
    baseURL: sanitizeBaseUrl(baseUrl),
    user: {
      zone: config.zone,
      networkId: config.networkId,
    },
    headers: config.headers,
    requestHandler,
  });
}
