import React from "react";
import * as Core from "@blueprintjs/core";
import { FormField, isElementOfType } from "../utils";
import { Radio, RadioProps } from "./toggles";

export interface RadioGroupProps extends Omit<Core.RadioGroupProps, "onChange"> {
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  field?: FormField<string | number | undefined>;
}

export class RadioGroup extends React.PureComponent<RadioGroupProps> {
  private autoGroupName = nextName();

  public componentDidUpdate(prevProps: RadioGroupProps) {
    const { field, selectedValue } = this.props;
    // Announce new value if prop changed
    if (selectedValue && selectedValue !== prevProps.selectedValue) {
      field?.onChange(selectedValue);
    }
  }

  public render() {
    const {
      onChange,
      field,
      options,
      name = field?.name,
      disabled = field?.disabled,
      selectedValue: controlledValue,
      ...radioGroupProps
    } = this.props;

    const selectedValue = controlledValue !== undefined
      ? controlledValue
      : field ? field.value : undefined;

    return (
      <Core.RadioGroup
        {...radioGroupProps}
        disabled={disabled}
        name={name}
        options={options}
        selectedValue={selectedValue}
        onChange={this.handleChange}
      >
        {Array.isArray(options) ? this.renderOptions() : this.renderChildren()}
      </Core.RadioGroup>
    );
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { onChange, field } = this.props;

    onChange?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(event.currentTarget.value);
      field?.onTouched();
    }
  };

  private renderChildren() {
    return React.Children.map(this.props.children, child => {
      if (isElementOfType(child, Radio) || isElementOfType(child, Core.Radio)) {
        return React.cloneElement(child, this.getRadioProps(child.props as RadioProps));
      }

      return child;
    });
  }

  private renderOptions() {
    return this.props.options?.map(option => (
      <Radio
        {...this.getRadioProps(option)}
        key={option.value}
        labelElement={option.label ?? option.value}
      />
    ));
  }

  private getRadioProps(optionProps: Core.RadioProps): RadioProps {
    const { field, name = field?.name, selectedValue: controlledSelectedValue } = this.props;
    const { disabled, checked, inline, value, onChange, ...restProps } = optionProps;

    const selectedValue = controlledSelectedValue !== undefined
      ? controlledSelectedValue
      : field ? field.value : undefined;

    return {
      groupField: field,
      checked: checked ?? value === selectedValue,
      disabled: disabled ?? this.props.disabled,
      inline: inline ?? this.props.inline,
      name: name == null ? this.autoGroupName : name,
      onChange: event => {
        onChange?.(event);
        this.handleChange(event);
      },
      value,
      ...restProps,
    };
  }
}

let counter = 0;
function nextName() {
  return `${Core.RadioGroup.displayName}-${counter++}`;
}
