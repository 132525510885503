import { lazy } from "@remhealth/ui";

export type { ToolbarProps } from "./toolbar";
export type { ToolbarButtonProps } from "./toolbarButton";
export type { ToolbarToggleProps } from "./toolbarToggle";

export const Toolbar = lazy(() => import("./toolbar"));
export const ToolbarButton = lazy(() => import("./toolbarButton"));
export const ToolbarToggle = lazy(() => import("./toolbarToggle"));
export const TaskList = lazy(() => import("./taskList"));
export const CreateLink = lazy(() => import("./createLink"));
