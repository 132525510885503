import { uniq } from "lodash-es";
import { NounAlternative, SpeechRule, SpeechRuleTemplate, VerbAlternative } from "@remhealth/apollo";
import { Label, Tag } from "@remhealth/ui";
import { Text } from "~/text";
import { Content, ScrollArea, TagContainer } from "./ruleSummary.styles";

type RuleFlavor = SpeechRule["rule"] | SpeechRuleTemplate["rule"];

export interface RuleSummaryProps {
  rule: RuleFlavor;
  showAlternativesOnly?: boolean;
}

export function RuleSummary(props: RuleSummaryProps) {
  const { rule, showAlternativesOnly = false } = props;
  const instruction = "instruction" in rule ? rule.instruction : undefined;
  const alternatives = getAlternatives(rule);
  return (
    <ScrollArea>
      <Content>
        {!showAlternativesOnly && instruction?.trim() && (
          <>
            <Label>{Text.Instructions}</Label>
            {instruction}
          </>
        )}
        {alternatives.length > 0 && (
          <>
            <Label>{getAlternativeLabel(rule)}</Label>
            <TagContainer>{alternatives}</TagContainer>
          </>
        )}
      </Content>
    </ScrollArea>
  );
}

export function getAlternativeLabel(rule: RuleFlavor) {
  switch (rule.type) {
    case "Acronym":
      return Text.Expansions;
    case "LonelyNoun":
      return Text.Adjectives;
    case "Literal":
    case "Noun":
    case "StaffVerb":
    case "StaffVerbObject":
    case "StaffVerbPatient":
    case "StaffVerbRepeated":
    default:
      return Text.Alternatives;
  }
}

export function getAlternatives(rule: RuleFlavor): JSX.Element[] {
  switch (rule.type) {
    case "Acronym":
      return renderTag(rule.expansions);
    case "LonelyNoun":
      return renderTag(rule.adjectives);
    case "Literal":
      return renderTag(rule.alternatives);
    case "Noun":
      return renderTag(getNounAlternatives(rule.alternatives));
    case "StaffVerb":
    case "StaffVerbObject":
    case "StaffVerbPatient":
    case "StaffVerbRepeated":
      return renderTag(getVerbAlternatives(rule.alternatives));
    default:
      return [];
  }
}

function renderTag(list: string[]) {
  return (
    list.map((item, index) => <Tag key={index} fill large minimal>{item}</Tag>)
  );
}

function getNounAlternatives(alternatives: NounAlternative[]) {
  const alternativeList = alternatives.map(alt => {
    const nouns = [alt.singular, ...alt.plural];
    return uniq(nouns).sort().join(", ");
  });
  return alternativeList;
}

function getVerbAlternatives(alternatives: VerbAlternative[]) {
  const alternativeList = alternatives.map(alt => {
    const verbs = [...alt.vb, ...alt.vbd, ...alt.vbg, ...alt.vbn, ...alt.vbp, ...alt.vbp, ...alt.vbz];
    return uniq(verbs).sort().join(", ");
  });
  return alternativeList;
}
