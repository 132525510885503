import styled, { css } from "styled-components";

type Position = "top" | "middle" | "bottom";

export interface StackProps {
  $vertical?: boolean;
  $position?: Position;
  $centered?: boolean;
  $spacing?: number;
  $wrap?: boolean;
}

function getAlignItems(position?: Position) {
  switch (position) {
    case "top": return "flex-start";
    case "middle": return "center";
    case "bottom": return "flex-end";
    default: return "stretch";
  }
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${props => props.$vertical ? "column" : "row"};
  flex-wrap: ${props => props.$wrap ? "wrap" : "nowrap"};
  align-items: ${props => getAlignItems(props.$position)};
  column-gap: ${props => props.$spacing ?? 8}px;
  row-gap: ${props => props.$spacing ?? 8}px;

  ${props => props.$centered && css`
    justify-content: center;
  `};

  .fill {
    flex-grow: 1;
    flex-shrink: 1;
  }

  > .bp5-label,
  > .bp5-control {
    margin-bottom: 0;
  }

  > .bp5-label {
    margin-bottom: 0;
  }
`;
