import styled from "styled-components";
import { Card, Dialog } from "@remhealth/ui";

export const StyledDialog = styled(Dialog)`
  min-height: 450px;
  min-width: 600px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Info = styled.div`
  margin-top: 5px;
  margin-bottom: 25px;
  text-align: center;
`;

export const SuperviseesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 10px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 5px;
  .avatar {
    flex: 0 0 auto;
  }
  .name {
    flex: 1 1 auto;
  }
`;
