import styled from "styled-components";
import { TextArea } from "@remhealth/ui";

export const UsersTextArea = styled(TextArea)`
  min-height: 250px;
  line-height: 1.5 !important;
  width: 450px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProgresssInfo = styled.span`
  white-space: nowrap;
  margin-top: 8px;
`;

export const Failures = styled.div`
  width: 100%;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;

  table {
    width: 100%;
  }
`;
