import styled from "styled-components";
import { DeprecatedReleaseFlags, MobileReleaseFlags, ReleaseFlags } from "@remhealth/host";
import { MultiSelect } from "@remhealth/ui";

export const FlagsSelect = styled(MultiSelect<ReleaseFlags | MobileReleaseFlags | DeprecatedReleaseFlags>)`
  width: 300px;

  .bp5-tag-input {
    max-height: 400px;
  }
`;

export const FlagCount = styled.span`
  font-size: var(--font-size-small);
  color: ${props => props.theme.font.muted};
`;
