import { Group, type GroupNoteDefinition, type Reference } from "@remhealth/apollo";
import { ImportProps } from "./common";

export async function updateGroups(props: ImportProps): Promise<Group[]> {
  const {
    preview,
    sourceClient,
    targetClient,
    referenceFactory,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  // Only update groups that have things to update
  let sourceGroups = await sourceClient.groups.feed({
    filters: [{
      definitions: { presence: "MustBePresent" },
      identifier: { presence: "MustBePresent" },
    }],
  }).all({ abort });

  onUpdate(sourceGroups.length);

  if (sourceGroups.length === 0) {
    return [];
  }

  const groupReferenceMapper = await referenceFactory.createMapperByIdentifier("groups", sourceGroups, abort);

  const sourceGroupNoteDefinitions: Reference<GroupNoteDefinition>[] = [];

  sourceGroups = sourceGroups.filter(item => {
    onItemCreate(item.id, item.name ?? item.id);

    const targetGroup = groupReferenceMapper.map(item);
    if (!targetGroup) {
      onItemSkip(item.id, "TargetNotFound");
      return false;
    }

    if (item.definition) {
      sourceGroupNoteDefinitions.push(item.definition);
    }

    sourceGroupNoteDefinitions.push(...item.alternativeDefinitions);

    return true;
  });

  const groupNoteDefinitionReferenceMapper = await referenceFactory.createMapperById("groupNoteDefinitions", sourceGroupNoteDefinitions, abort);

  const results: Group[] = [];
  for (const sourceGroup of sourceGroups) {
    const group = groupReferenceMapper.map(sourceGroup);
    if (!group) {
      continue;
    }

    let updated = false;
    if (sourceGroup.definition && sourceGroup.definition.id !== group.definition?.id) {
      const definition = groupNoteDefinitionReferenceMapper.map(sourceGroup.definition);
      if (definition) {
        group.definition = definition;
        updated = true;
      } else {
        onItemError(sourceGroup.id, `Group note definition "${sourceGroup.definition.display}" not found`);
      }
    }

    for (const sourceAlternativeDefinition of sourceGroup.alternativeDefinitions) {
      if (group.alternativeDefinitions.some(d => d.id === sourceAlternativeDefinition.id)) {
        continue;
      }

      const alternativeDefinition = groupNoteDefinitionReferenceMapper.map(sourceAlternativeDefinition);
      if (!alternativeDefinition) {
        onItemError(sourceGroup.id, `Group note definition "${sourceAlternativeDefinition.display}" not found for alternative definition`);
        continue;
      }
      group.alternativeDefinitions.push(alternativeDefinition);
      updated = true;
    }

    if (!updated) {
      onItemSkip(sourceGroup.id, "NoUpdate");
      continue;
    }

    try {
      if (!preview) {
        results.push(await targetClient.groups.update(group, { abort }));
      } else {
        results.push(group);
      }
      onItemComplete(sourceGroup.id);
    } catch (error) {
      onItemError(sourceGroup.id, "Failed to update", true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return results;
}
