import { DateUtils, TimePrecision } from "@blueprintjs/datetime";
import { DateTime } from "luxon";

export function clampTimeByPrecision(date: Date, precision: TimePrecision | undefined): Date {
  const newValue = DateUtils.clone(date);

  switch (precision) {
    case undefined:
      newValue.setHours(0);
      newValue.setMinutes(0);
      newValue.setSeconds(0);
      newValue.setMilliseconds(0);
      break;
    case "minute":
      newValue.setSeconds(0);
      newValue.setMilliseconds(0);
      break;
    case "second":
      newValue.setMilliseconds(0);
  }

  return newValue;
}

export function getMidnight() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function isControlKey(e: React.KeyboardEvent) {
  return e.key.length > 1; // Control keys are descriptive
}

export function handleKeyEvent(e: React.KeyboardEvent, key: string, action: () => void, preventDefault = true) {
  if (e.key !== key) {
    return false;
  }
  if (preventDefault) {
    e.preventDefault();
  }
  action();
  return true;
}

const formats = ["t", "T"];

export function parseTime(str: string): Date | null | false {
  if (!str) {
    return null;
  }

  str = str.replace(" ", "");
  str = str.replace(/am$/i, "AM");
  str = str.replace(/pm$/i, "PM");

  for (const format of formats) {
    const parsed = DateTime.fromFormat(str, format);
    if (parsed.isValid) {
      return parsed.toJSDate();
    }
  }

  return false;
}
