import styled from "styled-components";

export const PaddedContainer = styled.div`
  padding: 20px;
`;

export const FieldWrapper = styled.div`
  margin: 0 0 20px 0;
`;

export const ImportListContainer = styled(FieldWrapper)`
  display: flex;
  align-items: center;
`;

export const LinkContent = styled.a`
  padding: 20px 0px 0px 10px;
`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  @media (max-width: 800px) {
    .collapsable {
      display: none;
    }
  }
`;

export const ActionCell = styled.td`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 50px;
    text-overflow: clip;
  }
`;
