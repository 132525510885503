import { Tab, Tabs } from "@remhealth/ui";
import { LonelyNounRulePanel } from "@remhealth/core";
import { RuleForm } from "../schema";
import { SpeechRuleCategoryInput } from "./speechRuleCategoryInput";

export interface LonelyNounRuleFormProps {
  form: RuleForm<"LonelyNoun">;
}

export function LonelyNounRuleForm(props: LonelyNounRuleFormProps) {
  const { form } = props;

  const panel = (
    <>
      <LonelyNounRulePanel form={form.rule.fields} />
      <SpeechRuleCategoryInput field={form.category} />
    </>
  );

  return (
    <Tabs renderActiveTabPanelOnly id="rule" selectedTabId="details">
      <Tab id="details" panel={panel} title="Configuration" />
    </Tabs>
  );
}
