const docElement = document.documentElement;

const isSvg = docElement.nodeName.toLowerCase() === "svg";

type InjectedBody = HTMLElement & { fake?: boolean };

function getBody(): InjectedBody {
  // After page load injecting a fake body doesn't work so check if body exists
  let body = document.body as InjectedBody | undefined;

  if (!body) {
    // Can't use the real body create a fake one.
    body = document.createElement(isSvg ? "svg" : "body");
    body.fake = true;
  }

  return body;
}

type InjectionCallback<TResult> = (div: HTMLDivElement, rule: string) => TResult;

function injectElementWithStyles<TResult>(rule: string, callback: InjectionCallback<TResult>) {
  const mod = "modernizr";
  const div = document.createElement("div");
  const body = getBody();

  const style = document.createElement("style");
  style.type = "text/css";
  style.id = `s${mod}`;

  (!body.fake ? div : body).append(style);
  body.append(div);

  style.append(document.createTextNode(rule));
  div.id = mod;

  let docOverflow: string;

  if (body.fake) {
    // Avoid crashing IE8, if background image is used
    body.style.background = "";
    // Safari 5.13/5.1.4 OSX stops loading if ::-webkit-scrollbar is used and scrollbars are visible
    body.style.overflow = "hidden";
    docOverflow = docElement.style.overflow;
    docElement.style.overflow = "hidden";
    docElement.append(body);
  }

  const ret = callback(div, rule);

  // If this is done after page load we don't want to remove the body so check if body exists
  if (body.fake) {
    body.remove();
    docElement.style.overflow = docOverflow!;
    // Trigger layout so kinetic scrolling isn't disabled in iOS6+
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    docElement.offsetHeight;
  } else {
    div.remove();
  }

  return ret;
}

export const hiddenscroll = injectElementWithStyles("#modernizr {width:100px;height:100px;overflow:scroll}", (elem) => {
  return elem.offsetWidth === elem.clientWidth;
});
