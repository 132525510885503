import styled from "styled-components";

export const ActionsHeader = styled.th`
  width: 300px;
`;

export const ActionCell = styled.td`
  text-align: right;
  > * {
    margin-left: 5px;
  }
`;
