import Quill from "quill";
import Delta from "quill-delta";
import { type FieldValidation, type PortalTarget, createPortal } from "@remhealth/ui";
import type { Range } from "~/types";
import { type EditorToHtmlOptions, type HtmlToTextOptions, editorToHtml, htmlToText } from "~/parse/parsers";
import { type ContentOptions, applyOptions, isBlankLine, setQuillContents } from "~/delta";
import type { ComposeEditor, ComposeEditorPortals } from "./composeEditor";
import { type Matcher, type MatcherSelector, Registry } from "./registry";

export type QuillChangeType = "text" | "selection" | "focused" | "initialValue" | "field" | "handle" | "registry" | "mode" | "flag" | "hook";
export type QuillOnChangeHandler = (type: QuillChangeType, editor: QuillEditor) => void;
export type UnlockFocusCallback = () => void;

export class QuillEditor implements ComposeEditor {
  private readonly _portals = {
    editor: createPortal({ targetClassName: "editor-portal" }),
    content: createPortal({ targetClassName: "content-portal" }),
    toolbarLeft: createPortal({ targetClassName: "toolbar-left" }),
    toolbarRight: createPortal({ targetClassName: "toolbar-right" }),
  };

  private readonly _onChange: QuillOnChangeHandler;

  private _flags = new Set<string>();
  private _handles = new Map<string, unknown>();
  private _focusLocks = new Set<number>();
  private _pendingFocus = false;
  private _quill: Quill | null = null;
  private _hookVersion: number | null = null;
  private _eventsPaused = false;
  private _pendingEvents = new Set<QuillChangeType>();
  private _range: Range | null = null;
  private _html: string | null = null;
  private _text: string | null = null;
  private _mode: "enabled" | "readonly" | "disabled" = "enabled";
  private _initialValueVersion = 0;
  private _valueVersion = 0;
  private _mountVersion = Math.random();
  private _field: FieldValidation;
  private _registry: Registry;
  private _initialValue: string;

  constructor(initialValue: string, field: FieldValidation, registry: Registry, onChange: QuillOnChangeHandler) {
    this._initialValue = initialValue;
    this._field = field;
    this._registry = registry;
    this._onChange = onChange;
  }

  public get mounted(): boolean {
    return !!this._quill;
  }

  public get id(): string | undefined {
    return this._quill?.root.id;
  }

  public get editorNode(): HTMLDivElement | undefined {
    return this._quill?.root ?? undefined;
  }

  public get initialValue(): string {
    return this._initialValue;
  }

  public get composingHtml(): string {
    return this._quill?.root.innerHTML ?? "";
  }

  public get quill(): Quill {
    if (!this._quill) {
      throw new Error("A Quill instance has not been initialized.");
    }

    return this._quill;
  }

  public get mountVersion(): number {
    return this._mountVersion;
  }

  public get hookVersion(): number | null {
    return this._hookVersion;
  }

  public get valueVersion(): number {
    return this._valueVersion;
  }

  public get initialValueVersion(): number {
    return this._initialValueVersion;
  }

  public get focused(): boolean {
    return !!this.range || this._pendingFocus || this._focusLocks.size > 0;
  }

  public get range(): Range | null {
    const lastRange = this._quill?.selection.lastRange;

    if (!lastRange) {
      this._range = null;
      return null;
    }

    // Avoid returning new instance of Range if values are same as before
    if (lastRange.index !== this._range?.index || lastRange.length !== this._range?.length) {
      this._range = { index: lastRange.index, length: lastRange.length };
    }

    return this._range;
  }

  public get readonly(): boolean {
    return this._mode === "readonly";
  }

  public get disabled(): boolean {
    return this._mode === "disabled";
  }

  public get enabled(): boolean {
    return this._mode === "enabled";
  }

  public get flags(): ReadonlySet<string> {
    return this._flags;
  }

  public get formats(): ReadonlySet<string> {
    return this._registry.registered;
  }

  public get registry(): Registry {
    return this._registry;
  }

  public get portals(): ComposeEditorPortals {
    return {
      editor: this._portals.editor.Portal,
      content: this._portals.content.Portal,
      toolbarLeft: this._portals.toolbarLeft.Portal,
      toolbarRight: this._portals.toolbarRight.Portal,
    };
  }

  public get portalTargets(): Record<keyof ComposeEditorPortals, PortalTarget> {
    return {
      editor: this._portals.editor.Target,
      content: this._portals.content.Target,
      toolbarLeft: this._portals.toolbarLeft.Target,
      toolbarRight: this._portals.toolbarRight.Target,
    };
  }

  public get field(): Readonly<FieldValidation> {
    return this._field;
  }

  public set field(value: FieldValidation) {
    this._field = value;
    this.dispatchChange("field");
  }

  public hook(quill: Quill, version: number): void {
    this._quill = quill;
    this._hookVersion = version;
    this.dispatchChange("hook");
  }

  public pauseEvents(): void {
    this._eventsPaused = true;
  }

  public unpauseEvents(): void {
    this._eventsPaused = false;

    if (this._pendingEvents.size > 0) {
      const events = Array.from(this._pendingEvents.values());
      this._pendingEvents.clear();

      for (const eventType of events) {
        this._onChange(eventType, this);
      }
    }
  }

  public dispatchChange(type: QuillChangeType): void {
    if (type === "text") {
      this._valueVersion++;
      this._html = null;
      this._text = null;
    }

    if (type === "selection") {
      this._pendingFocus = false;
    }

    if (this._eventsPaused) {
      this._pendingEvents.add(type);
    } else {
      this._onChange(type, this);
    }
  }

  public getHandle<T>(name: string): T | null {
    const handle = this._handles.get(name);
    return handle ? handle as T : null;
  }

  public setHandle(name: string, value: unknown): void {
    this._handles.set(name, value);
    this.dispatchChange("handle");
  }

  public deleteHandle(name: string): void {
    this._handles.delete(name);
  }

  public enableFlag(flag: string): void {
    if (this._flags.has(flag)) {
      return;
    }

    this._flags.add(flag);

    if (this.mounted) {
      this.dispatchChange("flag");
    }
  }

  public removeFlag(flag: string): void {
    if (!this._flags.delete(flag)) {
      return;
    }

    this._flags.delete(flag);

    if (this.mounted) {
      this.dispatchChange("flag");
    }
  }

  public addFormat(format: string): void {
    if (this._registry.isRegistered(format)) {
      return;
    }

    if (this.mounted) {
      this._mountVersion = Math.random();
    }

    this._registry.register(format);
    this.dispatchChange("registry");
  }

  public removeFormat(format: string): void {
    if (!this._registry.isRegistered(format)) {
      return;
    }

    if (this.mounted) {
      this._mountVersion = Math.random();
    }

    this._registry.unregister(format);
    this.dispatchChange("registry");
  }

  public addMatcher(selector: MatcherSelector, matcher: Matcher): void {
    if (this._registry.hasMatcher(selector, matcher)) {
      return;
    }

    if (this.mounted) {
      this._mountVersion = Math.random();
    }

    this._registry.addMatcher(selector, matcher);
    this.dispatchChange("registry");
  }

  public removeMatcher(selector: MatcherSelector, matcher: Matcher): void {
    if (!this._registry.hasMatcher(selector, matcher)) {
      return;
    }

    if (this.mounted) {
      this._mountVersion = Math.random();
    }

    this._registry.removeMatcher(selector, matcher);
    this.dispatchChange("registry");
  }

  public reset(initialValue: string): void {
    this._initialValue = initialValue;
    this._initialValueVersion++;
    this.dispatchChange("initialValue");
  }

  public getLength(): number {
    return this._quill ? this._quill.getLength() : 0;
  }

  public insertContent(index: number, content: string, options?: ContentOptions, isUserChange?: boolean): number {
    const appendDelta = this.quill.clipboard.convert({ html: content });

    if (this.isEmpty()) {
      this.setContent(appendDelta, options, isUserChange);
      return appendDelta.length();
    }

    applyOptions(appendDelta, options);

    const beforeAppendContent = this.quill.getContents(0, index);
    const afterAppendContent = this.quill.getContents(index);
    const beforeAndInsert = beforeAppendContent.concat(appendDelta);
    const insertIndex = beforeAndInsert.length();

    // Ignore trailing \n
    const newContent = isBlankLine(afterAppendContent)
      ? beforeAndInsert.chop()
      : beforeAndInsert.concat(afterAppendContent).chop();

    this.setContent(newContent, undefined, isUserChange);
    return insertIndex;
  }

  public deleteContent(range: Range, isUserChange?: boolean): number {
    this.quill.deleteText(range, isUserChange ? "user" : "api");
    return this.getLength();
  }

  public appendContent(content: string, options?: ContentOptions, isUserChange?: boolean): number {
    if (this.isEmpty()) {
      return this.setContent(content, options, isUserChange);
    }

    const appendDelta = this.quill.clipboard.convert({ html: content });
    applyOptions(appendDelta, options);

    const delta = this.quill.getContents().concat(appendDelta).chop();
    return this.setContent(delta, undefined, isUserChange);
  }

  public setContent(content: string, options?: ContentOptions, isUserChange?: boolean): number;
  public setContent(delta: Delta, options?: ContentOptions, isUserChange?: boolean): number;
  public setContent(content: Delta | string, options?: ContentOptions, isUserChange?: boolean): number {
    let delta: Delta;
    if (typeof content === "string") {
      delta = this.quill.clipboard.convert({ html: content });
    } else {
      delta = content;
    }

    applyOptions(delta, options);

    setQuillContents(this.quill, delta, isUserChange ? "user" : "api");

    return this.getLength();
  }

  public isEmpty(): boolean {
    return this._quill ? this._quill.editor.isBlank() : true;
  }

  public getContents(): Delta {
    return this._quill ? this._quill.getContents() : new Delta();
  }

  public getHtml(options?: EditorToHtmlOptions): string;
  public getHtml(range: Range, options: EditorToHtmlOptions): string;
  public getHtml(arg1?: Range | EditorToHtmlOptions, arg2?: EditorToHtmlOptions): string {
    const range = arg2 && arg1 ? arg1 as Range : null;
    const options = (arg2 ?? arg1 ?? {}) as EditorToHtmlOptions;

    if (range) {
      return this._quill ? editorToHtml(this._quill.getSemanticHTML(range.index, range.length), options) : "";
    }

    if (this._html !== null) {
      return this._html;
    }

    const html = editorToHtml(this.composingHtml, options);
    this._html = html;
    return html;
  }

  public getText(options?: HtmlToTextOptions): string;
  public getText(range: Range, options: HtmlToTextOptions): string;
  public getText(arg1?: Range | HtmlToTextOptions, arg2?: HtmlToTextOptions): string {
    const range = arg2 && arg1 ? arg1 as Range : null;
    const options = (arg2 ?? arg1 ?? {}) as HtmlToTextOptions;

    if (range) {
      return this._quill ? htmlToText(this._quill.getText(range.index, range.length), options) : "";
    }

    if (this._text !== null) {
      return this._text;
    }

    const text = htmlToText(this.composingHtml, options);
    this._text = text;
    return text;
  }

  public focus(): void {
    this._pendingFocus = true;

    setTimeout(() => {
      this._pendingFocus = false;
      if (this._quill) {
        const lastIndex = this._quill.getLength();
        this._quill.setSelection(lastIndex, 0, "user");
      }
    }, 10);
  }

  public blur(): void {
    this._pendingFocus = false;
    this._quill?.setSelection(null);
  }

  public lockFocus(): UnlockFocusCallback {
    const id = Math.random();

    const wasFocused = this.focused;
    this._focusLocks.add(id);

    if (wasFocused !== this.focused) {
      this.dispatchChange("focused");
    }

    return () => {
      const wasFocused = this.focused;
      this._focusLocks.delete(id);

      if (wasFocused !== this.focused) {
        this.dispatchChange("focused");
      }
    };
  }

  public makeEnabled(): void {
    if (this._mode === "enabled") {
      return;
    }

    this._mode = "enabled";
    this._quill?.enable(true);
    this.dispatchChange("mode");
  }

  public makeReadonly(): void {
    if (this._mode === "readonly") {
      return;
    }

    this._mode = "readonly";
    this._quill?.enable(false);
    this.dispatchChange("mode");
  }

  public makeDisabled(): void {
    if (this._mode === "disabled") {
      return;
    }

    this._mode = "disabled";
    this._pendingFocus = false;
    this._quill?.enable(false);
    this._quill?.setSelection(null);
    this.dispatchChange("mode");
  }
}
