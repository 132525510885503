import { useMemo, useRef } from "react";
import { type FormField, useCallbackRef, useUpdateEffect } from "@remhealth/ui";
import type { QuillEditor } from "~/editor/quillEditor";
import { htmlToText } from "~/parse/parsers";
import type { ComposeController } from "./useComposeController";

interface RichText {
  plainText?: string;
  value?: string;
}

export type ComposeField = FormField<RichText | undefined>;

export function useComposeField(field: ComposeField | undefined, controller: ComposeController) {
  const isEmptyFieldInitialValue = useMemo(() => field ? isEmptyRichText(field.initialValue) : true, [field?.initialValue]);

  const value = field?.value?.value ?? "";
  const lastKnownValue = useRef<string>(value);

  const onChange = useCallbackRef(handleOnChange);

  // Trigger reset if the field's value changes outside our knowledge
  useUpdateEffect(() => {
    if (lastKnownValue.current !== value) {
      lastKnownValue.current = value;
      controller.setInitialValue(lastKnownValue.current);
    }
  }, [value]);

  return { onChange };

  function handleOnChange(editor: QuillEditor) {
    if (!field || field.readOnly || field.disabled) {
      return;
    }

    const isEmpty = editor.isEmpty();

    try {
      // Try to mirror field's initialValue in blank/empty scenario
      if (isEmpty && isEmptyFieldInitialValue) {
        lastKnownValue.current = field.initialValue?.value ?? "";
        field.onChange(field.initialValue);
      } else {
        const html = editor.getHtml();
        if (field.value?.value !== html) {
          lastKnownValue.current = html;
          field.onChange({ plainText: editor.getText(), value: html });
        }
      }
    } finally {
      field.onTouched();
    }
  }
}

function isEmptyRichText(value: RichText | undefined): boolean {
  if (value === undefined) {
    return true;
  }

  if (value.value === undefined) {
    return true;
  }

  if (value.value === "") {
    return true;
  }

  return htmlToText(value.value) === "";
}
