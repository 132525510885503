import { KeyboardEvent, useState } from "react";
import { Button, FormGroup, InputGroup, Switch, TagList, Tooltip, isEnterKeyPress } from "@remhealth/ui";
import { DragHandleVertical } from "@remhealth/icons";
import { RuleForm } from "../schema";

export interface LiteralRulePanelProps {
  form: RuleForm<"Literal">;
}

export function LiteralRulePanel(props: LiteralRulePanelProps) {
  const { form } = props;

  const [newSuggestion, setNewSuggestion] = useState("");
  const [addSuggestionError, setAddSuggestionError] = useState("");

  const addSuggestion = (
    <Tooltip isOpen content={addSuggestionError} disabled={!addSuggestionError} intent="danger" placement="top-start">
      <InputGroup
        maxLength={100}
        placeholder="Enter new replacement..."
        rightElement={<Button square disabled={!newSuggestion.trim()} label="Add" onClick={handleAddSuggestion} />}
        value={newSuggestion}
        onBlur={handleSuggestionBlur}
        onChange={setNewSuggestion}
        onKeyDown={handleSuggestionKeyDown}
      />
    </Tooltip>
  );

  return (
    <>
      <FormGroup field={form.text} helperText="Enter any word or phrase to discourage" label="Word or phrase">
        <InputGroup field={form.text} maxLength={100} placeholder="Enter text..." />
        <Switch field={form.caseSensitive} label="Case sensitive" />
      </FormGroup>
      <FormGroup field={form.instruction} helperText="Give some rationale or advice to help explain the recommendation" label="Instructions">
        <InputGroup field={form.instruction} maxLength={300} placeholder="Write instruction..." />
      </FormGroup>
      <FormGroup field={form.alternatives} helperText="Suggested replacements in preferred order" label="Replacement to suggest">
        {addSuggestion}
        {form.alternatives.value.length > 0 && (
          <TagList
            removable
            sortable={form.alternatives.value.length > 1}
            tagProps={{ icon: form.alternatives.value.length > 1 ? <DragHandleVertical /> : undefined }}
            values={form.alternatives.value}
            onChange={handleSuggestionChange}
          />
        )}
      </FormGroup>
    </>
  );

  function handleSuggestionKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    setAddSuggestionError("");

    if (isEnterKeyPress(event)) {
      handleAddSuggestion();
    }
  }

  function handleSuggestionBlur() {
    setAddSuggestionError("");
  }

  function handleAddSuggestion() {
    const value = newSuggestion.trim();

    // Avoid duplicates
    const alreadyExists = form.alternatives.value.includes(value);

    if (alreadyExists) {
      setAddSuggestionError("This replacement is already present in the list.");
      return;
    }

    setNewSuggestion("");
    setAddSuggestionError("");

    form.alternatives.onChange([...form.alternatives.value, value]);
    form.alternatives.onTouched();
  }

  function handleSuggestionChange(alternatives: string[]) {
    form.alternatives.onChange(alternatives);
    form.alternatives.onTouched();
  }
}
