import styled from "styled-components";

export const Main = styled.main`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
`;

export const Body = styled.div`
  background-color: var(--light-gray4);
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
