import { useCallback, useMemo, useRef, useState } from "react";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { ChevronDown, ChevronUp } from "@remhealth/icons";
import { type FormField, getIntent } from "~/utils/form";
import { padWithZeroes } from "~/utils/misc";
import { useAutomation, useCallbackRef } from "~/hooks";
import { Dial, type DialHandle, type DialProps, type DialScheme, type DialSlot } from "./dial";
import { ButtonGroup } from "./buttonGroup";
import { IconButton } from "./button";
import { getComponentId } from "./formScope";

type SharedDialProps = Pick<DialProps,
  | "placeholders"
  | "autoFocus"
  | "id"
  | "name"
  | "className"
  | "disabled"
  | "readOnly"
  | "intent"
  | "large"
  | "fill"
  | "canClearSelection"
  | "rightElement"
  | "onFocus"
  | "onBlur"
  | "onClick"
  | keyof React.AriaAttributes
>;

export interface NumberDialProps extends SharedDialProps {
  defaultValue?: number;
  value?: number | null;
  min?: number;
  max?: number;
  /**
   * Number of decimal points.
   * @default 0
   */
  decimals?: number;
  showArrowButtons?: boolean;
  field?: FormField<number | undefined>;
  onChange?: (value: number | null) => void;
}

export const NumberDial = (props: NumberDialProps) => {
  const {
    className,
    field,
    name = field?.name,
    readOnly = field?.readOnly ?? false,
    disabled = field?.disabled ?? false,
    intent = getIntent(field),
    defaultValue,
    value: controlledValue = field ? field?.value ?? null : undefined,
    min,
    max,
    showArrowButtons = true,
    decimals = 0,
    onBlur,
    onChange,
    ...dialProps
  } = props;

  const { id, label } = useAutomation(props);
  const incrementId = getComponentId(id, "increment");
  const decrementId = getComponentId(id, "decrement");

  const [uncontrolledValue, setUncontrolledValue] = useState<number | null>(null);
  const dialRef = useRef<DialHandle>(null);

  const value = controlledValue !== undefined ? controlledValue : uncontrolledValue;

  const dialScheme = useMemo(() => getNumberDialScheme(decimals), [decimals]);

  const clamp = useCallback(handleClamp, [min, max, decimals]);
  const onBlurCallback = useCallbackRef(handleBlur);
  const onDialChangeCallback = useCallbackRef(handleDialChange);

  const defaultDialValue = useMemo(() => getNumberDialValue(defaultValue, decimals), [defaultValue, decimals]);
  const dialValue = useMemo(() => getNumberDialValue(value, decimals), [value, decimals]);

  const classes = useMemo(() => classNames(className, Classes.NUMERIC_INPUT, Classes.CONTROL_GROUP), [className]);

  return (
    <div className={classes} role="group">
      <Dial
        ref={dialRef}
        {...dialProps}
        clamp={clamp}
        defaultValue={defaultDialValue}
        disabled={disabled}
        field={field}
        format={format}
        intent={intent}
        name={name}
        parse={parse}
        readOnly={readOnly}
        scheme={dialScheme}
        value={dialValue}
        onBlur={onBlurCallback}
        onChange={onDialChangeCallback}
      />
      {showArrowButtons && (
        <ButtonGroup vertical>
          <IconButton
            aria-label={label ? `Increment ${label}` : "Increment"}
            disabled={disabled}
            icon={<ChevronUp />}
            id={incrementId}
            tabIndex={-1}
            onClick={handleIncrement}
          />
          <IconButton
            aria-label={label ? `Decrement ${label}` : "Decrement"}
            disabled={disabled}
            icon={<ChevronDown />}
            id={decrementId}
            tabIndex={-1}
            onClick={handleDecrement}
          />
        </ButtonGroup>
      )}
    </div>
  );

  function parse(value: string): number[] | null | undefined {
    if (!value) {
      return null;
    }
    const number = Number.parseInt(value, 10);
    return !Number.isNaN(number) ? getNumberDialValue(number, decimals) : undefined;
  }

  function format(dialValue: number[]): string {
    return formatNumber(getNumberDialNumber(dialValue, decimals), decimals);
  }

  function setValue(value: number | null) {
    dialRef.current?.setValue(getNumberDialValue(value, decimals));
  }

  function handleIncrement() {
    if (disabled || readOnly) {
      return;
    }

    const newValue = value != null ? value + 1 : 1;
    setValue(max !== undefined ? Math.min(max, newValue) : newValue);
  }

  function handleDecrement() {
    if (disabled || readOnly) {
      return;
    }

    const newValue = value != null ? value - 1 : -1;
    setValue(min !== undefined ? Math.max(min, newValue) : newValue);
  }

  function handleClamp(dialValue: number[]): number[] {
    const value = getNumberDialNumber(dialValue, decimals);
    if (min !== undefined && value < min) {
      return getNumberDialValue(min, decimals);
    }
    if (max !== undefined && value > max) {
      return getNumberDialValue(max, decimals);
    }
    return dialValue;
  }

  function handleDialChange(dialValue: number[] | null) {
    handleNumberChange(dialValue ? getNumberDialNumber(dialValue, decimals) : null);
  }

  function handleNumberChange(value: number | null) {
    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(value ?? undefined);
      field?.onTouched();
    }

    setUncontrolledValue(value);
    onChange?.(value);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>, slot: DialSlot, isFocusingSelf: boolean) {
    field?.onTouched();
    onBlur?.(event, slot, isFocusingSelf);
  }
};

export function getNumberDialScheme(decimals: number): DialScheme {
  const dialScheme: DialScheme = [
    {
      label: "Whole Number",
      width: 60,
      parent: null,
      nowrap: true,
      arrowIncrement: 1,
      scrollIncrement: 1,
      defaultValue: 0,
      shiftInputOnKeys: ["."],
      className: "whole",
      min: () => Number.NEGATIVE_INFINITY,
      max: () => Number.POSITIVE_INFINITY,
    },
  ];

  if (decimals > 0) {
    const maxFractions = Math.pow(10, decimals) - 1;

    dialScheme.push(
      ".",
      {
        label: "Fraction",
        width: 10 * decimals,
        parent: 0,
        nowrap: true,
        arrowIncrement: 1,
        scrollIncrement: 1,
        defaultValue: 0,
        className: "fraction",
        format: value => padWithZeroes(value.toString(), decimals),
        min: () => 0,
        max: () => maxFractions,
      }
    );
  }

  return dialScheme;
}

export function getNumberDialValue(value: number, decimals: number): number[];
export function getNumberDialValue(value: number | null, decimals: number): number[] | null;
export function getNumberDialValue(value: number | undefined, decimals: number): number[] | undefined;
export function getNumberDialValue(value: number | null | undefined, decimals: number): number[] | null | undefined;
export function getNumberDialValue(value: number | null | undefined, decimals: number): number[] | null | undefined {
  if (value != null) {
    const dialValue = [Math.trunc(value)];
    if (decimals) {
      const pow = Math.pow(10, decimals);
      dialValue.push(value * pow % pow);
    }
    return dialValue;
  }
  return value;
}

export function getNumberDialNumber(dialValue: number[], decimals: number): number;
export function getNumberDialNumber(dialValue: number[] | null, decimals: number): number | null;
export function getNumberDialNumber(dialValue: number[] | null, decimals: number): number | null {
  if (!dialValue) {
    return dialValue;
  }

  if (decimals === 0) {
    return dialValue[0];
  }

  const pow = Math.pow(10, decimals);
  const wholeNumber = dialValue[0] ?? 0;
  const fraction = dialValue[1] ?? 0;

  return wholeNumber + (fraction / pow);
}

function formatNumber(value: number | null, decimals: number): string {
  if (value != null) {
    const wholeNumber = Math.trunc(value);
    if (decimals === 0) {
      return wholeNumber.toString();
    }
    const pow = Math.pow(10, decimals);
    const fraction = value * pow % pow;
    return `${wholeNumber}.${padWithZeroes(fraction.toString(), decimals)}`;
  }
  return "";
}
