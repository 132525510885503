import styled from "styled-components";
import { Dialog } from "@remhealth/ui";

export const Modal = styled(Dialog)`
  padding: 20px;
  border-radius: 12px;
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`;
