import { HealthcareService, Location, NoteRule, Program, Reference } from "@remhealth/apollo";
import { ImportProps } from "./common";

export async function importNoteRules(props: ImportProps): Promise<NoteRule[]> {
  const {
    preview,
    sourceClient,
    targetClient,
    referenceFactory,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemUpdate,
    onItemComplete,
  } = props;

  let sourceNoteRules = await sourceClient.noteRules.feed({
    filters: [],
  }).all({ abort });

  onUpdate(sourceNoteRules.length);
  if (sourceNoteRules.length === 0) {
    return [];
  }

  const sourceServices: Reference<HealthcareService>[] = [];
  const sourcePrograms: Reference<Program>[] = [];
  const sourceLocations: Reference<Location>[] = [];

  const targetNoteRules = await targetClient.noteRules.feed({
    filters: [{ includeDeleted: true }],
  }).all({ abort });
  const noteRules = targetNoteRules.filter(f => !f.meta?.isDeleted);

  sourceNoteRules = sourceNoteRules.filter(item => {
    onItemCreate(item.id, item.name ?? item.criteria.type);

    if (targetNoteRules.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    if (!isSingleSetting(item)) {
      const name = item.name?.trim().toLowerCase();
      if (noteRules.some(d => d.criteria.type === item.criteria.type && d.name === name)) {
        onItemError(item.id, "Duplicate name", true);
        return false;
      }
    }

    sourceServices.push(...item.limitToServices);

    switch (item.criteria.type) {
      case "ProgramSelected":
        sourcePrograms.push(...item.criteria.programs);
        sourceServices.push(...item.criteria.allowedServices);
        break;

      case "ProgramLocation":
        sourcePrograms.push(...item.criteria.programs);
        sourceLocations.push(...item.criteria.allowedLocations);
        break;

      case "LocationSelected":
        sourceLocations.push(...item.criteria.locations);
        sourceServices.push(...item.criteria.allowedServices);
        break;

      case "SpanMidnight":
        sourceServices.push(...item.criteria.excludingServices);
        break;

      case "SessionDuration":
      case "ShowStatus":
      case "ServiceDate":
      case "MissingDiagnosis":
      case "AppointmentRequired":
      case "SessionOverlap":
      case "Authorization":
      case "SessionDurationIncrement":
        break;
    }

    return true;
  });

  const serviceReferenceMapper = await referenceFactory.createMapperByIdentifier("healthcareServices", sourceServices, abort);
  const programReferenceMapper = await referenceFactory.createMapperByIdentifier("programs", sourcePrograms, abort);
  const locationReferenceMapper = await referenceFactory.createMapperByIdentifier("locations", sourceLocations, abort);

  const results: NoteRule[] = [];
  for (const rule of sourceNoteRules) {
    const item = copyNoteRule(rule);
    if (item) {
      if (isSingleSetting(rule)) {
        const existingRule = noteRules.find(s => s.criteria.type === rule.criteria.type);
        if (existingRule) {
          try {
            await targetClient.noteRules.deleteById(existingRule.id, { abort });
            onItemUpdate(rule.id, existingRule.id);
          } catch (error) {
            onItemError(rule.id, "Failed to delete existing item");
            // eslint-disable-next-line no-console
            console.error(error);
            continue;
          }
        }
      }

      try {
        if (!preview) {
          results.push(await targetClient.noteRules.update({ ...item, meta: undefined }, { abort }));
        } else {
          results.push(item);
        }
        onItemComplete(rule.id);
      } catch (error) {
        onItemError(rule.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copyNoteRule(rule: NoteRule): NoteRule | null {
    const limitToServices = serviceReferenceMapper.map(rule.limitToServices, (unmatched) => {
      onItemError(rule.id, `Service "${unmatched.display}" not found for limited to services`);
    });

    switch (rule.criteria.type) {
      case "ProgramSelected": {
        const programs = programReferenceMapper.map(rule.criteria.programs, (unmatched) => {
          onItemError(rule.id, `Program "${unmatched.display}" not found`);
        });
        const allowedServices = serviceReferenceMapper.map(rule.criteria.allowedServices, (unmatched) => {
          onItemError(rule.id, `Service "${unmatched.display}" not found for allowed services`);
        });

        if (programs.length === 0 || allowedServices.length === 0) {
          onItemError(rule.id, "No valid program or allowed service", true);
          return null;
        }

        return {
          ...rule,
          limitToServices,
          criteria: {
            ...rule.criteria,
            programs,
            allowedServices,
          },
        };
      }

      case "LocationSelected": {
        const locations = locationReferenceMapper.map(rule.criteria.locations, (unmatched) => {
          onItemError(rule.id, `Location "${unmatched.display}" not found`);
        });
        const allowedServices = serviceReferenceMapper.map(rule.criteria.allowedServices, (unmatched) => {
          onItemError(rule.id, `Service "${unmatched.display}" not found for allowed services`);
        });

        if (locations.length === 0 || allowedServices.length === 0) {
          onItemError(rule.id, "No valid location or allowed service", true);
          return null;
        }

        return {
          ...rule,
          limitToServices,
          criteria: {
            ...rule.criteria,
            locations,
            allowedServices,
          },
        };
      }

      case "ProgramLocation": {
        const programs = programReferenceMapper.map(rule.criteria.programs, (unmatched) => {
          onItemError(rule.id, `Program "${unmatched.display}" not found for programs`);
        });
        const allowedLocations = locationReferenceMapper.map(rule.criteria.allowedLocations, (unmatched) => {
          onItemError(rule.id, `Location "${unmatched.display}" not found for allowed locations`);
        });

        if (programs.length === 0 || allowedLocations.length === 0) {
          onItemError(rule.id, "No valid program or allowed location", true);
          return null;
        }

        return {
          ...rule,
          limitToServices,
          criteria: {
            ...rule.criteria,
            programs,
            allowedLocations,
          },
        };
      }

      case "SpanMidnight": {
        const excludingServices = serviceReferenceMapper.map(rule.criteria.excludingServices, (unmatched) => {
          onItemError(rule.id, `Service "${unmatched.display}" not found for excluding services`);
        });

        return {
          ...rule,
          limitToServices,
          criteria: {
            ...rule.criteria,
            excludingServices,
          },
        };
      }

      case "Authorization": {
        if (limitToServices.length === 0) {
          onItemError(rule.id, "No valid service");
          return null;
        }

        return { ...rule, limitToServices };
      }

      case "SessionDuration":
      case "ShowStatus":
      case "ServiceDate":
      case "MissingDiagnosis":
      case "AppointmentRequired":
      case "SessionOverlap":
      case "SessionDurationIncrement":
        return { ...rule, limitToServices };
    }
  }
}

function isSingleSetting(item: NoteRule): boolean {
  switch (item.criteria.type) {
    case "SpanMidnight":
    case "AppointmentRequired":
    case "SessionOverlap":
      return true;

    case "ProgramLocation":
    case "ProgramSelected":
    case "LocationSelected":
    case "SessionDuration":
    case "ShowStatus":
    case "ServiceDate":
    case "MissingDiagnosis":
    case "Authorization":
    case "SessionDurationIncrement":
      return false;
  }
}
