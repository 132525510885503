import { type LoginOAuthConfiguration, type Zone } from "@remhealth/host";
import { type MesaConfig, routes } from "@remhealth/mesa";
import { pathCombine } from "@remhealth/ui";

// Loaded from public/config.js
interface StaticConfig {
  AUTH_CLIENT_ID: string;
  URL_CONFIG: string;
  __APP_ZONE: Zone;
  __APP_VERSION?: string;
}

declare const config: Partial<StaticConfig>;

const mergedConfig: StaticConfig = {
  // Default values
  AUTH_CLIENT_ID: "int-mesa",
  URL_CONFIG: "https://config.remarkable.ai",
  __APP_ZONE: "int",

  // Overridden values
  ...config,
};

export interface Configuration extends MesaConfig {
  oauth: LoginOAuthConfiguration;
  zone: Zone;
  version: string | undefined;
}

interface DatabaseConfig {
  urls: {
    oauth: string;
    apollo: string;
    athena: string;
    ehr: string;
    language: string;
  };
}

export async function fetchConfig(): Promise<Configuration> {
  const zone = mergedConfig.__APP_ZONE;
  const databaseConfig = await fetchDatabaseConfig<DatabaseConfig>(zone);

  return {
    ...databaseConfig,
    urls: {
      ...databaseConfig.urls,
      config: mergedConfig.URL_CONFIG,
    },
    zone,
    version: mergedConfig.__APP_VERSION,
    oauth: {
      authority: databaseConfig.urls.oauth,
      clientId: mergedConfig.AUTH_CLIENT_ID,
      scope: "openid email offline_access remarkable.ai:core spell:check hephaestus:core search sync athena:query",
      redirectUri: routes.authRedirect,
      postLogoutRedirectUri: routes.root,
      customLogoutEndpoint: pathCombine(databaseConfig.urls.oauth, "/logout"),
      loginCallbackPath: "/auth",
      logoutCallbackPath: "/loggedout",
      additionalParameters: {
        "X-RH-ApplicationID": "mesa",
        "X-RH-ApplicationVersion": mergedConfig.__APP_VERSION ?? "",
      },
    },
  };
}

export async function fetchMesaConfig(zone: Zone): Promise<MesaConfig> {
  const databaseConfig = await fetchDatabaseConfig<DatabaseConfig>(zone);

  return {
    ...databaseConfig,
    zone,
    urls: {
      ...databaseConfig.urls,
      config: mergedConfig.URL_CONFIG,
    },
  };
}

async function fetchDatabaseConfig<T>(zone: Zone): Promise<T> {
  const search = new URLSearchParams();
  search.append("app", "mesa");
  search.append("clientId", mergedConfig.AUTH_CLIENT_ID);
  search.append("zone", zone);

  const response = await fetch(`${mergedConfig.URL_CONFIG}/api/config/public?` + search.toString(), {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-RH-ApplicationID": "mesa",
      "X-RH-ApplicationVersion": mergedConfig.__APP_VERSION ?? "",
    },
  });

  if (response.status !== 200) {
    throw new Error(`Unexpected status code ${response.status} when fetching config.`);
  }

  return await response.json() as T;
}
