import { useEffect, useState } from "react";
import { CareFabricConnection, Reference } from "@remhealth/apollo";
import { Button, Classes, NonIdealState } from "@remhealth/ui";

import { useRegistry } from "~/contexts";
import { DialogBody } from "./common.styles";
import { ConnDialog, ConnList, Data, Heading } from "./careFabricConnectionDialog.styles";

export interface CareFabricConnectionDialogProps {
  isOpen: boolean;
  connection: Reference<CareFabricConnection> | undefined;
  onClose: () => void;
}

export const CareFabricConnectionDialog = (props: CareFabricConnectionDialogProps) => {
  const { isOpen, connection, onClose } = props;

  const registry = useRegistry();
  const [conn, setConn] = useState<CareFabricConnection | undefined>(undefined);

  useEffect(() => {
    loadConnection();
  }, [connection]);

  return (
    <ConnDialog
      isOpen={isOpen}
      title="New CareFabric Connection"
      onClose={onClose}
    >
      <>
        <DialogBody className={Classes.DIALOG_BODY}>
          {!conn && (<NonIdealState description="Connection not found" />)}
          {conn && (
            <ConnList>
              <Heading>Url</Heading>
              <Data>{conn.sdkUrl}</Data>
              <Heading>Client Id</Heading>
              <Data>{conn.clientId}</Data>
              <Heading>Client Secret</Heading>
              <Data>{conn.clientSecret}</Data>
            </ConnList>
          )}
        </DialogBody>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent="primary"
              label="Close"
              onClick={() => onClose()}
            />
          </div>
        </div>
      </>
    </ConnDialog>
  );

  async function loadConnection(): Promise<void> {
    if (!connection) {
      return;
    }

    const conn = await registry.careFabricConnections.expand(connection);
    setConn(conn);
  }
};
