import React, { ReactElement, useState } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { IconName } from "@remhealth/icons";
import type { DataAttributes } from "~/types";
import { Button } from "./button";
import { Icon, IconProps } from "./icon";
import { TagProps, toNativeProps as tagToNativeProps } from "./tag";

export interface TagInputProps extends Omit<Core.TagInputProps, "addOnBlur" | "inputProps" | "leftIcon" | "tagProps"> {
  /** If set, an Add button will be rendered in the rightElement. */
  addButtonText?: string;

  leftIcon?: IconName | ReactElement<IconProps>;

  tagProps?: TagProps | ((value: React.ReactNode, index: number) => TagProps);

  inputProps?: Core.HTMLInputProps & DataAttributes;
}

export const TagInput = (props: TagInputProps) => {
  const { addButtonText, className, rightElement, separator, values, onAdd, onChange, onInputChange, ...restProps } = props;

  const [inputValue, setInputValue] = useState(props.inputValue ?? "");

  const tagInputProps = toNativeProps(restProps);

  return (
    <Core.TagInput
      className={classnames(className, {
        "none-selected": values.length === 0,
      })}
      rightElement={addButtonText && inputValue ? <Button square label={addButtonText} onClick={handleAddClick} /> : rightElement}
      {...tagInputProps}
      inputValue={inputValue}
      separator={separator}
      values={values}
      onAdd={handleAdd}
      onChange={onChange}
      onInputChange={handleInputChange}
    />
  );

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.currentTarget.value);
    onInputChange?.(event);
  }

  function handleAdd(values: string[], method: Core.TagInputAddMethod) {
    onAdd?.(values, method);
    setInputValue("");
  }

  function handleAddClick() {
    if (inputValue) {
      const newValues = (separator === false ? [inputValue] : inputValue.split(separator as string))
        .map(val => val.trim())
        .filter(val => val.length > 0);

      onAdd?.(newValues, "default");

      if (onChange) {
        onChange([...values, ...newValues]);
      }

      setInputValue("");
    }
  }
};

export function toNativeProps(props: Partial<TagInputProps>): Partial<Core.TagInputProps> {
  const { leftIcon, tagProps, ...restProps } = props;

  const nativeLeftIcon = leftIcon ? <Icon className={Core.Classes.TAG_INPUT_ICON} icon={leftIcon} /> : undefined;
  const nativeTagProps = typeof tagProps === "function"
    ? tagRenderer(tagProps)
    : tagProps ? tagToNativeProps(tagProps) : undefined;

  return {
    ...restProps,
    leftIcon: nativeLeftIcon,
    tagProps: nativeTagProps,
  };

  function tagRenderer(renderer: (value: React.ReactNode, index: number) => TagProps) {
    return (value: React.ReactNode, index: number): Core.TagProps => {
      return tagToNativeProps(renderer(value, index));
    };
  }
}
