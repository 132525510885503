import styled from "styled-components";
import { AsyncSuggest } from "@remhealth/ui";

export interface CareFabricItem {
  id: string;
  display: string;
}

export const CareFabricItemSuggest = styled(AsyncSuggest<CareFabricItem>)`
  width: 500px;
`;
