import { type DependencyList, useEffect } from "react";
import { useQuillEditor } from "./useQuillEditor";

export function useImperativeComposeHandle<T>(name: string, factory: () => T, depList?: DependencyList) {
  const editor = useQuillEditor();

  useEffect(() => {
    editor.setHandle(name, factory());

    return () => {
      editor.deleteHandle(name);
    };
  }, [name, editor, editor.hookVersion, ...depList ?? []]);
}
