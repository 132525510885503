import { ReactNode } from "react";
import classnames from "classnames";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { Classes } from "@blueprintjs/core";
import { Tag, TagProps } from "./tag";

export interface TagListProps<T extends ReactNode = ReactNode> {
  values: ReadonlyArray<T>;
  className?: string;
  disabled?: boolean;
  sortable?: boolean;
  removable?: boolean;
  tagProps?: TagProps | ((value: ReactNode, index: number) => TagProps);
  header?: JSX.Element;
  footer?: JSX.Element;
  onChange?: (values: T[]) => void;
  onSort?: (source: number, destination: number) => void;
  onRemove?: (value: T, index: number) => void;
}

export function TagList<T extends ReactNode = ReactNode>(props: TagListProps<T>) {
  const { className, disabled, header, footer, removable, sortable, values, tagProps, onChange, onSort, onRemove } = props;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="tag-list">
        {provided => (
          <div className={classnames(Classes.INPUT, Classes.TAG_INPUT, "vertical", "tag-list", className)}>
            <div className={Classes.TAG_INPUT_VALUES} {...provided.droppableProps} ref={provided.innerRef}>
              {header}
              {values.map(maybeRenderTag)}
              {provided.placeholder}
              {footer}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  function maybeRenderTag(tag: ReactNode, index: number) {
    if (!tag) {
      return null;
    }

    const props = typeof tagProps === "function" ? tagProps(tag, index) : tagProps;

    return (
      <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!sortable || disabled}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Tag
              key={tag + "__" + index}
              fill
              large
              minimal
              data-tag-index={index}
              {...props}
              className={classnames({
                dragging: snapshot.isDragging && !snapshot.isDropAnimating,
              })}
              onRemove={!removable || disabled || (snapshot.isDragging && !snapshot.isDropAnimating) ? undefined : () => handleRemoveTag(index)}
            >
              {tag}
            </Tag>
          </div>
        )}
      </Draggable>
    );
  }

  function handleRemoveTag(index: number) {
    onRemove?.(values[index], index);
    onChange?.(values.filter((_, i) => i !== index));
  }

  function handleDragEnd(result: DropResult) {
    const index = result.source.index;
    const item = values[index];

    if (result.destination) {
      if (onChange) {
        const destinationIndex = result.destination.index;
        if (destinationIndex !== index) {
          const newValues = [...values];
          newValues.splice(index, 1);
          newValues.splice(destinationIndex, 0, item);
          onChange(newValues);
        }
      }

      onSort?.(index, result.destination.index);
    } else if (onRemove) {
      onRemove(item, index);
    }
  }
}
