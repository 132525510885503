import { useState } from "react";
import { SortDirection, SortField, TextExpansionSortField, TextExpansionTemplate } from "@remhealth/apollo";
import {
  Alert,
  GridFeed,
  IconButton,
  InputGroup,
  NonIdealState,
  SortButton,
  useDebouncedState,
  useUpdateEffect
} from "@remhealth/ui";
import { useFeed } from "@remhealth/core";
import { useRegistry } from "~/contexts";
import { Text } from "~/text";
import { TextExpansionTemplateDialog } from "./textExpansionTemplateDialog";
import {
  ActionCell,
  ActionHeader,
  AddTextExpansion,
  Container,
  Grid,
  GridContent,
  Header,
  NameHeader,
  PageTitle,
  RowItem,
  SearchInputFormGroup,
  TextExpansionAction
} from "./manageTextExpansionTemplates.styles";

export const ManageTextExpansionTemplates = () => {
  const registry = useRegistry();

  const [searchText, setSearchText] = useDebouncedState<string>("", 300);
  const [selectedTemplate, setSelectedTemplate] = useState<TextExpansionTemplate>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [sortPredicate, setSortPredicate] = useState<SortField<TextExpansionSortField>>({
    field: TextExpansionSortField.Name,
    direction: SortDirection.Ascending,
  });

  const feed = useFeed(registry.textExpansionTemplates, {
    filters: [
      { name: { startsWithAllWords: searchText } },
    ],
    orderBy: sortPredicate,
  });

  // Reset if feed query changes
  useUpdateEffect(() => {
    feed.reset();
  }, [searchText, sortPredicate]);

  return (
    <Container>
      <PageTitle>{Text.TextExpansions}</PageTitle>
      <Header>
        <SearchInputFormGroup>
          <InputGroup
            clearable
            fill
            large
            soft
            defaultValue={searchText}
            placeholder={Text.SearchTextExpansions}
            type="search"
            onChange={setSearchText}
          />
        </SearchInputFormGroup>
        <AddTextExpansion large icon="plus" intent="primary" label={Text.AddNew} onClick={handleAddNew} />
      </Header>
      <Grid>
        <GridFeed<TextExpansionTemplate>
          allowWrap
          compact
          interactive
          stickyHeader
          feed={feed}
          headerRenderer={headerRenderer}
          noResults={renderNoRecords}
          pageKey="textExpansionTemplate"
          rowRenderer={rowRenderer}
        />
      </Grid>
      <TextExpansionTemplateDialog
        isOpen={showDialog}
        textExpansionTemplate={selectedTemplate}
        onClose={handleEditClose}
        onSave={handleEditSave}
      />
      <Alert
        canEscapeKeyCancel
        cancelButtonText={Text.No}
        confirmButtonText={Text.ConfirmDelete}
        intent="primary"
        isOpen={showDeleteConfirm}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <p>{Text.DeleteConfirmation(Text.TextExpansion.toLowerCase())}</p>
      </Alert>
    </Container>
  );

  function renderNoRecords() {
    return (
      <tr>
        <td colSpan={3}>
          <NonIdealState
            description={Text.NoResults(Text.TextExpansions.toLowerCase())}
            title={Text.NoResultTitle(Text.TextExpansion)}
          />
        </td>
      </tr>
    );
  }

  function headerRenderer() {
    const { direction, field } = sortPredicate;
    return (
      <tr>
        <NameHeader>
          <SortButton
            label={Text.TextExpansionName}
            sort={field === TextExpansionSortField.Name ? direction : "Unspecified"}
            onClick={handleSortByName}
          />
        </NameHeader>
        <th>{Text.FullText}</th>
        <ActionHeader />
      </tr>
    );
  }

  function rowRenderer(textExpansionTemplate: TextExpansionTemplate) {
    return (
      <RowItem key={textExpansionTemplate.id} onClick={() => handleEdit(textExpansionTemplate)}>
        <td>{textExpansionTemplate.name}</td>
        <GridContent>{textExpansionTemplate.expansion}</GridContent>
        <ActionCell>
          <TextExpansionAction className="TextExpansion-action">
            <IconButton
              elevated
              aria-label={Text.Delete}
              icon="trash"
              onClick={e => handleDelete(e, textExpansionTemplate)}
            />
          </TextExpansionAction>
        </ActionCell>
      </RowItem>
    );
  }

  function handleAddNew() {
    setShowDialog(true);
    setSelectedTemplate(undefined);
  }

  function handleEditClose() {
    setSelectedTemplate(undefined);
    setShowDialog(false);
  }

  function handleEdit(template: TextExpansionTemplate) {
    setSelectedTemplate(template);
    setShowDialog(true);
  }

  function handleDelete(e: React.MouseEvent, template: TextExpansionTemplate) {
    e.stopPropagation();
    setSelectedTemplate(template);
    setShowDeleteConfirm(true);
  }

  function handleDeleteCancel() {
    setSelectedTemplate(undefined);
    setShowDeleteConfirm(false);
  }

  async function handleDeleteConfirm() {
    if (selectedTemplate) {
      await registry.textExpansionTemplates.deleteById(selectedTemplate.id);
      feed.reset();
    }
    setShowDeleteConfirm(false);
    setSelectedTemplate(undefined);
  }

  function handleSortByName() {
    handleSortBy(TextExpansionSortField.Name);
  }

  function handleSortBy(field: TextExpansionSortField) {
    let direction: SortDirection = SortDirection.Ascending;
    if (sortPredicate.field === field) {
      direction = getReverseSortDirection(sortPredicate.direction);
    }
    setSortPredicate({ field, direction });
  }

  function getReverseSortDirection(sortDirection?: SortDirection) {
    return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
  }

  function handleEditSave() {
    feed.reset();
    setSelectedTemplate(undefined);
    setShowDialog(false);
  }
};
