import { Envelope } from "@remhealth/icons";
import { ContactPoint, IStore, Practitioner, PractitionersClient } from "@remhealth/apollo";
import { Button, Classes, Dialog, FormGroup, InputGroup } from "@remhealth/ui";
import { Yup } from "~/validation";
import { Form } from "~/forms";

import { CaptureEmailSubmitContainer } from "./captureEmail.styles";

export interface CaptureEmailProps {
  store: IStore<PractitionersClient>;
  user: Practitioner;
  isOpen: boolean;
  captureWorkEmail: boolean;
  onClose: () => void;
}

export interface CaptureEmailForm {
  email: string;
}

const schema = Yup.object<CaptureEmailForm>({
  email: Yup.string()
    .email()
    .label("Email")
    .required(),
});

export function CaptureEmail(props: CaptureEmailProps) {
  const { store, user, isOpen, captureWorkEmail, onClose } = props;

  return (
    <Dialog isOpen={isOpen} title="Add Email" onClose={onClose}>
      <Form<CaptureEmailForm>
        initialValues={{
          email: "",
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {form => {
          const { email: emailField } = form.fields;
          return (
            <div className={Classes.DIALOG_BODY}>
              <FormGroup field={emailField} label="Email">
                <InputGroup large field={emailField} leftIcon={<Envelope />} type="email" />
              </FormGroup>
              <CaptureEmailSubmitContainer>
                <Button
                  disabled={!form.isValid}
                  intent="primary"
                  label="Add Email"
                  loading={form.isSubmitting}
                  onClick={() => form.submitForm()}
                />
              </CaptureEmailSubmitContainer>
            </div>
          );
        }}
      </Form>
    </Dialog>
  );

  function handleSubmit(values: CaptureEmailForm) {
    const { email } = values;

    const newEmail: ContactPoint = {
      system: "Email",
      use: captureWorkEmail ? "Work" : "Temp",
      value: email,
      preferred: true,
    };

    const updatedTelecoms: ContactPoint[] = [...user.telecoms, newEmail];

    user.telecoms = updatedTelecoms;

    store.upsert(user);
    onClose();
  }
}
