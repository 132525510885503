import React, { useEffect, useState } from "react";
import { sortBy } from "lodash-es";
import { DateTime } from "luxon";
import { AutomaticUpdates, Comment, Download, Edit, GitPull, Refresh, Star, StarEmpty } from "@remhealth/icons";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  DateFormats,
  Icon,
  IconButton,
  NonIdealState,
  PagingGrid,
  PagingResult,
  Spinner,
  Tab,
  TabId,
  Tabs,
  Tooltip,
  useAbort,
  usePagingController
} from "@remhealth/ui";
import { useAccessToken } from "@remhealth/host";
import { routes } from "~/app/routes";
import { MesaConfig } from "~/config";
import { Artifact, ArtifactBinding, Build, ConfigService } from "./configService";
import { EditBuildDialog } from "./editBuildDialog";
import {
  ActionCell,
  ArtifactBar,
  BuildDisplayCell,
  ChannelBuild,
  ChannelNameCell,
  CommitCell,
  Grid,
  Main,
  Notes,
  SourceBar,
  StatusCell
} from "./builds.styles";

export const buildRoutes = {
  root: routes.builds,
} as const;

export interface BuildsProps {
  config: MesaConfig;
}

const star = <Star color="var(--orange4)" />;
const desktopRepo = "https://github.com/remarkable-health/remhealth";
const mobileRepo = "https://github.com/remarkable-health/bells-mobile";

export const Builds = (props: BuildsProps) => {
  const { config } = props;

  const token = useAccessToken();
  const controller = usePagingController<Build>();
  const abort = useAbort();
  const service = new ConfigService(config.urls.config);

  const canEditStatus = token.allClaims("rh.staff.role").includes("sysadmin");

  const [repo, setRepo] = useState<string>();
  const [artifact, setArtifact] = useState<Artifact>();
  const [binding, setBinding] = useState<ArtifactBinding>();
  const [editing, setEditing] = useState<Build>();

  useEffect(() => {
    handleTabChange("bells-desktop");
  }, []);

  useEffect(() => {
    controller.reset();
  }, [artifact?.id, binding?.platform, binding?.arch]);

  const bindings = artifact ? sortBy(artifact.bindings, b => b.order) : [];
  const channels = binding ? sortBy(binding.channels, c => c.order) : [];

  return (
    <Main>
      <Tabs large id="builds" selectedTabId={artifact?.id} onChange={handleTabChange}>
        <Tab id="bells-desktop" title="Bells Desktop" />
        <Tab id="bells-mobile" title="Bells Mobile" />
        <Tab id="db/ctvc" title="CTVocabulary" />
        <Tab id="db/ctlx" title="CTLX Drug Database" />
        <Tab id="db/ctmx" title="Micromedex Drug Database" />
      </Tabs>
      <ArtifactBar>
        {binding && bindings.length > 1 && (
          <SourceBar>
            <ButtonGroup toggles>
              {bindings.map(b => (
                <React.Fragment key={`${b.platform}-${b.arch}`}>
                  {button(`${binding.platform}|${binding.arch}`, `${b.platform}|${b.arch}`, `${b.platform} (${b.arch})`, () => setBinding(b))}
                </React.Fragment>
              ))}
            </ButtonGroup>
          </SourceBar>
        )}
      </ArtifactBar>
      <Grid>
        <PagingGrid<Build>
          compact
          fill
          stickyHeader
          controller={controller}
          defaultPageSize={10}
          headerRenderer={headerRenderer}
          noResults={renderNoRecords}
          pageKey="buildsV2"
          pageSizeChoices={[10, 20, 50]}
          paginationChildren={<Button minimal icon={<Refresh />} label="Refresh" onClick={() => controller.reset()} />}
          preload={1}
          rowRenderer={rowRenderer}
          onLoadMore={handleLoadMore}
        />
      </Grid>
      <EditBuildDialog build={editing} config={config} onClose={handleEditClose} onSaveComplete={handleSaveComplete} />
    </Main>
  );

  async function handleTabChange(tabId: TabId) {
    switch (tabId) {
      case "bells-desktop":
        setRepo(desktopRepo);
        break;
      case "bells-mobile":
        setRepo(mobileRepo);
        break;
      default:
        setRepo(undefined);
        break;
    }

    await fetchArtifact(tabId as string);
  }

  async function fetchArtifact(artifactId: string) {
    setArtifact(undefined);

    const freshArtifact = await service.getArtifact(token.accessToken, artifactId, abort.signal);

    if (freshArtifact) {
      setArtifact(freshArtifact);

      const freshBinding = freshArtifact.id === artifact?.id && binding
        ? freshArtifact.bindings.find(b => b.arch === binding.arch && b.platform === binding.platform)
        : null;

      setBinding(freshBinding ?? freshArtifact.bindings[0]);
    }
  }

  function button<T>(actual: T | undefined, expected: T, label: string, onClick: () => void) {
    return <Button active={expected === actual} label={label} onClick={onClick} />;
  }

  function headerRenderer() {
    return (
      <tr>
        <th>Version</th>
        <th>Built Time</th>
        {repo && <th>Commit</th>}
        <th>Status</th>
        {channels.map(channel => (
          <ChannelNameCell key={channel.key}>
            <Tooltip className="follow" content={channel.isBound ? "Using explicit build" : channel.follow ? "Following latest build" : "Not following latest build"}>
              <IconButton
                minimal
                aria-label="Edit Status"
                disabled={!canEditStatus}
                icon={<AutomaticUpdates />}
                intent={!channel.isBound && channel.follow ? "success" : "none"}
                onClick={() => setFollow(channel.key, channel.isBound ? true : !channel.follow)}
              />
            </Tooltip>
            <span>
              {channel.storeLink
                ? <a href={channel.storeLink} rel="noopener noreferrer" target="_blank">{channel.name}</a>
                : channel.name}
            </span>
          </ChannelNameCell>
        ))}
        <th />
      </tr>
    );
  }

  function rowRenderer(build: Build) {
    const releaseNotes = (
      <>
        <h5>Release Notes</h5>
        <p>{build.releaseNotes}</p>
      </>
    );

    const retirementNotes = (
      <>
        <h5>Retirement Notes</h5>
        <p>{build.retirementNotes}</p>
      </>
    );

    const notes = (
      <Notes>
        {build.releaseNotes && releaseNotes}
        {build.retirementNotes && retirementNotes}
      </Notes>
    );

    return (
      <tr key={build.id}>
        <BuildDisplayCell>
          <span className="display">{build.display}</span>
          {build.buildNumber && (
            <span className="build"> ({build.buildNumber})</span>
          )}
        </BuildDisplayCell>
        <td>{DateFormats.friendlyDateTime(DateTime.fromISO(build.created, { zone: "UTC" }).toLocal())}</td>
        {repo && (
          <CommitCell>
            {build.commit && <a className="commit" href={`${repo}/commit/${build.commit}`} rel="noopener noreferrer" target="_blank">{build.commit.slice(0, 7)}</a>}
            {renderPrevBuild(build)}
          </CommitCell>
        )}
        <StatusCell>
          {build.status}
          {(build.releaseNotes || build.retirementNotes) && (
            <Tooltip content={notes}>
              <Icon icon={<Comment />} />
            </Tooltip>
          )}
        </StatusCell>
        {artifact && binding && channels.map(channel => (
          <ChannelBuild key={channel.key}>
            {channel.boundBuildGuid === build.id
              ? <IconButton minimal aria-label="Make Current" icon={star} onClick={() => makeCurrent(artifact, binding, channel.key, null)} />
              : <IconButton minimal aria-label="Make Current" icon={<StarEmpty />} onClick={() => makeCurrent(artifact, binding, channel.key, build)} />}
          </ChannelBuild>
        ))}
        <ActionCell>
          {canEditStatus && <IconButton aria-label="Edit Status" icon={<Edit />} onClick={() => setEditing(build)} />}
          {build.downloadUrl
            ? <AnchorButton href={build.downloadUrl} icon={<Download />} label="Download" />
            : <AnchorButton disabled label="No download found" />}
        </ActionCell>
      </tr>
    );
  }

  function renderPrevBuild(build: Build) {
    const index = controller.items.findIndex(i => i.id === build.id);
    const prevBuild = index !== -1
      ? controller.items[index + 1]
      : undefined;

    if (!prevBuild?.commit || !repo) {
      return undefined;
    }

    const href = `${repo}/compare/${prevBuild.commit}...${build.commit}`;

    return (
      <IconButton
        minimal
        small
        tooltip
        aria-label={`Diff from ${prevBuild.display} (${prevBuild.buildNumber})`}
        className="compare"
        href={href}
        icon={<GitPull />}
        iconSize={12}
        rel="noopener noreferrer"
        target="_blank"
      />
    );
  }

  function renderNoRecords() {
    return (
      <tr>
        <td colSpan={4 + channels.length}>
          {!artifact ? <Spinner size={60} /> : <NonIdealState description="No builds found." title="No results." />}
        </td>
      </tr>
    );
  }

  async function handleLoadMore(startIndex: number, limit: number, abort: AbortSignal): Promise<PagingResult<Build>> {
    if (!artifact || !binding) {
      return { items: [], hasMore: false };
    }
    const response = await service.queryBuilds(token.accessToken, artifact.id, binding.platform, binding.arch, startIndex, limit, abort);
    return { items: response.results, hasMore: !!response.totalCount && response.totalCount > (response.results.length + startIndex) };
  }

  async function makeCurrent(artifact: Artifact, binding: ArtifactBinding, channel: string, build: Build | null) {
    await service.setChannelBuild(token.accessToken, {
      arch: binding.arch,
      artifact: artifact.id,
      channel,
      platform: binding.platform,
      buildGuid: build?.id ?? null,
    });

    await service.setChannelFollow(token.accessToken, {
      arch: binding.arch,
      artifact: artifact.id,
      channel,
      platform: binding.platform,
      follow: false,
    });

    await fetchArtifact(artifact.id);
  }

  async function setFollow(channel: string, follow: boolean) {
    if (!artifact || !binding) {
      return;
    }

    if (follow) {
      await service.setChannelBuild(token.accessToken, {
        arch: binding.arch,
        artifact: artifact.id,
        channel,
        platform: binding.platform,
        buildGuid: null,
      });
    }

    await service.setChannelFollow(token.accessToken, {
      arch: binding.arch,
      artifact: artifact.id,
      channel,
      platform: binding.platform,
      follow,
    });

    await fetchArtifact(artifact.id);
  }

  function handleSaveComplete(build: Build) {
    const itemIndex = controller.items.findIndex(b => b.id === build.id);
    if (itemIndex !== -1) {
      const items = [...controller.items];
      items.splice(itemIndex, 1, build);
      controller.setItems(items);
    }
  }

  function handleEditClose() {
    setEditing(undefined);
  }
};
