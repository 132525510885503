import type { Intent } from "@blueprintjs/core";
import styled, { css } from "styled-components";

export const UnitCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .bp5-icon {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity 100ms var(--pt-transition-ease);
  }

  .bp5-icon:first-child {
    top: -2px;
  }

  .bp5-icon:last-child {
    bottom: -2px;
  }

  &:hover:not(.bp5-disabled) {
    .bp5-icon {
      opacity: 0.5;
    }
  }
`;

export interface UnitInputProps {
  $intent: Intent;
  $width: number;
  $toggle: boolean;
}

export const UnitInput = styled.input<UnitInputProps>`
  background: transparent;
  border: 0;
  border-radius: var(--pt-border-radius);
  outline: 0;
  padding: 0;
  color: ${props => props.theme.font.default};
  font-size: inherit;
  text-align: center;
  width: ${props => props.$width}px;
  height: 100%;

  transition-timing-function: var(--pt-transition-ease);
  transition-duration: 100ms;
  transition-property: box-shadow;

  ${props => props.$toggle && css`
    cursor: pointer;
  `};

  &.bp5-disabled {
    color: ${props => props.theme.font.disabled};
  }

  &.bp5-large {
    width: ${props => props.$width * 1.2}px;
  }

  ${props => props.$intent !== "none" && css`
    box-shadow: ${props.theme.input.border[props.$intent]};
  `};

  &:focus {
    box-shadow: ${props => props.theme.input.border.focus};
  }

  &:hover:not(:focus):not(.bp5-disabled) {
    background-color: ${props => props.theme.dark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.06)"};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.placeholder.default};
    font-size: var(--font-size-normal);
    opacity: 1;
  }
`;

export interface DialInputGroupProps {
  $intent: Intent;
  $empty: boolean;
}

export const DialInputGroup = styled.div<DialInputGroupProps>`
  display: inline-flex;
  align-items: center;
  box-shadow: ${props => props.theme.input.border.default};
  background: ${props => props.theme.input.background.default};
  border-radius: var(--pt-border-radius);

  padding: 0 1px;
  color: ${props => props.$empty ? props.theme.placeholder.default : props.theme.font.default};

  font-size: var(--font-size-normal);
  height: ${props => props.theme.gridSize * 3}px;

  ${props => props.$intent !== "none" && css`
    box-shadow: ${props.theme.input.border[props.$intent]};
  `};

  &.bp5-disabled {
    box-shadow: ${props => props.theme.input.border.disabled};
    background: ${props => props.theme.input.background.disabled};
    color: ${props => props.theme.font.disabled};
  }

  &.bp5-large {
    font-size: var(--font-size-large);
    height: ${props => props.theme.gridSize * 4}px;
  }

  &.bp5-fill {
    width: 100%;
    flex: 1 1 auto;
  }

  > .bp5-icon {
    margin: 0 5px;
    color: ${props => props.$intent === "none" ? props.theme.icon.default : props.theme.font.intents[props.$intent]};
  }

  > .bp5-icon:first-child {
    margin-left: 8px;
  }

  &.bp5-large > .bp5-icon:first-child {
    margin-left: 10px;
  }

  > .bp5-button {
    min-width: 24px;
    min-height: 24px;
    margin: 2px;
    padding: 0 10px;
  }

  &.bp5-large > .bp5-button {
    min-width: 30px;
    min-height: 30px;
    margin: 4px;
  }
`;
