import { IAsyncDatabase, IAsyncStorage, PartitionResult, StorageRecord } from "@remhealth/apollo";

class IndexedDbObjectStorage<T extends StorageRecord> implements IAsyncStorage<T> {
  private readonly database: IDBDatabase;
  private readonly partition: string;

  constructor(database: IDBDatabase, partition: string) {
    this.database = database;
    this.partition = partition;
  }

  public getAll(): Promise<Map<string, T>> {
    const map = new Map<string, T>();
    return new Promise<Map<string, T>>((resolve, reject) => {
      const cursor = this.getStore().openCursor();
      cursor.addEventListener("error", event => reject(event));
      cursor.addEventListener("success", () => {
        if (!cursor.result) {
          resolve(map);
        } else {
          map.set(cursor.result.primaryKey.toString(), cursor.result.value);
          cursor.result.continue();
        }
      });
    });
  }

  public getItem(key: string): Promise<T | null> {
    return new Promise<T | null>((resolve, reject) => {
      const request = this.getStore().get(key);
      request.addEventListener("error", event => reject(event));
      request.addEventListener("success", () => {
        resolve(request.result ?? null);
      });
    });
  }

  public setItem(key: string, value: T): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = this.getStore(true).put(value, key);
      request.addEventListener("error", event => reject(event));
      request.addEventListener("success", () => resolve());
    });
  }

  public removeItem(key: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = this.getStore(true).delete(key);
      request.addEventListener("error", event => reject(event));
      request.addEventListener("success", () => resolve());
    });
  }

  public clear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = this.getStore(true).clear();
      request.addEventListener("error", event => reject(event));
      request.addEventListener("success", () => resolve());
    });
  }

  private getStore(allowWrite = false): IDBObjectStore {
    return this.database.transaction(this.partition, allowWrite ? "readwrite" : "readonly").objectStore(this.partition);
  }
}

/**
 * An async storage using an IndexedDB instance as the backing storage.
 */
export class IndexedDbAsyncStorageFactory implements IAsyncDatabase {
  private readonly databaseName: string;

  constructor(databaseName: string) {
    this.databaseName = databaseName;
  }

  public async create<TPartitions extends string>(partitions: TPartitions[], storageVersion: number): Promise<PartitionResult<TPartitions>> {
    const lastStorageVersion = localStorage.getItem(`${this.databaseName}-storageVersion`);
    const forceNew = lastStorageVersion !== null && Number.parseInt(lastStorageVersion) < storageVersion;

    return new Promise<PartitionResult<TPartitions>>((resolve, reject) => {
      const openRequest = window.indexedDB.open(this.databaseName);

      openRequest.addEventListener("error", event => reject(event));
      openRequest.addEventListener("success", async () => {
        const database = openRequest.result;

        // If stores are missing, drop database and recreate it
        if (forceNew || !allStoresExist(database, partitions)) {
          const currentVersion = database.version;
          database.close();
          await deleteDatabase(this.databaseName);
          const newDatabase = await createDatabase(this.databaseName, currentVersion + 1, partitions);
          resolve(createStores(newDatabase, partitions));
        } else {
          resolve(createStores(database, partitions));
        }

        localStorage.setItem(`${this.databaseName}-storageVersion`, storageVersion.toString());
      });
    });
  }
}

function createStores<TPartitions extends string>(database: IDBDatabase, partitions: string[]): PartitionResult<TPartitions> {
  const result: any = {};
  partitions.forEach(partition => {
    result[partition] = new IndexedDbObjectStorage(database, partition);
  });
  return result;
}

async function createDatabase(databaseName: string, version: number, partitions: string[]): Promise<IDBDatabase> {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = window.indexedDB.open(databaseName, version);
    request.addEventListener("error", event => reject(event));

    request.addEventListener("upgradeneeded", () => {
      const database = request.result;
      partitions.forEach(partition => {
        if (!database.objectStoreNames.contains(partition)) {
          database.createObjectStore(partition);
        }
      });
    });

    request.addEventListener("success", () => resolve(request.result));
  });
}

async function deleteDatabase(databaseName: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const request = window.indexedDB.deleteDatabase(databaseName);
    request.addEventListener("error", event => reject(event));
    request.addEventListener("success", () => resolve());
  });
}

function allStoresExist(database: IDBDatabase, partitions: string[]): boolean {
  return partitions.every(partition => database.objectStoreNames.contains(partition));
}
