import { routes } from "~/app/routes";

export const operationRoutes = {
  root: routes.operations,
  tab: `${routes.operations}/:tab`,
  practice: `${routes.operations}/:tab/:networkId/:practiceTab`,
} as const;

export const practiceAreaTabs = {
  practice: "Details",
  import: "Data Import",
  users: "Users",
} as const;

export type PracticeAreaTabId = keyof typeof practiceAreaTabs;
