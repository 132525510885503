import {
  IFeedIterator,
  Identifier,
  type Practice,
  type PracticeClients,
  Reference,
  ResourceFilterSet,
  ResourceTypes,
  type ResponseOptions,
  groupBy,
  isReference,
  parseBells,
  parseCareFabric,
  parseCtOne,
  parseResourceSystem,
  systems
} from "@remhealth/apollo";

import { createReference, createVersionedReference } from "@remhealth/host";

type ResourceClientResourceType<K extends keyof PracticeClients> = Awaited<ReturnType<PracticeClients[K]["fetchById"]>>;

export interface ReferenceMapper<T extends ResourceTypes> {
  map<K extends Reference<T> | T>(source: K): K | undefined;
  map<K extends Reference<T> | T>(source: K[], unmatchHandler: (unmatchedSource: K) => void): K[];
  map<K extends Reference<T> | T>(source: K, versionedReference: true): Reference<T> | undefined;
  map<K extends Reference<T> | T>(source: K[], versionedReference: true, unmatchedHandler: (unmatchedSource: K) => void): Reference<T>[];
}

export interface ReferenceMapperFactory {
  createMapperById<K extends keyof PracticeClients, T extends ResourceClientResourceType<K>>(
    resourceType: K,
    source: (Reference<T> | T)[],
    abort: AbortSignal
  ): Promise<ReferenceMapper<ResourceClientResourceType<K>>>;

  createMapperByIdentifier<K extends keyof PracticeClients, T extends Awaited<ReturnType<PracticeClients[K]["fetchById"]>>>(
    resourceType: K,
    source: (Reference<T> | T)[],
    abort: AbortSignal
  ): Promise<ReferenceMapper<ResourceClientResourceType<K>>>;
}

interface ReferenceClient<T extends ResourceTypes> {
  feed(request: { filters: ResourceFilterSet[]; responseOptions?: ResponseOptions }): IFeedIterator<T>;
}

export function createReferenceMapperFactory(sourceClient: PracticeClients, targetClient: PracticeClients, targetPractice: Practice): ReferenceMapperFactory {
  return {
    async createMapperById(resourceType, source, abort) {
      return await createReferenceMapperById(targetClient[resourceType] as ReferenceClient<ResourceTypes>, source, abort);
    },
    async createMapperByIdentifier(resourceType, source, abort) {
      return await createReferenceMapperByIdentifier(sourceClient[resourceType] as ReferenceClient<ResourceTypes>, targetClient[resourceType] as ReferenceClient<ResourceTypes>, targetPractice.networkId, source, abort);
    },
  } as ReferenceMapperFactory;
}

async function createReferenceMapperById(
  targetClient: ReferenceClient<ResourceTypes>,
  source: (Reference<ResourceTypes> | ResourceTypes)[],
  abort: AbortSignal
): Promise<ReferenceMapper<ResourceTypes>> {
  const targetMap = new Map<string, ResourceTypes>(); // Map by ID
  const pageSize = 100;

  const list = [...new Set(source.map(i => i.id))];
  for (let i = 0; i < list.length; i += pageSize) {
    const page = list.slice(i, i + pageSize);
    const targetItems = await targetClient.feed({
      filters: [{ ids: page, includeDeleted: true }],
    }).all({ abort });

    for (const targetItem of targetItems) {
      targetMap.set(targetItem.id, targetItem);
    }
  }

  return { map: createMap(mapSingle) };

  function mapSingle(sourceReference: Reference<ResourceTypes> | ResourceTypes, versionedReference: boolean): Reference<ResourceTypes> | ResourceTypes | undefined {
    const match = targetMap.get(sourceReference.id);
    if (match) {
      return isReference(sourceReference)
        ? versionedReference ? createVersionedReference(match) : createReference(match)
        : match;
    }

    return undefined;
  }
}

async function createReferenceMapperByIdentifier(
  sourceClient: ReferenceClient<ResourceTypes>,
  targetClient: ReferenceClient<ResourceTypes>,
  targetNetworkId: string,
  source: (Reference<ResourceTypes> | ResourceTypes)[],
  abort: AbortSignal
): Promise<ReferenceMapper<ResourceTypes>> {
  const sourceMap = new Map<string, ResourceTypes>(); // Map by ID
  const targetMap = new Map<string, ResourceTypes>(); // Map by identifier

  const referenceIds = new Set<string>();
  for (const item of source) {
    if (!isReference(item)) {
      if (!sourceMap.has(item.id)) {
        sourceMap.set(item.id, item);
      }
    } else {
      referenceIds.add(item.id);
    }
  }

  const pageSize = 100;

  const list = [...referenceIds];
  for (let i = 0; i < list.length; i += pageSize) {
    const page = list.slice(i, i + pageSize);
    const newSourceItems = await sourceClient.feed({
      filters: [{ ids: page, includeDeleted: true }],
    }).all({ abort });

    for (const sourceItem of newSourceItems) {
      sourceMap.set(sourceItem.id, sourceItem);
    }
  }

  const sourceItems = [...sourceMap.values()];

  for (let i = 0; i < sourceItems.length; i += pageSize) {
    const page = sourceItems.slice(i, i + pageSize);
    const identifiers = page.flatMap(i => i.identifiers ?? []);
    if (identifiers.length) {
      const targetItems = await targetClient.feed({
        filters: getIdentifierFilters(identifiers, targetNetworkId),
      }).all({ abort });

      for (const targetItem of targetItems) {
        if (targetItem.identifiers?.length) {
          const identiferKey = getKey(targetItem.identifiers);
          targetMap.set(identiferKey, targetItem);
        }
      }
    }
  }

  return { map: createMap(mapSingle) };

  function mapSingle(sourceReference: Reference<ResourceTypes> | ResourceTypes, versionedReference: boolean): Reference<ResourceTypes> | ResourceTypes | undefined {
    const source = sourceMap.get(sourceReference.id);
    if (!source) {
      return undefined;
    }

    if (source.identifiers?.length) {
      const identiferKey = getKey(source.identifiers);
      const match = targetMap.get(identiferKey);
      if (match) {
        return isReference(sourceReference)
          ? versionedReference ? createVersionedReference(match) : createReference(match)
          : match;
      }
    }

    return undefined;
  }
}

function getKey(identifiers: Identifier[]) {
  return identifiers.map(getIdentifierKey).sort().join("+").toLowerCase();
}

function getIdentifierKey(identifier: Identifier): string {
  const system = identifier.system ? getUniversalSystem(identifier.system) : undefined;
  return `${system ?? ""}|${identifier.value}`;
}

// Exclude networkID from resource identifiers, to ensure a match across different networkIDs
function getUniversalSystem(system: string): string {
  // For example, `carefabric://rhmobdev1/StaffMember` -> `StaffMember`
  const ehrSystem = parseResourceSystem(system);
  if (ehrSystem) {
    return ehrSystem[1]; // ResourceType | EntityType
  }
  return system;
}

function createMap<T extends ResourceTypes>(mapSingle: (sourceReference: Reference<T> | T, versionedReference: boolean ) => Reference<T> | T | undefined) {
  return map;

  function map(sourceReference: Reference<T> | T): Reference<T> | T | undefined;
  function map(sourceReferences: (Reference<T> | T)[], unmatchedHandler: (unmatchedSource: Reference<T> | T) => void): (Reference<T> | T)[];
  function map(sourceReferences: Reference<T> | T, versionedReference: boolean): Reference<T> | undefined;
  function map(sourceReferences: (Reference<T> | T)[], versionedReference: boolean, unmatchedHandler: (unmatchedSource: Reference<T> | T) => void): Reference<T>[];

  function map(
    arg1: Reference<T> | T | (Reference<T> | T)[],
    arg2?: ((unmatchedSource: Reference<T> | T) => void) | boolean,
    arg3?: ((unmatchedSource: Reference<T> | T) => void)
  ): Reference<T> | T | (Reference<T> |T)[] | Reference<T>[] | undefined {
    const hasFlag = typeof arg2 === "boolean";
    if (Array.isArray(arg1)) {
      return hasFlag ? mapArray(arg1, arg2, arg3!) : mapArray(arg1, false, arg2!);
    }
    return hasFlag ? mapSingle(arg1, arg2) : mapSingle(arg1, false);
  }

  function mapArray(
    sourceReferences: (Reference<T> | T)[],
    versionedReference: boolean,
    unmatchedHandler: ((unmatchedSource: Reference<T> | T) => void)
  ): (Reference<T> | T)[] | Reference<T>[] {
    const matched: (Reference<T> | T)[] = [];

    for (const sourceReference of sourceReferences) {
      const match = mapSingle(sourceReference, versionedReference);
      if (match) {
        matched.push(match);
      } else {
        unmatchedHandler(sourceReference);
      }
    }

    return matched;
  }
}

function getIdentifierFilters(identifiers: Identifier[], networkId: string): ResourceFilterSet[] {
  const systemGroups = groupBy(identifiers, i => i.system);
  const filterSets: ResourceFilterSet[] = [];

  for (const [system, identifiers] of systemGroups) {
    if (system) {
      filterSets.push({
        identifier: {
          system: convertSystem(system, networkId),
          equalsAny: identifiers.map(i => i.value),
          ignoreCase: true,
        },
      });
    }
  }

  return filterSets;
}

// If system is an EHR system, convert it to match the desired networkId
function convertSystem(system: string, desiredNetworkId: string): string {
  const carefabricSystem = parseCareFabric(system);
  if (carefabricSystem) {
    return systems.careFabric(desiredNetworkId, carefabricSystem[1]);
  }
  const ctOneSystem = parseCtOne(system);
  if (ctOneSystem) {
    return systems.ctOne(desiredNetworkId, ctOneSystem[1]);
  }
  const bellsSystem = parseBells(system);
  if (bellsSystem) {
    return systems.bells(desiredNetworkId, bellsSystem[1]);
  }
  return system;
}
