import { PropsWithChildren } from "react";
import { Edit } from "@remhealth/icons";
import { SpeechRuleTemplate } from "@remhealth/apollo";
import { Button, Classes, Popover, Tag } from "@remhealth/ui";
import { RuleSummary } from "@remhealth/core";
import { AddContainer, Overview, RuleKindContainer, TagList } from "./ruleOverview.styles";

interface Rule<TRule> extends Omit<SpeechRuleTemplate, "rule"> {
  rule: TRule;
}

export interface RuleListProps<TRule extends SpeechRuleTemplate["rule"]> {
  rules: Rule<TRule>[];
  noResults: JSX.Element | string;
  rulesEqual: (a: TRule, b: TRule) => boolean;
  ruleRenderer: (item: TRule) => JSX.Element | string;
  onAdd?: () => void;
  onEdit?: (rule: Rule<TRule>) => void;
}

export function RuleList<TRule extends SpeechRuleTemplate["rule"]>(props: PropsWithChildren<RuleListProps<TRule>>) {
  const {
    children,
    rulesEqual,
    ruleRenderer,
    noResults,
    rules,
    onAdd,
    onEdit,
  } = props;

  return (
    <RuleKindContainer>
      <Overview>
        {children}
      </Overview>
      <TagList>
        <AddContainer>
          <Button minimal icon="plus" intent="primary" label="Add New" onClick={onAdd} />
        </AddContainer>
        {rules.length === 0 && (
          <p>{noResults}</p>
        )}
        {rules.map((r, index) => (
          <Popover key={r.id} content={<RuleSummary rule={r.rule} />} hoverCloseDelay={0} hoverOpenDelay={500} interactionKind="hover" placement="top">
            <Tag interactive large minimal round rightIcon={<Edit />} onClick={() => onEdit?.(r)}>
              {ruleRenderer(r.rule)}
              {getDuplicateIndex(index)}
            </Tag>
          </Popover>
        ))}
      </TagList>
    </RuleKindContainer>
  );

  function getDuplicateIndex(index: number) {
    if (index > 0) {
      const current = rules[index];
      const matches = rules.slice(0, index).filter(other => rulesEqual(current.rule, other.rule)).length;
      if (matches > 0) {
        return <span className={Classes.TEXT_MUTED}> ({matches + 1})</span>;
      }
    }

    return null;
  }
}

export function RuleOverview(props: PropsWithChildren<{}>) {
  const { children } = props;

  return (
    <RuleKindContainer>
      <Overview>
        {children}
      </Overview>
    </RuleKindContainer>
  );
}
