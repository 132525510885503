import { SpeechRuleCategory } from "@remhealth/apollo";
import { Text } from "~/text";

export const speechRuleCategoryText: Record<SpeechRuleCategory, string> = {
  AnyService: Text.AnyService,
  AttendantCare: Text.AttendantCare,
  CaseManagement: Text.CaseManagement,
  CPST: Text.CPST,
  OutpatientTherapy: Text.OutpatientTherapy,
  PeerSupport: Text.PeerSupport,
  SubstanceUse: Text.SubstanceUse,
  TargetedCaseManagement: Text.TargetedCaseManagement,
};
