import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { groupBy } from "lodash-es";
import { Database, SyncIssue } from "@remhealth/icons";
import { Features, Practice, PracticeSortField, PracticeStatus, Product, SortDirection, SortField, systems } from "@remhealth/apollo";
import { ColumnFilter, Ellipsize, GridFeed, GroupedRows, InputGroup, SortButton, Tag, Tooltip, useDebouncedState, useLocalStorage, useUpdateEffect } from "@remhealth/ui";
import { ButtonLink, ProductChoice, Stack, createPracticeFilters, getProductLabel, useFeed } from "@remhealth/core";

import { Grid } from "~/common";
import { useRegistry } from "~/contexts";
import { operationRoutes } from "./routes";
import { PracticeRow, SearchBar } from "./practiceGrid.styles";

const practiceFiltersKey = "practice-grid-filters";
const practiceStatusesKey = "practice-grid-status";
const productFilterKey = "practice-grid-product-filter";
const sortKey = "practice-grid-sort-v2";

const products = Object.values(Product);
const statuses = Object.values(PracticeStatus);

const practiceFilters = ["Bells Only", "Has Sync Issues", "Apollo Database not deployed"] as const;
type PracticeFilter = typeof practiceFilters[number];

export const PracticeGrid = () => {
  const navigate = useNavigate();

  const registry = useRegistry();
  const storage = useLocalStorage();

  const [sortPredicate, setSortPredicate] = useState<SortField<PracticeSortField>>(initSort);
  const [searchText, setSearchText] = useDebouncedState("", 500);
  const [selectedStatuses, setSelectedStatuses] = useState<PracticeStatus[]>(initStatuses);
  const [selectedFilters, setSelectedFilters] = useState<PracticeFilter[]>(initFilters);
  const [productFilter, setProductFilter] = useState<ProductChoice[]>(initProductChoices);

  const feed = useFeed(registry.practices, {
    filters: createPracticeFilters({
      query: searchText,
      status: selectedStatuses,
      bellsOnly: selectedFilters.includes("Bells Only"),
      hasPullIssues: selectedFilters.includes("Has Sync Issues"),
      databaseNotExists: selectedFilters.includes("Apollo Database not deployed"),
      product: productFilter,
    }),
    orderBy: sortPredicate,
  });

  // Reset if feed query changes
  useUpdateEffect(() => {
    feed.reset();
  }, [searchText, selectedFilters, productFilter, sortPredicate]);

  return (
    <Stack $vertical>
      <SearchBar>
        <InputGroup
          large
          soft
          placeholder="Search for practices..."
          type="search"
          onChange={setSearchText}
        />
        <ButtonLink
          large
          icon="plus"
          label="New Practice"
          to={generatePath(operationRoutes.practice, { networkId: "new", tab: "practices", practiceTab: "practice" })}
        />
      </SearchBar>
      <Grid>
        <GridFeed<Practice>
          compact
          fill
          interactive
          defaultPageSize={50}
          feed={feed}
          group={groupRenderer}
          headerRenderer={headerRenderer}
          pageKey="customerGrid"
          rowRenderer={rowRenderer}
        />
      </Grid>
    </Stack>
  );

  function groupRenderer(practices: Practice[]): GroupedRows<Practice>[] {
    const groups = groupBy(practices, item => item.status);
    return Object.entries(groups)
      .filter(([_, items]) => items.length)
      .map(([type, groupedItems]) => {
        return {
          items: groupedItems,
          header: <tr><td colSpan={5}>{type}</td></tr>,
        };
      });
  }

  function headerRenderer() {
    const { field, direction = SortDirection.Unspecified } = sortPredicate ?? {};
    return (
      <tr>
        <th>
          <SortButton
            label="Name"
            sort={field === PracticeSortField.StatusAndDisplay ? direction : SortDirection.Unspecified}
            onClick={handleSortByName}
          />
          <ColumnFilter<PracticeStatus | PracticeFilter>
            aria-label="Filter Practices"
            items={[...statuses, ...practiceFilters]}
            labelRenderer={i => i}
            selectedItems={[...selectedStatuses, ...selectedFilters]}
            onChange={handlePracticeFilterChange}
          />
        </th>
        <th>
          <SortButton
            label="Network ID"
            sort={field === PracticeSortField.StatusAndNetworkId ? direction : SortDirection.Unspecified}
            onClick={handleSortByNetworkId}
          />
        </th>
        <th>
          <span>Product</span>
          <ColumnFilter<ProductChoice>
            aria-label="Filter by Product"
            items={["Unknown", ...products]}
            labelRenderer={product => product === "Unknown" ? "Unknown" : getProductLabel(product)}
            selectedItems={productFilter}
            onChange={handleProductFilterChange}
          />
        </th>
        <th>Database / ScopeID</th>
        <th>Features</th>
      </tr>
    );
  }

  function rowRenderer(item: Practice) {
    const syncIssue = item.meta?.pullResult?.outcome === "Failed"
      ? "danger"
      : item.meta?.pullResult?.outcome === "Warning" ? "warning" : "none";

    const careFabricScope = item.identifiers?.find(i => i.system === systems.careFabricScope)?.value;

    return (
      <PracticeRow key={item.id} $syncIssue={syncIssue} onClick={() => handleRowSelect(item)}>
        <td>
          {item.name}
          {item.features.includes("Bells") && <Tag minimal intent="primary">Bells</Tag>}
          {!item.databaseExists && (
            <Tooltip
              content="This practice does not have an Apollo database deployed."
              intent="danger"
            >
              <Database intent="danger" />
            </Tooltip>
          )}
          {syncIssue !== "none" && (
            <Tooltip
              content={`This practice had sync ${syncIssue === "danger" ? "failures" : "warnings"}. Click here to view details.`}
              intent={syncIssue}
            >
              <SyncIssue intent={syncIssue} />
            </Tooltip>
          )}
        </td>
        <td>{item.networkId}</td>
        <td>{item.product ? getProductLabel(item.product) : "Unknown"}</td>
        <td><Ellipsize>{item.database?.display ?? careFabricScope}</Ellipsize></td>
        <td><Ellipsize>{item.features.sort(sortFeatures).join(", ")}</Ellipsize></td>
      </PracticeRow>
    );
  }

  function handleSortByNetworkId() {
    handleSortBy(PracticeSortField.StatusAndNetworkId);
  }

  function handleSortByName() {
    handleSortBy(PracticeSortField.StatusAndDisplay);
  }

  function handleSortBy(sortBy: PracticeSortField) {
    const { field, direction = SortDirection.Ascending } = sortPredicate ?? {};

    const newSort: SortField<PracticeSortField> = {
      field: sortBy,
      direction: field === sortBy ? getReverseSortDirection(direction) : direction,
    };

    storage.setItem(sortKey, JSON.stringify(newSort));
    setSortPredicate(newSort);
  }

  function getReverseSortDirection(sortDirection: SortDirection) {
    return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
  }

  function handleRowSelect(practice: Practice) {
    const url = generatePath(operationRoutes.practice, { networkId: practice.networkId, tab: "practices", practiceTab: "practice" });
    navigate(url);
  }

  function handleProductFilterChange(products: ProductChoice[]) {
    storage.setItem(productFilterKey, JSON.stringify(products));
    setProductFilter(products);
  }

  function handlePracticeFilterChange(selections: (PracticeStatus | PracticeFilter)[]) {
    const selectedStatuses = selections.filter(i => ([...statuses] as string[]).includes(i)) as PracticeStatus[];
    const selectedFilters = selections.filter(i => ([...practiceFilters] as string[]).includes(i)) as PracticeFilter[];

    storage.setItem(practiceStatusesKey, JSON.stringify(selectedStatuses));
    setSelectedStatuses(selectedStatuses);

    storage.setItem(practiceFiltersKey, JSON.stringify(selectedFilters));
    setSelectedFilters(selectedFilters);
  }

  function initProductChoices(): ProductChoice[] {
    const stored = storage.getItem(productFilterKey);
    if (stored) {
      return JSON.parse(stored);
    }
    return [];
  }

  function initStatuses(): PracticeStatus[] {
    const stored = storage.getItem(practiceStatusesKey);
    if (stored) {
      return JSON.parse(stored);
    }
    return [];
  }

  function initFilters(): PracticeFilter[] {
    const stored = storage.getItem(practiceFiltersKey);
    if (stored) {
      return JSON.parse(stored);
    }
    return [];
  }

  function initSort(): SortField<PracticeSortField> {
    const stored = storage.getItem(sortKey);
    if (stored) {
      return JSON.parse(stored);
    }
    return { field: PracticeSortField.StatusAndDisplay, direction: SortDirection.Ascending };
  }
};

// Priority features
const featuresOrder: Features[] = ["Bells"];

function sortFeatures(left: Features, right: Features): number {
  const priorityOrderLeft = featuresOrder.indexOf(left);
  const priorityOrderRight = featuresOrder.indexOf(right);
  if (priorityOrderLeft !== -1 || priorityOrderRight !== -1) {
    return (priorityOrderLeft === -1 ? Number.POSITIVE_INFINITY : priorityOrderLeft) - (priorityOrderRight === -1 ? Number.POSITIVE_INFINITY : priorityOrderRight);
  }

  return left.localeCompare(right);
}
