import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Book, Build, Download, HorizontalInbetween, Snippet } from "@remhealth/icons";
import { Button } from "@remhealth/ui";
import { Logos } from "@remhealth/core";
import { Text } from "~/text";

import { routes } from "./routes";
import { Container, Logo, NavMenu, NavMenuItem } from "./sidebar.styles";

export interface SidebarProps {
  onLogout: () => void;
}

export const Sidebar = (props: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLogout } = props;

  return (
    <Container>
      <Logo><img alt="" src={Logos.BellsLogoHorizontal} /></Logo>
      <h2>Mesa</h2>
      <NavMenu large>
        <NavMenuItem {...activeProps(routes.operations)} icon={<Build />} text="Operations" onClick={() => navigate(routes.operations)} />
        <NavMenuItem {...activeProps(routes.builds)} icon={<Download />} text="Builds" onClick={() => navigate(routes.builds)} />
        <NavMenuItem {...activeProps(routes.recommendations)} icon={<Book />} text="Recommendations" onClick={() => navigate(routes.recommendations)} />
        <NavMenuItem {...activeProps(routes.dropIns)} icon={<Snippet />} text={Text.Snippets} onClick={() => navigate(routes.dropIns)} />
        <NavMenuItem {...activeProps(routes.expansions)} icon={<HorizontalInbetween />} text={Text.TextExpansions} onClick={() => navigate(routes.expansions)} />
      </NavMenu>
      <Button minimal intent="danger" label="Sign Out" onClick={onLogout} />
    </Container>
  );

  function activeProps(path: string) {
    const active = matchesPath(path);
    return { intent: active ? "primary" as const : "none" as const, active };
  }

  function matchesPath(path: string) {
    return !!matchPath(`${path}/*`, location.pathname);
  }
};
