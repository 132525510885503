import { FormField, FormGroup, SelectInput } from "@remhealth/ui";
import { SpeechRuleCategory } from "@remhealth/apollo";
import { speechRuleCategoryText } from "@remhealth/core";

export interface SpeechRuleCategoryInputProps {
  field: FormField<SpeechRuleCategory | undefined>;
}

export function SpeechRuleCategoryInput(props: SpeechRuleCategoryInputProps) {
  const { field } = props;

  return (
    <FormGroup label="Applicable Category">
      <SelectInput<SpeechRuleCategory>
        field={field}
        items={Object.values(SpeechRuleCategory)}
        optionRenderer={category => speechRuleCategoryText[category]}
      />
    </FormGroup>
  );
}
