import { Tab, Tabs } from "@remhealth/ui";
import { NounRulePanel } from "@remhealth/core";
import { RuleForm } from "../schema";
import { SpeechRuleCategoryInput } from "./speechRuleCategoryInput";

export interface NounRuleFormProps {
  form: RuleForm<"Noun">;
}

export function NounRuleForm(props: NounRuleFormProps) {
  const { form } = props;

  const panel = (
    <>
      <NounRulePanel form={form.rule.fields} />
      <SpeechRuleCategoryInput field={form.category} />
    </>
  );

  return (
    <Tabs renderActiveTabPanelOnly id="rule" selectedTabId="details">
      <Tab id="details" panel={panel} title="Configuration" />
    </Tabs>
  );
}
