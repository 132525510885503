import styled from "styled-components";
import { Menu, MenuItem } from "@remhealth/ui";

export const Container = styled.aside`
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  width: 230px;
  padding: 5px;
  display: flex;
  background: white;
  box-shadow: 1px 0 1px var(--border-muted);
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > h2 {
    margin: 0 10px 10px 10px;
    font-weight: 400;
    text-align: center;
  }
`;

export const NavMenu = styled(Menu)`
  margin: 5px 0;

  li + li {
    margin-top: 4px;
  }
`;

export const NavMenuItem = styled(MenuItem)`
  border-radius: 500px;
`;

export const Logo = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    max-width: 100%;
    max-height: 50px;
    height: auto;
  }
`;
