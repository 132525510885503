import { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { AvailableUsers } from "@remhealth/core";
import { Practice } from "@remhealth/apollo";
import { operationRoutes } from "../routes";
import { Container } from "./usersGrid.styles";

export interface UsersGridProps {
  practice: Practice;
}

export function UsersGrid(props: UsersGridProps) {
  const { practice } = props;

  const [bellsRoute, ehrRoute] = useMemo(() => {
    const bellsRoute = generatePath(operationRoutes.practice, { tab: "practices", networkId: practice.networkId, practiceTab: "users" });
    const ehrRoute = generatePath(operationRoutes.practice, { tab: "practices", networkId: practice.networkId, practiceTab: "user-import" });
    return [bellsRoute, ehrRoute];
  }, [practice.networkId]);

  return (
    <Container>
      <AvailableUsers
        allowSupervisorImport
        showExtensions
        bellsRoute={bellsRoute}
        ehrRoute={ehrRoute}
        militaryTime={false}
        practice={practice}
      />
    </Container>
  );
}
