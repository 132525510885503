import styled from "styled-components";
import { Dialog, FormGroup } from "@remhealth/ui";

export const Modal = styled(Dialog)`
  width: 70% !important;
  min-height: 400px;
  user-select: auto;
`;

export const FieldGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;

export const TextGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;

export const CategoryGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  justify-content: flex-start;
`;

export const QuillContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 300px;
`;
