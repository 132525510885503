import React from "react";
import styled from "styled-components";
import { NonIdealState, Svg } from "@remhealth/ui";

import { FullCenterScreen } from "../styles";
import { Illustrations } from "../assets";

export const NotFoundTitle = styled.span`
  font-size: 28px;
`;

export class NotFound extends React.PureComponent {
  public render() {
    return (
      <FullCenterScreen>
        <NonIdealState
          description="The page you have requested could not be found."
          icon={<Svg src={Illustrations.NotFound} />}
          title={<NotFoundTitle>404 Page Not Found</NotFoundTitle>}
        />
      </FullCenterScreen>
    );
  }
}
