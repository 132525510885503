import { INetworkConsumer, OnlineChangeEventHandler, StoreFactory } from "@remhealth/apollo";
import { profileStoragePartitionKey } from "@remhealth/host";
import { IndexedDbAsyncStorageFactory } from "./indexedDbAsyncStorage";

export function storeFactory(networkId: string, profile: string) {
  const asyncStorageFactory = new IndexedDbAsyncStorageFactory(`bells-${profileStoragePartitionKey(networkId, profile)}`);
  return new StoreFactory(asyncStorageFactory, new AlwaysOnlineNetworkConsumer());
}

class AlwaysOnlineNetworkConsumer implements INetworkConsumer {
  public isOnline() {
    return Promise.resolve(true);
  }

  public onOnlineChange(_handler: OnlineChangeEventHandler): () => void {
    // No-op, never changes
    return () => {};
  }
}
