import { useEffect, useState } from "react";
import { BlockedPerson, Import, Refresh } from "@remhealth/icons";
import { PractitionerSearchRecord as ExternalPractitioner, PullByIdentifierResult } from "@remhealth/mastodon";
import { LocalDate, Practice, Practitioner, Reference } from "@remhealth/apollo";

import {
  Button,
  DateFormats,
  Ellipsize,
  IconButton,
  NonIdealIcon,
  PagingGrid,
  PagingResult,
  useAbort,
  useDebouncer,
  usePagingController,
  useStateRef
} from "@remhealth/ui";

import { containsIdentifier, getProductFlag, useApollo } from "@remhealth/host";
import { Text } from "~/text";
import { useEhr } from "~/services";
import { useErrorHandler } from "~/app";
import { PullLoadingDialog } from "./pullLoadingDialog";
import { ImportEhrUserDialog } from "./importEhrUserDialog";
import { ImportSupervisorDialog } from "./importSupervisorDialog";
import { Grid } from "./common.styles";
import { ActionCell, ActionsHeader } from "./ehrUsers.styles";

export interface EhrUsersProps {
  searchText: string;
  practice: Practice;
  isImportOpen: boolean;
  isSupervisorImportOpen: boolean;
  onImport: (practioner: Reference<Practitioner>) => Promise<void>;
  onCloseImport: () => void;
  onCloseSupervisorImport: () => void;
}

export function EhrUsers(props: EhrUsersProps) {
  const { practice, searchText, isImportOpen, isSupervisorImportOpen, onImport, onCloseImport, onCloseSupervisorImport } = props;

  const apollo = useApollo();
  const ehr = useEhr();
  const abort = useAbort();
  const debouncer = useDebouncer(300);
  const handleError = useErrorHandler();
  const controller = usePagingController<ExternalPractitioner>();

  const showStaffUsername = getProductFlag(practice, "ShowStaffUsername");
  const showStaffHireDate = getProductFlag(practice, "ShowStaffHireDate");

  const [importedUsers, setImportedUsers] = useState(new Map<string, "imported" | "newly-imported" | "not-imported">());
  const [pullingUser, setPullingUser] = useState("");
  const continuationToken = useStateRef<string | undefined>("");

  useEffect(() => {
    resetGrid();
  }, [searchText]);

  return (
    <>
      <Grid>
        <PagingGrid<ExternalPractitioner>
          allowWrap
          compact
          stickyHeader
          controller={controller}
          headerRenderer={headerRenderer}
          noResults={renderNoRecords}
          pageKey="ehrUser"
          rowRenderer={rowRenderer}
          onLoadMore={handleLoadEhrUser}
          onViewChanged={handleViewChange}
        />
      </Grid>
      <PullLoadingDialog name={pullingUser} practice={practice} onCancel={handleCancelImport} />
      <ImportEhrUserDialog isOpen={isImportOpen} onClose={onCloseImport} onImported={handleImported} />
      <ImportSupervisorDialog isOpen={isSupervisorImportOpen} onClose={onCloseSupervisorImport} />
    </>
  );

  function renderNoRecords() {
    return (
      <tr>
        <td colSpan={showStaffHireDate ? 4 : 3}>
          <NonIdealIcon
            description={searchText
              ? "No matching users found. No users matched your search."
              : "Looks like you haven't searched for a user. Enter the user name to view or import."}
            icon={<BlockedPerson />}
            intent="primary"
            title={Text.NoUsers}
          />
        </td>
      </tr>
    );
  }

  async function handleImported(item: ExternalPractitioner, result: PullByIdentifierResult) {
    importedUsers.set(item.identifier.value, "newly-imported");
    setImportedUsers(importedUsers);

    if (result.reference) {
      await onImport(result.reference as Reference<Practitioner>);
    }
  }

  function handleCancelImport() {
    setPullingUser("");
    abort.reset();
  }

  function headerRenderer() {
    return (
      <tr>
        <th>Name</th>
        <th>{showStaffUsername ? "Username" : "Email"}</th>
        {showStaffHireDate && <th>Hire Date</th>}
        <ActionsHeader />
      </tr>
    );
  }

  function rowRenderer(item: ExternalPractitioner, index: number) {
    const separatedDate = DateFormats.date(LocalDate.toDate(item.tenure?.end));
    const importedUser = importedUsers.get(item.identifier.value);
    const importLoading = importedUser === undefined;
    const newlyImported = importedUser === "newly-imported";
    const alreadyImported = newlyImported || importedUser === "imported";
    return (
      <tr key={index} data-rowid={`row-${item.identifier.value}`}>
        <td><Ellipsize>{item.display}</Ellipsize></td>
        <td><Ellipsize>{item.altId}</Ellipsize></td>
        {showStaffHireDate && (
          <td>{DateFormats.date(LocalDate.toDate(item.tenure?.start)) || (separatedDate ? "N/A" : "")}{separatedDate ? " - " + separatedDate : ""}</td>
        )}
        <ActionCell>
          <Button
            minimal
            className="import"
            disabled={alreadyImported}
            icon={alreadyImported ? undefined : <Import />}
            intent={newlyImported ? "success" : alreadyImported ? undefined : "primary"}
            label={newlyImported ? "User imported" : alreadyImported ? "User already imported" : "Import"}
            loading={importLoading}
            outlined={alreadyImported}
            onClick={(e) => handleImportEhrUser(e, item)}
          />
          {alreadyImported && (
            <IconButton elevated tooltip aria-label="Refresh" icon={<Refresh />} loading={importLoading} onClick={(e) => handleImportEhrUser(e, item)} />
          )}
        </ActionCell>
      </tr>
    );
  }

  async function handleLoadEhrUser(startIndex: number, limit: number, abort: AbortSignal): Promise<PagingResult<ExternalPractitioner>> {
    if (!searchText) {
      return {
        items: [],
        hasMore: false,
      };
    }

    try {
      const response = await ehr.searchPractitioners(searchText, {
        limit,
        continuationToken: startIndex !== 0 ? continuationToken.current : undefined,
      }, abort);

      continuationToken.set(response.continuationToken);

      return {
        items: response.results,
        hasMore: !!response.continuationToken,
      };
    } catch (error) {
      handleError(error);
      throw error;
    }
  }

  async function handleImportEhrUser(event: React.MouseEvent, item: ExternalPractitioner) {
    event.stopPropagation();

    setPullingUser(item.display);
    try {
      const result = await ehr.pull(item, abort.signal);

      if (result.reference) {
        const practitioner = result.reference as Reference<Practitioner>;
        onImport(practitioner);
        importedUsers.set(item.identifier.value, "newly-imported");
        setImportedUsers(importedUsers);
      }
    } finally {
      setPullingUser("");
    }
  }

  async function resetGrid() {
    controller.reset();
  }

  function handleViewChange(items: ExternalPractitioner[]) {
    debouncer.delay(() => checkUsersImported(items));
  }

  async function checkUsersImported(items: ExternalPractitioner[]) {
    const notLoaded = items.filter(item => !importedUsers.has(item.identifier.value));

    if (notLoaded.length === 0) {
      return;
    }

    try {
      const response = await apollo.practitioners.query({
        filters: notLoaded.map(ehrUser => ({
          identifier: {
            system: ehrUser.identifier.system,
            equalTo: ehrUser.identifier.value,
          },
        })),
        abort: abort.signal,
      });

      setImportedUsers(importedUsers => {
        items.forEach(item => {
          const value = item.identifier.value.toLowerCase();
          const match = response.results.find(r => containsIdentifier(r, item.identifier));
          importedUsers.set(value, match ? "imported" : "not-imported");
        });
        return new Map(importedUsers);
      });
    } catch (error) {
      handleError(error);
    }
  }
}
