import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { FormField } from "../utils";
import { useAbort, useDebouncer } from "../hooks";
import { SelectInput, SelectInputProps } from "./selectInput";
import { SelectPopoverProps } from "./useListItems";

type PropOmissions = "items";

export interface LazySelectInputProps<T> extends Omit<SelectInputProps<T>, PropOmissions> {
  /**
   * Function to fetch the list of choices.
   */
  itemsSource: () => T[] | Promise<T[]>;

  /**
   * If true, the list of choices will be fetched upon request to open the popover.
   * @default true
   */
  fetchOnOpen?: boolean;

  /**
   * If true, the list of choices will be fetched as soon as the component is initialized.
   * @default false
   */
  fetchOnInitialize?: boolean;

  field?: FormField<T | undefined>;
}

export interface LazySelectInput {
  clearItems(): void;
}

/**
 * A Select component with the ability to lazily (and asynchronously) load its choices.
 */
function LazySelectInputComponent<T>(props: LazySelectInputProps<T>, ref: React.Ref<LazySelectInput>) {
  const {
    itemsSource,
    fetchOnInitialize,
    intent,
    popoverProps: controlledPopoverProps,
    disabled,
    rightElement,
    field,
    fetchOnOpen,
    ...restProps
  } = props;

  const abort = useAbort();
  const debouncer = useDebouncer(100);

  const [items, setItems] = useState<T[]>();
  const [fetching, setFetching] = useState(false);

  const clearable = !fetching && !!props.clearable;

  const popoverProps: SelectPopoverProps = {
    targetTagName: "div",
    ...controlledPopoverProps,
    onInteraction: fetching ? undefined : handlePopoverInteraction,
    isOpen: fetching ? false : controlledPopoverProps?.isOpen,
  };

  useEffect(() => {
    if (fetchOnInitialize) {
      debouncer.delay(fetchItems);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    clearItems,
  }));

  return (
    <SelectInput
      {...restProps}
      clearable={clearable}
      disabled={disabled || field?.disabled}
      field={field}
      intent={intent}
      items={items ?? []}
      popoverProps={popoverProps}
      rightElement={fetching ? <Spinner className="loader" intent={intent} size={16} /> : rightElement}
    />
  );

  /**
   * Fetches a list of items using the itemsSource.
   */
  async function fetchItems(): Promise<void> {
    setFetching(true);

    const items = await itemsSource();

    if (abort.signal.aborted) {
      return;
    }

    setItems(items);
    setFetching(false);
  }

  /**
   * Empties the list of loaded items, if any.
   */
  function clearItems(): void {
    setItems(undefined);
  }

  function handlePopoverInteraction(isOpen: boolean, e?: React.SyntheticEvent<HTMLElement>): void {
    controlledPopoverProps?.onInteraction?.(isOpen, e);

    if (fetchOnOpen !== false && items === undefined && !fetching) {
      fetchItems();
    }
  }
}

export const LazySelectInput = forwardRef(LazySelectInputComponent) as <T>(props: LazySelectInputProps<T> & { ref?: React.Ref<LazySelectInput>}) => JSX.Element;
