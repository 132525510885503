import styled from "styled-components";
import { Button, FormGroup, InputGroup } from "@remhealth/ui";

export const Grid = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`;

export const RowItem = styled.tr`
  .snippet-action {
    visibility: hidden;
  }
  &:hover .snippet-action {
    visibility: visible;
  }
`;

export const SnippetAction = styled.div`
  justify-content: space-around;
  align-items: center;
`;

export const ActionCell = styled.td`
  text-align: right;
`;

export const ActionHeader = styled.th`
  width: 5.75rem;
`;

export const AddSnippet = styled(Button)`
  align-self: flex-start;
  margin-left: 24px;
`;

export const SnippetTextCell = styled.div`
  margin-right: auto;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  line-height: 24px;
`;

export const NameHeader = styled.th`
  width: 20rem;
`;

export const SearchInputFormGroup = styled(FormGroup)`
  margin-bottom: 15px !important;
  flex: 1;
`;

export const SearchInput = styled(InputGroup)`
  flex-grow: 1;
  flex-shrink: 1;
`;

export const Container = styled.main`
  padding: 40px 40px 15px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > .bp5-callout {
    flex: 0 0 auto;
    margin-bottom: 15px;
  }
`;

export const PageTitle = styled.h3`
  margin-top: 0;
`;

export const SearchField = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;

  .bp5-button {
    flex: 0 0 auto;
  }

  > h3 {
    flex: 1 1 auto;
    margin: 0;
  }

  h3 + && {
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  display: flex ;
  justify-content: space-between;
`;
