import type { Mixpanel } from "mixpanel-browser";
import { securityRoleIds } from "@remhealth/apollo";
import { sanitizeBaseUrl } from "@remhealth/ui";
import { UserSession } from "~/auth/userSession";

export interface UserTracking {
  identify(user: UserSession, anonymous: boolean): Promise<void>;
  increment(property: string, by?: number): Promise<void>;
  set(property: string, to: string | number | Date | null | undefined): Promise<void>;
}

export interface TrackingService {
  readonly user: UserTracking;
  track(event: string, properties?: Record<string, any>): Promise<void>;
}

export class VoidTrackingService implements TrackingService {
  public get user(): UserTracking {
    return {
      identify: () => Promise.resolve(),
      increment: () => Promise.resolve(),
      set: () => Promise.resolve(),
    };
  }

  public track = (event: string, properties?: Record<string, any>) => {
    if (properties) {
      // eslint-disable-next-line no-console
      console.debug(event, properties);
    } else {
      // eslint-disable-next-line no-console
      console.debug(event);
    }
    return Promise.resolve();
  };
}

export class MixPanelTrackingService implements TrackingService {
  private static readonly instances: { [token: string]: Mixpanel | undefined } = {};

  public user: UserTracking;
  private readonly instance: Mixpanel;
  private readonly applicationType: string;

  private constructor(instance: Mixpanel, applicationType: string) {
    this.instance = instance;
    this.user = {
      identify: this.userIdentify,
      increment: this.userIncrement,
      set: this.userSet,
    };
    this.applicationType = applicationType;
  }

  public static async init(token: string, applicationType: string, host: string): Promise<TrackingService> {
    let instance = MixPanelTrackingService.instances[token];
    if (instance) {
      return new MixPanelTrackingService(instance, applicationType);
    }

    // Its possible for this to fail if user has ad blocker
    try {
      const { init } = await import("mixpanel-browser");

      instance = await new Promise<Mixpanel>(resolve => {
        return init(token, {
          loaded: resolve,
          api_host: sanitizeBaseUrl(host),
          cross_subdomain_cookie: false,
        }, `instance_${token}`);
      });

      MixPanelTrackingService.instances[token] = instance;

      return new MixPanelTrackingService(instance, applicationType);
    } catch {
      return new VoidTrackingService();
    }
  }

  public track = async (eventName: string, properties?: Record<string, any>): Promise<void> => {
    return new Promise<void>(resolve => this.instance.track(eventName, properties, () => resolve()));
  };

  private userIdentify = async (user: UserSession, anonymous: boolean): Promise<void> => {
    return new Promise<void>(resolve => {
      this.instance.identify(user.person.id);

      this.instance.people.set({
        email: user.email,
        display: anonymous ? undefined : user.person.display,
        resourceType: user.person.resourceType,
        networkId: user.practice.networkId,
        ehr: user.practice.product ?? "Unknown",
        practice: user.practice.display,
      }, () => resolve());

      // Global properties that get saved with every event (every call of .track())
      this.instance.register({
        ehr: user.practice.product ?? "Unknown",
        networkId: user.practice.networkId,
        resourceType: user.person.resourceType,
        applicationType: this.applicationType,
        isAdmin: user.person.profile.roles.some(r => r.id === securityRoleIds.Administrative) ? "yes" : "no",
      });
    });
  };

  private userIncrement = async (property: string, by: number): Promise<void> => {
    return new Promise<void>(resolve => this.instance.people.increment(property, by, () => resolve()));
  };

  private userSet = async (property: string, to: string | number | Date | null | undefined): Promise<void> => {
    return new Promise<void>(resolve => this.instance.people.set(property, to, () => resolve()));
  };
}
