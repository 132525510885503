import { HealthcareService, NoteDefinition, NoteDefinitionReference, Reference, SpeechRule } from "@remhealth/apollo";
import { ImportProps } from "./common";

export async function importSpeechRules(props: ImportProps): Promise<SpeechRule[]> {
  const {
    preview,
    sourceClient,
    targetClient,
    referenceFactory,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemUpdate,
    onItemComplete,
  } = props;

  let sourceSpeechRules = await sourceClient.speechRules.feed({
    filters: [],
  }).all({ abort });

  onUpdate(sourceSpeechRules.length);
  if (sourceSpeechRules.length === 0) {
    return [];
  }

  const targetSpeechRules = await targetClient.speechRules.feed({
    filters: [{ includeDeleted: true }],
  }).all({ abort });
  const speechRules = targetSpeechRules.filter(f => !f.meta?.isDeleted);

  const sourceServices: Reference<HealthcareService>[] = [];
  const sourceNoteDefinitions: Reference<NoteDefinition>[] = [];

  sourceSpeechRules = sourceSpeechRules.filter(item => {
    onItemCreate(item.id, item.display ?? item.rule.type);

    if (targetSpeechRules.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    sourceServices.push(...item.services);
    sourceNoteDefinitions.push(...item.noteDefinitions.map(n => n.definition));
    return true;
  });

  const serviceReferenceMapper = await referenceFactory.createMapperByIdentifier("healthcareServices", sourceServices, abort);
  const noteDefinitionReferenceMapper = await referenceFactory.createMapperById("noteDefinitions", sourceNoteDefinitions, abort);

  const results: SpeechRule[] = [];
  for (const rule of sourceSpeechRules) {
    const item = copySpeechRules(rule);
    if (item) {
      if (isSingleSetting(rule) && !preview) {
        const existingRule = speechRules.find(s => s.rule.type === rule.rule.type);
        if (existingRule) {
          try {
            await targetClient.speechRules.deleteById(existingRule.id, { abort });
            onItemUpdate(rule.id, existingRule.id);
          } catch (error) {
            onItemError(rule.id, "Failed to delete existing item");
            // eslint-disable-next-line no-console
            console.error(error);
            continue;
          }
        }
      }

      try {
        if (!preview) {
          results.push(await targetClient.speechRules.update({ ...item, meta: undefined }, { abort }));
        } else {
          results.push(item);
        }
        onItemComplete(rule.id);
      } catch (error) {
        onItemError(rule.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copySpeechRules(rule: SpeechRule): SpeechRule | null {
    const services = serviceReferenceMapper.map(rule.services, (unmatched) => {
      onItemError(rule.id, `Service "${unmatched.display}" not found`);
    });

    const noteDefinitions: NoteDefinitionReference[] = [];
    for (const noteDefinition of rule.noteDefinitions) {
      const definition = noteDefinitionReferenceMapper.map(noteDefinition.definition);
      if (definition) {
        noteDefinitions.push({
          definition,
          sections: noteDefinition.sections,
        });
      } else {
        onItemError(rule.id, `Note type "${noteDefinition.definition.display}" not found`);
      }
    }

    return { ...rule, userExclusions: [], derivedFrom: undefined, services, noteDefinitions };
  }
}

function isSingleSetting(rule: SpeechRule): boolean {
  switch (rule.rule.type) {
    case "PatientAssessment":
    case "PatientNoun":
    case "NonThirdPerson":
    case "MissingActor":
      return true;

    case "StaffVerb":
    case "StaffVerbObject":
    case "StaffVerbPatient":
    case "StaffVerbRepeated":
    case "Noun":
    case "LonelyNoun":
    case "Acronym":
    case "Literal":
      return false;
  }
}
