import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: var(--light-gray5);
  box-shadow: inset 0px -1px 0 var(--border-muted);

  .bp5-breadcrumbs {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;
