import { useEffect, useMemo, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { StyleSheetManager } from "styled-components";
import { ForwardingNotifier, OverlayToaster, OverlaysProvider, Toaster, isAbortError } from "@remhealth/ui";
import { BrowserLoginAuthenticationEvents, ErrorBoundary, LoadingMessage, LoginRequired, getErrorMessage, useAuthentication } from "@remhealth/core";
import { AccessTokenContext, AccessTokenStorage, Authenticator, FHIRSmartLogin } from "@remhealth/host";

import { App as MesaApp, Text } from "@remhealth/mesa";
import { type Configuration, fetchConfig, fetchMesaConfig } from "./config";

const accessTokenStorage = new AccessTokenStorage("mesa-tokens");
const authEvents = new BrowserLoginAuthenticationEvents();

const AppContents = () => {
  const [config, setConfig] = useState<Configuration>();
  const authenticator = useMemo(() => config ? new Authenticator(config.oauth) : null, [config?.oauth]);
  const toaster = useRef<Toaster>(null);
  const forwardedToaster = useMemo(() => new ForwardingNotifier(toaster), []);

  useEffect(() => {
    initialize();
  }, []);

  if (!config || !authenticator) {
    return <LoadingMessage title={Text.Loading} />;
  }

  return (
    <OverlaysProvider>
      <OverlayToaster ref={toaster} maxToasts={3} />
      <AppAuthenticator authenticator={authenticator} config={config} toaster={forwardedToaster} onError={handleError} />
    </OverlaysProvider>
  );

  async function initialize(): Promise<void> {
    try {
      const config = await fetchConfig();
      setConfig(config);
    } catch (error) {
      handleError(error);
    }
  }

  function handleError(error: any) {
    if (isAbortError(error)) {
      return;
    }

    // eslint-disable-next-line no-console
    console.error(error);

    toaster.current?.show({
      message: getErrorMessage(error),
      intent: "danger",
      timeout: 10000, // 10 seconds
    });
  }
};

interface AppAuthenticatorProps {
  authenticator: Authenticator;
  config: Configuration;
  toaster: Toaster;
  onError: (error: any) => void;
}

function AppAuthenticator(props: AppAuthenticatorProps) {
  const { authenticator, config, onError } = props;

  const { isSigningIn, tokenContext, login, logout, reauth, pauseRefreshTimer } = useAuthentication({
    authenticator,
    events: authEvents,
    storage: accessTokenStorage,
    loginConfiguration: config.oauth,
    handleError,
    reportError,
  });

  if (isSigningIn()) {
    return (
      <LoadingMessage title={Text.SigningInLoading} />
    );
  }

  if (!tokenContext) {
    // Show login required in screen
    return <LoginRequired onLogin={handleLogin} />;
  }

  return (
    <ErrorBoundary onError={handleError}>
      <AccessTokenContext.Provider value={tokenContext}>
        <MesaApp
          {...props}
          config={config}
          configLoader={fetchMesaConfig}
          version={config.version}
          zone={config.zone}
          onError={handleError}
          onIdle={pauseRefreshTimer}
          onLogout={logout}
          onReauth={reauth}
        />
      </AccessTokenContext.Provider>
    </ErrorBoundary>
  );

  function handleLogin(fhirSmart?: FHIRSmartLogin): void {
    // Force auth if FHIR Smart was explicitly asked for
    const forceAuth = !!fhirSmart;

    // Default launch context for Mesa to use NIAM
    if (!fhirSmart) {
      fhirSmart = {
        issuer: "https://app-launch.bells.ai/ntst/niam/prod",
        launch: String(Date.now()), // Random value
      };
    }

    login({ fhirSmart, forceAuth });
  }

  function handleError(error: any) {
    onError(error);
  }

  function reportError(error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export const App = () => {
  return (
    <StyleSheetManager namespace=".mesa-app">
      <BrowserRouter>
        <AppContents />
      </BrowserRouter>
    </StyleSheetManager>
  );
};
