import { createContext, useContext } from "react";
import type { ComposeEditor } from "~/editor/composeEditor";

export interface ComposeContext {
  editor: ComposeEditor;
  version: number;
}

export const ComposeContext = createContext<ComposeContext | null>(null);
export const FocusedContext = createContext<boolean>(false);

/**
 * Returns a compose editor.  Will trigger re-renders if the state of the editor changes.
 */
export function useCompose(): ComposeEditor {
  const compose = useContext(ComposeContext);

  if (!compose) {
    throw new Error("The useCompose hook must be used inside the <Compose> component's context.");
  }

  return compose.editor;
}

/**
 * Returns a compose editor and version.  Will trigger re-renders if the state of the editor changes.
 */
export function useComposeVersion(): ComposeContext {
  const context = useContext(ComposeContext);

  if (!context) {
    throw new Error("The useComposeVersion hook must be used inside the <Compose> component's context.");
  }

  return context;
}

/**
 * Returns whether the compose editor is focused.
 */
export function useIsFocused() {
  return useContext(FocusedContext);
}
